import {useEffect, useState, createContext} from 'react';
import {useParams} from 'react-router-dom';
import {io, Socket} from 'socket.io-client';
import {loadMatch, NationMatch, Player, SelectedMap, StarcraftMaps} from 'src/features/tools/nationwars';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';
import {duoMaps, soloMaps, VETOSTEPS} from 'src/utils/nationwarsHelpers';
import NationsMatchesAnnounceSelectedMap from '../NationsMatchesAnnounceSelectedMap';
import NationsMatchesCentralPanel from '../NationsMatchesCentralPanel';
import NationsMatchesCountdownTimer from '../NationsMatchesCountdownTimer/NationsMatchesCountdownTimer';
import NationsMatchesMapsList from '../NationsMatchesMapsList';
import NationsMatchesPlayers from '../NationsMatchesPlayers';
import NationsMatchesPlayerSelection from '../NationsMatchesPlayerSelection';
import NationsMatchesSidePanels from '../NationsMatchesSidePanels';
import NationsMatchesTimer from '../NationsMatchesTimer/NationsMatchesTimer';
import NationsMatchesWaitingScreen from '../NationsMatchesWaitingScreen/NationsMatchesWaitingScreen';
import MatchContext from '../NationsWarsMatchContext';
import styles from './NationsMatchesRender.module.css';
const baseURL = process.env.REACT_APP_BASE_URL ?? '';
export const timePerChoice = 91;



const NationsMatchesRender = () => {

  const {id, nation} = useParams();
  const dispatch = useAppDispatch();
  const {matchDetails} = useAppSelector((s) => s.nations);
  const [socket, setSocket] = useState<Socket>();
  const [currentMatch, setCurrentMatch] = useState<NationMatch | null>(null);
  const [nationFlags, setNationFlags] = useState<string[]>([]);
  const [remainingTime, setRemainingTime] = useState<number>(timePerChoice);
  const [VS1Maps, setVS1Maps] = useState<StarcraftMaps[]>([...soloMaps]);
  const [VS2Maps, setVS2Maps] = useState<StarcraftMaps[]>([...duoMaps]);
  const [hoveredPlayer, setHoveredPlayer] = useState<number | null>(null);
  const [selectedMap, setSelectedMap] = useState<SelectedMap | undefined>();

  useEffect(() => {
    dispatch(loadMatch(id!));
  }, []);

  useEffect(() => {
    setSocket(io(`${baseURL}/nationwars-match`, {query: {id}}));
  }, []);

  const findStartingNation = (match : NationMatch) => {
    const nations = match.nations;
    const foundNation = nations.find((n) => n.flag === match.startingNation);
    return foundNation;
  };

  const checkIfVetoPhase = (step: number) => {
    return (currentMatch!.currentStep === 1 || currentMatch!.currentStep === 2 || currentMatch!.currentStep === 8 || currentMatch!.currentStep === 9);
  };
  const handlePlayerSelection = (player1: string, player2: string, nationIndex: number ) => {
    console.log({player1, player2, nationIndex});
    socket?.emit('player-selected-to-server', {id, player1, player2, nation: nationIndex});
  };

  const checkIfMapAlreadySelected = (mapName: string) => {
    const mapAlreadyInList = currentMatch?.maps.findIndex((m) => m.map.name === mapName);
    if (mapAlreadyInList !== -1) return true;
    return false;
  };

  const handleMapSelection = (map: StarcraftMaps, solo: boolean) => {
    if (currentMatch?.currentStep === 7 || currentMatch?.currentStep === 12) return;
    if (checkIfMapAlreadySelected(map.name)) return;
    const isVetoStep = checkIfVetoPhase(currentMatch!.currentStep);
    socket?.emit('show-selected-map-to-server', {id, name: map.name, veto: isVetoStep, nation});
    socket?.emit('map-selected-to-server', {id, map, nation, order: matchDetails?.currentStep, veto: isVetoStep});
  };

  const refreshMapDisplay = (match: NationMatch) => {
    const isVetoStep = (match.currentStep === 1 || match.currentStep === 2);
    if (!isVetoStep) {
      const {maps} = match;
      const remainingMaps = soloMaps.filter((map) => {
        for (const m of maps) {
          if (m.veto !== false && m.map.name !== map.name) return true;
        }
      });
      setVS1Maps(remainingMaps);
    }
  };

  const matchContextObject = {
    currentMatch,
    setCurrentMatch,
    nationFlags,
    VS1Maps,
    setVS1Maps,
    VS2Maps,
    setVS2Maps,
    handleMapSelection,
    handleMapDisplay: refreshMapDisplay,
    hoveredPlayer,
    setHoveredPlayer,
    handlePlayerSelection,
    selectedMap,
    setSelectedMap,
  };

  useEffect(() => {
    if (socket) {
      socket.on('initialize-match', (match: NationMatch  ) => {
        setCurrentMatch(match);
        console.log(match.background);
        setNationFlags(match.nations.map((n) => n.flag));
      });
      socket.on('start-vetoes-to-client', () => {
        dispatch(loadMatch(id!));
      });

      socket.on('refresh-client', (match : NationMatch) => {
        setCurrentMatch(match);
        setRemainingTime(match.timer.remainingTime);
      });

      socket.on('refresh-timer-to-client', (match : NationMatch) => {
        setCurrentMatch(match);
        refreshMapDisplay(match);

      });

      socket.on('show-selected-map-to-client', (data: SelectedMap) => {
        console.log(data);
        setSelectedMap({...data, name: data.name.replaceAll('_', ' ')});
        setTimeout(() => {
          setSelectedMap(undefined);
        }, 3000);
      });

    }

    return () => {
      if (socket?.connected) socket.disconnect();
    };
  }, [socket]);


  return (
    <MatchContext.Provider value={matchContextObject}>
      {currentMatch && (

        <div className={styles.pageContainer} style={!currentMatch.background && nation === 'stream' ? {background: 'unset'} : {}}>
          <NationsMatchesWaitingScreen />
          <NationsMatchesCountdownTimer />
          {selectedMap && <NationsMatchesAnnounceSelectedMap isVetoPhase={checkIfVetoPhase(currentMatch.currentStep)}/>}
          {currentMatch.currentStep === 7 && (
            <>
              <NationsMatchesPlayers />
              <NationsMatchesPlayerSelection />
            </>
          )}
          <div className={styles.titreEtLignes} style={{opacity : currentMatch?.currentStep === 0 ? 0.2 : 1}}>
            <NationsMatchesSidePanels />
            <NationsMatchesCentralPanel />
            <div className={styles.mapsListContainer}>
              {VS1Maps && <NationsMatchesMapsList solo={true} />}
              {VS2Maps && <NationsMatchesMapsList solo={false} />}
            </div>
            {(remainingTime && currentMatch.currentStep !== 12) && <NationsMatchesTimer />}
          </div>
        </div>
      )}
    </MatchContext.Provider>
  );
};

export default NationsMatchesRender;
