/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-props-no-spreading */
import {useEffect} from 'react';
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import BackButton from 'src/components/Global/BackButton';
import {useNavigate, useParams} from 'react-router-dom';
import {loadRessourcesList, updateRessource} from 'src/features/website/events';
import styles from './RessourceUpdate.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

interface IFormInputs {
  description: string;
}

const SignupSchema = yup.object().shape({
  description: yup.string().min(2, 'Description must be at least 2 characters'),
});

interface RessourceUpdateParams {
  idparam: string;
}

const RessourceUpdate = () => {
  const {id} = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {ressourcesList} = useAppSelector((s) => s.events);
  const {register, handleSubmit, setValue, formState: {errors, isDirty, isValid}} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });
  const currentRessource = ressourcesList.filter((r) => r._id === id)[0];

  const onSubmitRessourceUpdate = (data: IFormInputs) => {
    const newRessource = new FormData();
    if (data.description) newRessource.append('description', data.description);

    dispatch(updateRessource(id!, newRessource, navigate));
  };

  useEffect(() => {
    dispatch(loadRessourcesList());
  }, [dispatch]);

  useEffect(() => {
    if (currentRessource) setValue('description', currentRessource.description, {shouldValidate: true});
  }, [currentRessource, setValue]);

  return (
    <div>
      <div className={styles.title}>Create Ressource</div>
      <BackButton />
      <form onSubmit={handleSubmit(onSubmitRessourceUpdate)}>
        <label>Description</label>
        <input {...register('description')} defaultValue={currentRessource?.description} />
        <p>{errors.description?.message}</p>
        <input type="submit" value="Update" disabled={!isDirty}/>
      </form>
    </div>
  );
};

export default RessourceUpdate;
