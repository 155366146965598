/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/jsx-props-no-spreading */
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {Channel, updateChannel} from 'src/features/tools/basecamp';
import {useAppDispatch} from 'src/hooks/hooks';
import * as yup from 'yup';
import styles from './UpdateChannel.module.css';

interface UpdateChannelProps {
  channel: Channel;
}
interface IFormInputs {
  channel: string;
  viewers: number
}

const SignupSchema = yup.object().shape({
  channel: yup.string().min(2, 'Channel must be at least 2 characters'),
  viewers: yup.number().min(1, 'Viewers must be at least 1'),

});

const UpdateChannel = ({channel}: UpdateChannelProps) => {
  const dispatch = useAppDispatch();
  const {register, handleSubmit, formState: {errors, isValid}} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });

  const onSubmitPlayerUpdate = async (data: IFormInputs) => {
    const {_id} = channel;
    const updatedChannel = {
      channel: data?.channel,
    };
    dispatch(updateChannel(_id, updatedChannel));
  };

  return (
    <div>
      <div className={styles.title}>Update Player</div>
      <form onSubmit={handleSubmit(onSubmitPlayerUpdate)}>
        <div className="w-100 pr-3">
          <label>Channel</label>
          <input {...register('channel')} defaultValue={channel.channel}/>
        </div>
        <div>
          <label>Expected viewership</label>
          <input
            {...register('viewers')}
            defaultValue={channel.viewers}
          />
          {errors.viewers && <p>{errors.viewers.message}</p>}
        </div>
        <input type="submit" value="Update" disabled={!isValid} />
      </form>
    </div>
  );
};

export default UpdateChannel;
