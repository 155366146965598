import {useState, useEffect} from 'react';
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import {Line} from 'react-chartjs-2';
import styles from './TwitchAnalytics.module.css';
import {useAppDispatch} from 'src/hooks/hooks';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
    },
    title: {
      display: true,
      text: 'Chart.js Line Chart',
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const data = {
  labels,
  datasets: [
    {
      label: 'Dataset 1',
      // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      data: [],
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'Dataset 2',
      // data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      data: [],
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

const analytics = [
  {
    followers: 613643,
    started_at: '2021-12-15T08:15:50Z',
    max_viewers: 12312,
    live_for: 30,
    average_viewers: 41512,
    amount_of_record: 2,
  },
  {
    followers: 23232,
    started_at: '2021-12-13T08:15:50Z',
    max_viewers: 32113,
    live_for: 30,
    average_viewers: 21512,
    amount_of_record: 2,
  },
  {
    followers: 3123,
    started_at: '2021-12-15T08:15:50Z',
    max_viewers: 22222,
    live_for: 30,
    average_viewers: 31512,
    amount_of_record: 2,
  },
];

const baseApiUrl = process.env.REACT_APP_BASE_URL;
const TwitchAnalytics = () => {
  const dispatch = useAppDispatch();

  const [maxViewersArray, setMaxViewersArray] = useState<number[]>([]);
  // function that return the unique days in twitchData array

  const getUniqueDays = (datas: any) => {
    const uniqueDays = new Set();
    datas.forEach((item: any) => {
      uniqueDays.add(item.started_at.slice(0, 10));
    });
    return Array.from(uniqueDays);
  };

  const getArrayOfMaxViewers = (datas: any) => {
    const arrayOfMaxViewers: number[] = [];
    datas.forEach((item: any) => {
      arrayOfMaxViewers.push(item.max_viewers);
    });
    return arrayOfMaxViewers;
  };

  const getArrayOfAverageViewers = (datas: any) => {
    const arrayOfAverageViewers: number[] = [];
    datas.forEach((item: any) => {
      arrayOfAverageViewers.push(item.average_viewers);
    });
    return arrayOfAverageViewers;
  };

  const getArrayOfFollowers = (datas: any) => {
    const arrayOfFollowers: number[] = [];
    datas.forEach((item: any) => {
      arrayOfFollowers.push(item.followers);
    });
    return arrayOfFollowers;
  };

  useEffect(() => {
    const maxViewers = getArrayOfMaxViewers(analytics);
    setMaxViewersArray(maxViewers);
  }, []);
  const twitchData = {
    labels,
    datasets: [
      {
        label: 'Max viewers',
        data: maxViewersArray,
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
      // {
      //   label: 'Dataset 2',
      //   data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      //   borderColor: 'rgb(53, 162, 235)',
      //   backgroundColor: 'rgba(53, 162, 235, 0.5)',
      // },
    ],
  };
  // useEffect(() => {
  //   dispatch(loadTwitchUsersList())
  // }, [dispatch]);
  const startParsing = async () => {
    await axios.post(`${baseApiUrl}/twitchdata/start`);
  };
  const generateOauth = async () => {
    await axios.post(`${baseApiUrl}/twitchdata/generate-oauth-token`);
  };
  return (
    <div className={styles.pageContainer}>
      <div className={styles.title}>Twitch Analytics</div>
      <Line options={options} data={twitchData} />;

    </div>
  );
};

export default TwitchAnalytics;
