/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-underscore-dangle */
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import BackButton from 'src/components/Global/BackButton';
import {loadArticle, updateArticle} from 'src/features/website/articles';
import ImagePicker from 'src/components/Global/ImagePicker';
import styles from './ArticleUpdate.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

interface IFormInputs {
  url: string;
  title: string;
}
interface articleImage {
  image: File | undefined;
}

const SignupSchema = yup.object().shape({
  url: yup.string().min(6, 'URL must be at least 6 characters'),
  title: yup.string().min(4, 'Title must be at least 4 characters'),
});

const ArticleUpdate = () => {
  const {id} = useParams();
  const {articleDetails} = useAppSelector((s) => s.articles);
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const {register, handleSubmit, setValue, formState: {errors, isDirty, isValid}} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });

  const [memberImage, setMemberImage] = useState<articleImage>({
    image: undefined,
  });
  const onFileChange = (file: File | undefined) => {
    setMemberImage({image: file});
  };

  const onSubmitArticleUpdate = (data: IFormInputs) => {
    const updatedArticle = new FormData();
    if (data.title) updatedArticle.append('title', data.title);
    if (memberImage.image) updatedArticle.append('image', memberImage.image);
    if (data.url) updatedArticle.append('url', data.url);
    dispatch(updateArticle(id!, updatedArticle, history));
  };

  useEffect(() => {
    dispatch(loadArticle(id!));
  }, [dispatch, id]);

  useEffect(() => {
    if (articleDetails) {
      setValue('title', articleDetails.title, {shouldValidate: true});
      setValue('url', articleDetails.url, {shouldValidate: true});
    }
  }, [articleDetails, setValue]);

  return (
    <div>
      <div className={styles.title}>Update Member</div>
      <BackButton />
      <form onSubmit={handleSubmit(onSubmitArticleUpdate)}>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>First Name</label>
            <input
              {...register('title')}
              defaultValue={articleDetails?.title}
            />
            {errors.title && <p>{errors.title.message}</p>}

          </div>
          <div className="w-50 pl-3">
            <label>URL</label>
            <input {...register('url')} defaultValue={articleDetails?.url} />
            <p>{errors.url?.message}</p>
          </div>
        </div>
        <label>Pick Photo</label>
        <div className={styles.imagePickerContainer}>
          {articleDetails?.image
            && <img
            src={articleDetails?.image}
            alt="Memberpic"
            className={styles.oldImage}
          />
          }
          <ImagePicker
            labelText="Add an image for the article"
            fileHandler={onFileChange}
            errorText=""
            previewHeight={180}
            previewWidth={320}
            heightRatio={180}
            widthRatio={320}
          />
        </div>
        <input type="submit" value="Update" disabled={!isDirty}/>
      </form>
    </div>
  );
};

export default ArticleUpdate;
