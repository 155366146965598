import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Nation, NationMatch, Player} from 'src/features/tools/nationwars';
import NationsMatchesTimer from '../NationsMatchesTimer/NationsMatchesTimer';
import MatchContext from '../NationsWarsMatchContext';
import styles from './NationsMatchesSidePanels.module.css';



const NationsMatchesSidePanels = () => {
  const {nationFlags} = useContext(MatchContext);

  return (
    <div className={styles.container}>
      {nationFlags.map((n, index) => (
        <div key={index}>
          <img src={`/assets/nationwarriors/flags/${n.toUpperCase()}.png`} alt={n} className={styles.flag} style={index === 0 ? {left: '45px'} : {right: '45px'}} />
        </div>
      ))}
    </div>
  );
};

export default NationsMatchesSidePanels;
