import {useEffect, useState} from 'react';
import {deleteMember, loadMembersList, Member, memberDetailsUnloaded} from 'src/features/website/members';
import Icon from 'awesome-react-icons';
import {useNavigate} from 'react-router-dom';
import {Button, Modal} from 'react-bootstrap';
import {getUser} from 'src/utils/localUser';
import styles from './MembersList.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

const MembersList = () => {
  const dispatch = useAppDispatch();
  const {membersList} = useAppSelector((s) => s.members);
  const navigate = useNavigate();
  const [openDeleteModal, setDeleteOpenModal] = useState(false);
  const [currentMember, setCurrentMember] = useState<Member>();

  // Route not allowed for non editor users
  useEffect(() => {
    if (getUser().role === 'editor') navigate('/');
  }, [navigate]);

  useEffect(() => {
    dispatch(memberDetailsUnloaded());
    dispatch(loadMembersList());
  }, [dispatch]);

  const handleDeleteClick = () => {
    if (currentMember?.id) {
      dispatch(deleteMember(currentMember?.id));
      setDeleteOpenModal(false);
    }
  };

  return (
    <div className={styles.membersListContainer}>
      <Modal show={openDeleteModal} onHide={() => setDeleteOpenModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete{' '}
          {`${currentMember?.firstname} ${currentMember?.lastname}`} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteOpenModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleDeleteClick()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <div className={styles.title}>Members List</div>
      <div className={styles.tableContainer}>
        <table className="table table-dark">
          <thead>
            <tr>
              <th scope="col">Firstname</th>
              <th scope="col">Lastname</th>
              <th scope="col">Description</th>
              <th scope="col">Pole</th>
              <th scope="col">Position</th>
              <th scope="col">Photo</th>
              <th scope="col">Update</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {membersList && membersList.map((member: Member) => (
              <tr key={member?.id}>
                <td>{member?.firstname}</td>
                <td>{member?.lastname}</td>
                <td>{member?.description}</td>
                <td>{member?.pole}</td>
                <td>{member?.position}</td>
                <td>
                  <img
                    src={member?.imageUrl}
                    alt={`${member?.firstname} profile`}
                    className={styles.photo}
                  />
                </td>
                <td>
                  <div
                    className={styles.cursorPointer}
                    onClick={() => {
                      navigate(`/website/members/update/${member.id}`);
                    }}
                  >
                    <Icon name="activity" />
                  </div>
                </td>
                <td>
                  <button
                    className="deleteButton"
                    onClick={() => {
                      setDeleteOpenModal(true);
                      setCurrentMember(member);
                    }}
                  >
                    <Icon name="trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MembersList;
