/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import {useNavigate} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Controller} from 'react-hook-form';
import * as yup from 'yup';
import {createTalent} from 'src/features/website/talents';
import {createOptions} from 'src/utils/selectOptions';
import ImagePicker from 'src/components/Global/ImagePicker';
import {loadEventsList} from 'src/features/website/events';
import {AlertTypes, createAlert} from 'src/features/global/alert';
import BackButton from 'src/components/Global/BackButton';
import styles from './TalentCreate.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

interface IFormInputs {
  title: string;
  description: string;
  firstname: string;
  lastname: string;
  nickname: string;
  nationality: string;
  game: string;
  teamName: string;
  birth_date: string;
  member_since: string;
  events: { label: string; value: string }[];
}

interface TalentImage {
  image: File | undefined;
}

const SignupSchema = yup.object().shape({
  title: yup.string().min(4, 'must be at least 4 characters'),
  description: yup.string().min(8, 'must be at least 8 characters'),
  firstname: yup.string().min(4, 'must be at least 4 characters'),
  lastname: yup.string().min(4, 'must be at least 4 characters'),
  nickname: yup.string().min(2, 'must be at least 2 characters'),
  nationality: yup.string().min(2, 'must be at least 2 characters'),
  game: yup.string().min(2, 'must be at least 2 characters'),
  teamName: yup.string().min(2, 'must be at least 2 characters'),
  birth_date: yup.date().required('Birth Date must not be empty'),
  member_since: yup.date().required('Member since must not be empty'),
});

interface TalentCreateProps {
  external?: boolean;
  setCreateTalent?: (value: boolean) => void;
}

const TalentCreate = ({external, setCreateTalent}: TalentCreateProps) => {
  const {eventsList} = useAppSelector((s) => s.events);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {register, handleSubmit, control, formState: {errors, isValid}} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });
  const [talentImage, setTalentImage] = useState<TalentImage>({
    image: undefined,
  });

  const onFileChange = (file: File | undefined) => {
    setTalentImage({image: file});
  };

  const onSubmitTalentCreate = (data: IFormInputs) => {
    const newTalent = new FormData();
    if (!talentImage.image) {
      dispatch(createAlert('Please pick a photo', AlertTypes.warning));
      return;
    }
    if (data.member_since) newTalent.append('member_since', data.member_since);
    if (data.description) newTalent.append('description', data.description);
    if (data.nationality) newTalent.append('nationality', data.nationality);
    if (data.birth_date) newTalent.append('birth_date', data.birth_date);
    if (talentImage.image) newTalent.append('image', talentImage.image);
    if (data.firstname) newTalent.append('firstname', data.firstname);
    if (data.nickname) newTalent.append('nickname', data.nickname);
    if (data.lastname) newTalent.append('lastname', data.lastname);
    if (data.teamName) newTalent.append('teamName', data.teamName);
    if (data.title) newTalent.append('title', data.title);
    if (data.game) newTalent.append('game', data.game);
    if (data.events) {
      data.events.forEach((event) => {
        newTalent.append('events[]', event.value);
      });
    }
    dispatch(createTalent(newTalent, !!setCreateTalent && setCreateTalent, !external && navigate));
  };

  useEffect(() => {
    dispatch(loadEventsList());
  }, [dispatch]);

  const selectOptions = createOptions(eventsList);

  return (
    <div>
      <div className={styles.title}>Create Talent</div>
      {!external && <BackButton />}
      <form onSubmit={handleSubmit(onSubmitTalentCreate)}>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Firstname</label>
            <input {...register('firstname')} />
            {errors.firstname && <p>{errors.firstname.message}</p>}

          </div>
          <div className="w-50 pl-3">
            <label>Lastname</label>
            <input {...register('lastname')} />
            {errors.lastname && <p>{errors.lastname.message}</p>}
          </div>
        </div>
        <div className="d-flex">
          <div className="w-50 pr-3">

            <label>Nickname</label>
            <input {...register('nickname')} />
            {errors.nickname && <p>{errors.nickname.message}</p>}

          </div>
          <div className="w-50 pl-3">
            <label>Team Name</label>
            <input {...register('teamName')} />
            {errors.teamName && <p>{errors.teamName.message}</p>}
          </div>
        </div>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Game</label>
            <input {...register('game')} />
            {errors.game && <p>{errors.game.message}</p>}

          </div>
          <div className="w-50 pl-3">

            <label>Nationality</label>
            <input {...register('nationality')} />
            {errors.nationality && <p>{errors.nationality.message}</p>}
          </div>
        </div>

        <label>Description</label>
        <input {...register('description')} />
        <p>{errors.description?.message}</p>

        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Member Since</label>
            <Controller
              control={control}
              name="member_since"
              render={({field}) => (
                <DatePicker
                  placeholderText="Select date"
                  onChange={(date: any) => field.onChange(date)}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  selected={field.value}
                />
              )}
            />
          </div>
          <div className="w-50 pl-3">
            <label>Birth Date</label>
            <Controller
              control={control}
              name="birth_date"
              render={({field}) => (
                <DatePicker
                  placeholderText="Select date"
                  onChange={(date: any) => field.onChange(date)}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  selected={field.value}
                />
              )}
            />
          </div>
        </div>

        <label>Events List</label>
        <Controller
          name="events"
          control={control}
          render={({field}) => <Select
            {...field}
            isMulti
            options={selectOptions}
          />}
        />

        <label>Pick Photo</label>
        <div className={styles.imagePickerContainer}>
          <ImagePicker
            labelText="Pick a photo"
            fileHandler={onFileChange}
            errorText=""
            previewHeight={200}
            previewWidth={200}
            heightRatio={1}
            widthRatio={1}
          />
        </div>
        <input type="submit" value="Publish" disabled={!isValid} />
      </form>
    </div>
  );
};
export default TalentCreate;
