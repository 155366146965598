import {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import {RootState} from 'src/app/rootReducer';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import ImagePicker from 'src/components/Global/ImagePicker';
import * as yup from 'yup';
import {loadPartnersList} from 'src/features/website/partners';
import {loadEvent, loadEventsList, loadRessourcesList, updateEvent} from 'src/features/website/events';
import {createOptions, createTagsOptions, tagsOptions} from 'src/utils/selectOptions';
import {loadTalentsList} from 'src/features/website/talents';
import {yupResolver} from '@hookform/resolvers/yup';
import BackButton from 'src/components/Global/BackButton';
import {Button, Modal} from 'react-bootstrap';
import RessourceCreate from 'src/components/Website/Ressources/RessourceCreate';
import PartnerCreate from 'src/components/Website/Partners/PartnerCreate';
import TalentCreate from 'src/components/Website/Talents/TalentCreate';
import Icon from 'awesome-react-icons';
import styles from './RealisationUpdate.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

interface IFormInputs {
  title: string;
  description: string;
  game: string;
  link: string;
  thumbnail: string;
  periodicity: string;
  imageUrl: string;
  ressources: { label: string; value: string }[];
  talents: { label: string; value: string }[];
  clients: { label: string; value: string }[];
  tags: { label: string; value: string }[];
  start_date: string;
  end_date: string;
}

interface RealisationImage {
  image: File | undefined;
}

const SignupSchema = yup.object().shape({
  title: yup.string().min(2, 'Title must be at least 2 characters'),
  description: yup.string().min(2, 'Description must be at least 2 characters'),
  periodicity: yup.string().min(2, 'Periodicity must be at least 2 characters'),
  link: yup.string().min(2, 'Link must be at least 2 characters'),
});

const PartnerUpdate = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {id} = useParams();
  console.log(id);
  const {events, partners, talents} = useAppSelector((s: RootState) => s);
  const {eventDetails, eventsList, ressourcesList} = events;
  const {partnersList} = partners;
  const {talentsList} = talents;
  const [createRessource, setCreateRessource] = useState(false);
  const [createPartner, setCreatePartner] = useState(false);
  const [createTalent, setCreateTalent] = useState(false);
  const currentEvent = eventsList.filter((e) => e.id === id)[0];

  const {register, setValue, handleSubmit, control, getValues, formState: {errors, isDirty, isValid}} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });
  const [thumbnail, setThumbnail] = useState<RealisationImage>({
    image: undefined,
  });
  const [banner, setBanner] = useState<RealisationImage>({
    image: undefined,
  });

  const [photosArray, setPhotosArray] = useState<RealisationImage[]>([
    {image: undefined},
    {image: undefined},
    {image: undefined},
  ]);
  const onFileChange = (file: File | undefined, isBanner: boolean) => {
    if (isBanner) setBanner({image: file});
    else setThumbnail({image: file});
  };

  const onFileChangeArray1 = (file: File | undefined) => {
    setPhotosArray([{image: file}, ...photosArray.splice(1, 2)]);
  };
  const onFileChangeArray2 = (file: File | undefined) => {
    setPhotosArray([
      ...photosArray.splice(0, 1),
      {image: file},
      ...photosArray.splice(1, 2),
    ]);
  };
  const onFileChangeArray3 = (file: File | undefined) => {
    setPhotosArray([...photosArray.splice(0, 2), {image: file}]);
  };

  const onSubmitRealisationUpdate = (data: IFormInputs) => {
    const updatedEvent = new FormData();
    if (data.ressources) data.ressources.forEach((ressource) => { updatedEvent.append('ressources[]', ressource.value); });
    if (data.clients) data.clients.forEach((client) => { updatedEvent.append('clients[]', client.value); });
    if (data.talents) data.talents.forEach((talent) => { updatedEvent.append('talents[]', talent.value); });
    if (data.tags) data.tags.forEach((tag) => { updatedEvent.append('tags[]', tag.value); });
    if (photosArray[0].image) updatedEvent.append('photo1', photosArray[0].image);
    if (photosArray[1].image) updatedEvent.append('photo2', photosArray[1].image);
    if (photosArray[2].image) updatedEvent.append('photo3', photosArray[2].image);
    if (banner.image) updatedEvent.append('banner', banner.image);
    if (data.description) updatedEvent.append('description', data.description);
    if (data.start_date) updatedEvent.append('start_date', data.start_date);
    if (thumbnail.image) updatedEvent.append('thumbnail', thumbnail.image);
    if (data.end_date) updatedEvent.append('end_date', data.end_date);
    if (data.title) updatedEvent.append('title', data.title);
    if (data.game) updatedEvent.append('game', data.game);
    if (data.link) updatedEvent.append('link', data.link);
    if (data.periodicity) updatedEvent.append('periodicity', data.periodicity);
    dispatch(updateEvent(id!, updatedEvent, navigate));
  };

  useEffect(() => {
    dispatch(loadEvent(id!));
    dispatch(loadPartnersList());
    dispatch(loadRessourcesList());
    dispatch(loadEventsList());
    dispatch(loadTalentsList());
  }, [dispatch, id]);

  useEffect(() => {
    if (currentEvent) {
      setValue('title', currentEvent.title, {shouldValidate: true});
      setValue('description', currentEvent.description, {shouldValidate: true});
      setValue('link', currentEvent.link, {shouldValidate: true});
      setValue('periodicity', currentEvent.periodicity, {shouldValidate: true});
      setValue('game', currentEvent.game, {shouldValidate: true});
    }
  }, [currentEvent, setValue]);

  // Create Options and default options for select component
  const selectOptionsPartners = [...createOptions(partnersList), {label: 'Ogaming', value: 'ogaming'}];
  const selectOptionsTalents = createOptions(talentsList);
  const selectOptionsRessources = createOptions(ressourcesList);
  const defaultOptionsClients = currentEvent && createOptions(currentEvent.clients);
  const defaultOptionsRessources = (currentEvent && currentEvent.ressources) && createOptions(currentEvent.ressources);
  const defaultOptionsTalents = currentEvent && createOptions(currentEvent.talents);
  const defaultOptionsTags = currentEvent && createTagsOptions(currentEvent.tags);

  const addNewLine = () => {
    const currentValue = getValues().description;
    setValue('description', `${currentValue} \n`);
  };

  const handleOnSelect = (event: React.SyntheticEvent<HTMLTextAreaElement, Event>) => {
    const target = event.target as HTMLInputElement;
    console.log(target);
  };

  return (
    <div>
      <div className={styles.title}>Update Realisation</div>
      <BackButton />
      <Modal
        show={createPartner}
        onHide={() => {
          setCreatePartner(false);
          setTimeout(() => dispatch(loadPartnersList()), 2000);
        }
        }>
        <Modal.Body>
          <PartnerCreate external setCreatePartner={setCreatePartner}/>
        </Modal.Body>
      </Modal>
      <Modal
        show={createTalent}
        onHide={() => {
          setCreatePartner(false);
          setTimeout(() => dispatch(loadTalentsList()), 2000);
        }
        }>
        <Modal.Body>
          <TalentCreate external setCreateTalent={setCreateTalent}/>
        </Modal.Body>
      </Modal>

      <Modal
        show={createRessource}
        onHide={() => { setCreateRessource(false); setTimeout(() => dispatch(loadRessourcesList()), 2000); }
        }>
        <Modal.Body>
          <RessourceCreate external setCreateRessource={setCreateRessource}/>
        </Modal.Body>
      </Modal>
      <form onSubmit={handleSubmit(onSubmitRealisationUpdate)}>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Title</label>
            <input {...register('title')} defaultValue={eventDetails?.title} />
            {errors.title && <p>{errors.title.message}</p>}
          </div>
          <div className="w-50 pl-3">
            <label>Periodicity</label>
            <input {...register('periodicity')} defaultValue={eventDetails?.periodicity} />
            {errors.periodicity && <p>{errors.periodicity?.message}</p>}
          </div>
        </div>

        <Button onClick={() => addNewLine()}>
          <Icon name="more-vertical" />
        </Button>
        <label>Description</label>
        <textarea {...register('description')} defaultValue={eventDetails?.description} rows={4} onSelect={(e) => handleOnSelect(e)} />
        <p>{errors.description?.message}</p>

        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Game (optional)</label>
            <input {...register('game')} defaultValue={eventDetails?.game} />
            <p>{errors.game?.message}</p>
          </div>
          <div className="w-50 pl-3">
            <label>Link (Aftershow, VOD) - (enter &quot;null&quot; if none)</label>
            <input {...register('link')} defaultValue={eventDetails?.link} />
            <p>{errors.link?.message}</p>
          </div>
        </div>

        <div className="d-flex ">
          <div className="w-50 pr-3">
            <label>Start Date</label>
            <Controller
              control={control}
              name="start_date"
              render={({field}) => (
                <DatePicker
                  placeholderText="Select date"
                  onChange={(date: any) => field.onChange(date)}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  selected={field.value}
                />
              )}
            />
          </div>
          <div className="w-50 pl-3">
            <label>End Date</label>
            <Controller
              control={control}
              name="end_date"
              render={({field}) => (
                <DatePicker
                  placeholderText="Select date"
                  onChange={(date: any) => field.onChange(date)}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  selected={field.value}
                />
              )}
            />
          </div>
        </div>

        <label>Tags List</label>
        {defaultOptionsClients && (
          <Controller
            name="tags"
            control={control}
            render={({field}) => <Select
              {...field}
              isMulti
              options={tagsOptions}
              defaultValue={defaultOptionsTags} />}
          />
        )}

        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Ressources List</label>
            {defaultOptionsClients && (
              <Controller
                name="ressources"
                control={control}
                render={({field}) => <Select
                  {...field}
                  isMulti
                  options={selectOptionsRessources}
                  defaultValue={defaultOptionsRessources} />}
              />
            )}
          </div>
          <div className="w-50 pl-3">
            <label>Create New Ressource</label>
            <Button onClick={() => setCreateRessource(true)}>Create</Button>

          </div>
        </div>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Partners List</label>
            {defaultOptionsClients && (
              <Controller
                name="clients"
                control={control}
                render={({field}) => <Select
                  {...field}
                  isMulti
                  options={selectOptionsPartners}
                  defaultValue={defaultOptionsClients} />}
              />
            )}
          </div>
          <div className="w-50 pl-3">
            <label>Create New Partner</label>
            <Button onClick={() => setCreatePartner(true)}>Create</Button>

          </div>
        </div>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Talents List</label>
            {defaultOptionsTalents && (
              <Controller
                name="talents"
                control={control}
                render={({field}) => <Select
                  {...field}
                  isMulti
                  options={selectOptionsTalents}
                  defaultValue={defaultOptionsTalents} />}
              />
            )}
          </div>
          <div className="w-50 pl-3">
            <label>Create New Talent</label>
            <Button onClick={() => setCreatePartner(true)}>Create</Button>

          </div>
        </div>

        <div className="text-center text-white">Pick Banner</div>
        <div className={styles.imagePickerContainer}>
          {eventDetails?.thumbnail
            && <img
              src={eventDetails?.banner}
              alt="EventBanner"
              className={styles.oldImage}
            />}
          <ImagePicker
            labelText="Pick Banner"
            fileHandler={(f) => onFileChange(f, true)}
            errorText=""
            previewHeight={200}
            previewWidth={200}
            heightRatio={100}
            widthRatio={300}
          />
        </div>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Pick thumbnail</label>
            <div className={styles.imagePickerContainer}>
              {eventDetails?.thumbnail
                && <img
                src={eventDetails?.thumbnail}
                alt="EventThumbnail"
                className={styles.oldImage}
              /> }
              <ImagePicker
                labelText="Pick thumbnail"
                fileHandler={(f) => onFileChange(f, false)}
                errorText=""
                previewHeight={200}
                previewWidth={200}
                heightRatio={1}
                widthRatio={1}
              />
            </div>
          </div>
          <div className="w-50 pl-3">
            <label>Pick photo 1</label>
            <div className={styles.imagePickerContainer}>
              {eventDetails?.photos[0]
                && <img
                src={eventDetails?.photos[0]}
                alt="Eventpic1"
                className={styles.oldImage}
                />
              }
              <ImagePicker
                labelText="Pick photo 1"
                fileHandler={onFileChangeArray1}
                errorText=""
                previewHeight={200}
                previewWidth={200}
                heightRatio={1}
                widthRatio={2}
              />
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Pick photo 2</label>
            <div className={styles.imagePickerContainer}>
              {eventDetails?.photos[1]
                && <img
                src={eventDetails?.photos[1]}
                alt="Eventpic2"
                className={styles.oldImage}
              />
              }
              <ImagePicker
                labelText="Pick photo 2"
                fileHandler={onFileChangeArray2}
                errorText=""
                previewHeight={200}
                previewWidth={200}
                heightRatio={1}
                widthRatio={2}
              />
            </div>
          </div>
          <div className="w-50 pl-3">
            <label>Pick photo 3</label>
            <div className={styles.imagePickerContainer}>
              {eventDetails?.photos[2]
                && <img
                src={eventDetails?.photos[2]}
                alt="Eventpic3"
                className={styles.oldImage}
              />
                            }
              <ImagePicker
                labelText="Pick photo 3"
                fileHandler={onFileChangeArray3}
                errorText=""
                previewHeight={200}
                previewWidth={200}
                heightRatio={1}
                widthRatio={2}
              />
            </div>
          </div>
        </div>

        <input type="submit" value="Update" disabled={!isDirty} />
      </form>
    </div>
  );
};

export default PartnerUpdate;
