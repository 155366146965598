import {useNavigate} from 'react-router-dom';
import styles from './BackButton.module.css';

const BackButton = () => {
  const navigate = useNavigate();
  return (
    <button
      type="button"
      className={`${styles.backButton} btn btn-primary`}
      onClick={() => navigate(-1)}
    >
      Back
    </button>
  );
};

export default BackButton;
