/* eslint-disable react/jsx-props-no-spreading */
import {yupResolver} from '@hookform/resolvers/yup';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import BackButton from 'src/components/Global/BackButton';
import {AlertTypes, createAlert} from 'src/features/global/alert';
import {createArticle} from 'src/features/website/articles';
import {useAppDispatch} from 'src/hooks/hooks';
import * as yup from 'yup';
import ImagePicker from '../../../Global/ImagePicker';
import styles from './ArticleCreate.module.css';

interface ArticleImage {
  image: File | undefined;
}

interface IFormInputs {
  url: string;
  title: string;
}

const SignupSchema = yup.object().shape({
  url: yup.string().min(6, 'URL must be at least 6 characters'),
  title: yup.string().min(4, 'Title must be at least 4 characters'),
});

const CreateArticle = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {register, handleSubmit, formState: {errors, isValid}} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });

  const [articleImage, setArticleImage] = useState<ArticleImage>({
    image: undefined,
  });

  const onFileChange = (file: File | undefined) => {
    setArticleImage({image: file});
  };

  const OnSubmitArticleCreate = (data: IFormInputs) => {
    const newArticle = new FormData();
    if (!articleImage.image) {
      dispatch(createAlert('Please pick a photo', AlertTypes.warning));
      return;
    }
    if (data.title) newArticle.append('title', data.title);
    if (articleImage.image) newArticle.append('image', articleImage.image);
    if (data.url) newArticle.append('url', data.url);
    dispatch(createArticle(newArticle, navigate));
  };

  return (
    <div>
      <div className={styles.title}>Create Article</div>
      <BackButton />
      <form onSubmit={handleSubmit(OnSubmitArticleCreate)}>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Title</label>
            <input {...register('title')} />
            {errors.title && <p>{errors.title.message}</p>}

          </div>
          <div className="w-50 pl-3">
            <label>URL</label>
            <input {...register('url')} />
            <p>{errors.url?.message}</p>
          </div>
        </div>

        <label>Pick Photo</label>
        <div className={styles.imagePickerContainer}>
          <ImagePicker
            labelText="Add an image for the article"
            fileHandler={onFileChange}
            errorText=""
            previewHeight={180}
            previewWidth={320}
            heightRatio={180}
            widthRatio={320}
          />
        </div>
        <input type="submit" value="Publish" disabled={!isValid}/>
      </form>
    </div>
  );
};

export default CreateArticle;
