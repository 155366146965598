/* eslint-disable react/jsx-props-no-spreading */
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {createChannel} from 'src/features/tools/basecamp';
import {useAppDispatch} from 'src/hooks/hooks';
import * as yup from 'yup';
import styles from './CreateChannel.module.css';

interface IFormInputs {
  channel: string;
  viewers: number;
}

const SignupSchema = yup.object().shape({
  channel: yup.string().min(2, 'Channel must be at least 2 characters'),
  viewers: yup.number().min(1, 'Viewers must be at least 1'),
});

const CreateChannel = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {register, handleSubmit, formState: {errors, isDirty, isValid}} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });
  const onSubmitNationUpdate = async (data: IFormInputs) => {
    const newChannel = {
      channel: data.channel,
      viewers: data.viewers,
    };
    dispatch(createChannel(newChannel, navigate));
  };
  return (
    <div className={styles.pageContainer}>

      <div className={styles.title}>Add Channel</div>
      <form onSubmit={handleSubmit(onSubmitNationUpdate)}>
        <div>
          <label>Channel Name</label>
          <input
            {...register('channel')}
          />
          {errors.channel && <p>{errors.channel.message}</p>}
        </div>
        <div>
          <label>Expected viewership</label>
          <input
            {...register('viewers')}
          />
          {errors.viewers && <p>{errors.viewers.message}</p>}
        </div>
        <input type="submit" value="Create" disabled={!isDirty} />
      </form>
    </div>
  );
};

export default CreateChannel;
