import {useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useParams} from 'react-router-dom';
import {RootState} from 'src/app/rootReducer';
import {
  createPoll,
  deletePoll,
  fetchActivePollForChannel,
  fetchChannel,
  fetchPollsList,
  Poll,
  renderOverlay,
  showResults,
  startPoll,
  stopPoll} from 'src/features/tools/basecamp';
import Icon from 'awesome-react-icons';
import styles from './ChannelDashboard.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

interface Params {
  channelId: string
  channel: string
}

enum VotesSteps {
  RENDER = 'render',
  START = 'start',
  RESULT = 'result',
  STOP = 'stop',
}

const VoteInitialised : Poll = {
  _id: '',
  votes: {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
  },
  votesPercentage: {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
  },
  channel: '',
  alreadyVoted: [],
  question: '',
  answers: {
    answerA: '',
    answerB: '',
    answerC: '',
    answerD: '',
  },
  correctAnswer: '',
  voteFinished: false,
};

interface IFormInputs {
  _id: string
  question: string
  answers:{
    answerA: string
    answerB: string
    answerC: string
    answerD: string
  }
  correctAnswer: string
}

const baseApiUrl = process.env.REACT_APP_URL ?? '';

const PollDashboard = () => {
  const {channelId, channel} = useParams();
  const [currentStep, setCurrentStep] = useState<VotesSteps>(VotesSteps.RENDER);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentPollId, setCurrentPollId] = useState<string>('');
  const dispatch = useAppDispatch();
  const {activePoll, currentChannel} = useAppSelector((s: RootState) => s.basecamp);
  const {register, handleSubmit, getValues, setValue} = useForm<IFormInputs>({
    mode: 'onChange',
  });

  const [voteResult] = useState<Poll>(VoteInitialised);

  const handleShowResults = () => {
    dispatch(showResults(channelId!, currentPollId));
  };
  useEffect(() => {
    dispatch(fetchChannel(channelId!));
    dispatch(fetchPollsList(channelId!));
    dispatch(fetchActivePollForChannel(channelId!));
  }, []);

  const handleLoadValues = (data: Poll) => {
    const fields = Object.getOwnPropertyNames(getValues());
    fields.forEach((field) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setValue(field, data[`${field}`]);
    });
  };

  const handleSubmitForm = async (data: IFormInputs) => {
    setCurrentStep(VotesSteps.RESULT);
    const newVote: any = {
      question: '',
      answers: {
        answerA: '',
        answerB: '',
        answerC: '',
        answerD: '',
      },
      correctAnswer: '',
      channel,
      _id: channelId,
    };
    if (data.correctAnswer) newVote.correctAnswer = data.correctAnswer;
    if (data.answers) newVote.answers = data.answers;
    if (data.question) newVote.question = data.question;
    await dispatch(startPoll(channelId!, currentPollId));
    await dispatch(fetchActivePollForChannel(channelId!));
  };

  const handleDeletePoll = () => {
    dispatch(deletePoll(channelId!, currentPollId));
    setOpenDeleteModal(false);
    setTimeout(() => dispatch(fetchChannel(channelId!)), 1000);
  };

  const handleRenderOverlay = () => {
    const pollData = {
      ...getValues(),
      pollId: currentPollId,
      votesPercentage: {
        A: 0,
        B: 0,
        C: 0,
        D: 0,
      },
    };
    if (currentChannel) dispatch(renderOverlay(pollData, currentChannel.channel));
  };

  const handleStopPoll = () => {
    dispatch(stopPoll(channelId!, channel!));
  };

  return (
    <>
      <div className={` ${styles.pageContainer}`}>
      <div className={styles.pageTitle}>
        USE SLOW MODE FOR THE DURATION OF EACH POLL. (Otherwise people can vote multiple time)
      </div>
      <Modal show={openDeleteModal} onHide={() => setOpenDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Vote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the vote ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpenDeleteModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleDeletePoll()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div>
            <label>Question</label>
            <input {...register('question')} defaultValue={activePoll && activePoll?.question}/>
          </div>
          <div className="d-flex">
            <div className="w-75 pr-3">
              <label>Answer A</label>
              <input {...register('answers.answerA')} defaultValue={activePoll && activePoll?.answers.answerA}/>

            </div>
            <div className={`w-25 ${styles.marginLeft}`}>
              <label># Votes</label>
              <input value={voteResult?.votes?.A} type="number" defaultValue={activePoll && activePoll?.votes.A}/>

            </div>
          </div>
          <div className="d-flex">
            <div className="w-75 pr-3">
              <label>Answer B</label>
              <input {...register('answers.answerB')} defaultValue={activePoll && activePoll?.answers.answerB}/>
            </div>
            <div className={`w-25 ${styles.marginLeft}`}>
              <label># Votes</label>
              <input value={voteResult?.votes?.B} type="number" defaultValue={activePoll && activePoll?.votes.B}/>

            </div>
          </div>
          <div className="d-flex">
            <div className="w-75 pr-3">
              <label>Answer C</label>
              <input {...register('answers.answerC')} defaultValue={activePoll && activePoll?.answers.answerC}/>
            </div>
            <div className={`w-25 ${styles.marginLeft}`}>
              <label># Votes</label>
              <input value={voteResult?.votes?.C} type="number" defaultValue={activePoll && activePoll?.votes.C}/>

            </div>
          </div>
          <div className="d-flex">
            <div className="w-75 pr-3">
              <label>Answer D</label>
              <input {...register('answers.answerD')} defaultValue={activePoll && activePoll?.answers.answerD}/>
            </div>
            <div className={`w-25 ${styles.marginLeft}`}>
              <label># Votes</label>
              <input value={voteResult?.votes?.D} type="number" defaultValue={activePoll && activePoll?.votes.D}/>

            </div>
          </div>
          <div className="d-flex">
            <div className={`w-50 ${styles.marginLeft}`}>
              <label>Correct Answer</label>
              <input {...register('correctAnswer')} defaultValue={activePoll && activePoll?.correctAnswer}/>
            </div>
          </div>

          <input
            type="submit"
            value="2-Start Poll"
            disabled={currentStep !== VotesSteps.START}
          />
        </form>

        <label>Link</label>
        {/* <input type="text" value="https://admin.alttabprod.com/tools/votes/QCM/render"/> */}
        <input type="text" value={`${baseApiUrl}/tools/basecamp/polls/render/${channelId}/${channel}`} />
        <div className="d-flex mt-4">
          <button
            className={`buttonBlue ${styles.width30}`}
            onClick={ () => {
              dispatch(createPoll(getValues(), channelId!));
              setTimeout(() => dispatch(fetchChannel(channelId!)), 1000);
            }}
          >
              Store Q&A
          </button>
          <button
            className={`buttonPink ${styles.width30}`}
            onClick={() => {
              setCurrentStep(VotesSteps.START);
              handleRenderOverlay();
            }}
            style={{opacity: currentStep !== VotesSteps.RENDER ? 0.3 : 1}}
            disabled={currentStep !== VotesSteps.RENDER}
          >
              1-Render overlay
          </button>
          <button
            className={`buttonYellow ${styles.width30}`}
            onClick={() => {
              handleShowResults();
              setCurrentStep(VotesSteps.STOP);
            }}
            style={{opacity: currentStep !== VotesSteps.RESULT ? 0.3 : 1}}
            disabled={currentStep !== VotesSteps.RESULT}

          >
            3-Show Result
          </button>
          <button
            onClick={() => {
              handleStopPoll();
              setCurrentStep(VotesSteps.RENDER);
            }}
            className={`buttonRed ${styles.width30}`}
            style={{opacity: currentStep !== VotesSteps.STOP ? 0.3 : 1}}
            disabled={currentStep !== VotesSteps.STOP}

          >
            4-Stop Poll
          </button>
        </div>
        <div className={styles.voteListContainer}>
          <div className={styles.title}>SAVED POLLS</div>
          <div className={styles.tableContainer}>
            <table className="table table-dark">
              <thead>
                <tr>
                  <th scope="col">Question</th>
                  <th scope="col">A</th>
                  <th scope="col">B</th>
                  <th scope="col">C</th>
                  <th scope="col">D</th>
                  <th scope="col">Correct Answer</th>
                  <th scope="col">Load</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                {currentChannel?.polls && currentChannel?.polls.map((poll: Poll) => (
                  <tr key={poll?._id}>
                    <td>{poll?.question}</td>
                    <td>{poll?.answers.answerA}</td>
                    <td>{poll?.answers.answerB}</td>
                    <td>{poll?.answers.answerC}</td>
                    <td>{poll?.answers.answerD}</td>
                    <td>{poll?.correctAnswer}</td>
                    <td>
                      <div
                        className={styles.cursorPointer}
                        onClick={() => {
                          handleLoadValues(poll);
                          setCurrentPollId(poll._id);
                        }
                        }
                      >
                        <Icon name="activity" />
                      </div>
                    </td>
                    <td>
                      <div
                        className={styles.cursorPointer}
                        onClick={() => {
                          setCurrentPollId(poll._id);
                          setOpenDeleteModal(true);
                        } }
                      >
                        <Icon name="trash" />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default PollDashboard;
