/* eslint-disable react/jsx-props-no-spreading */
import {useEffect, useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import ImagePicker from 'src/components/Global/ImagePicker';
import * as yup from 'yup';
import {loadPartnersList} from 'src/features/website/partners';
import {createEvent, loadEventsList, loadRessourcesList} from 'src/features/website/events';
import {createOptions, tagsOptions} from 'src/utils/selectOptions';
import {loadTalentsList} from 'src/features/website/talents';
import {yupResolver} from '@hookform/resolvers/yup';
import BackButton from 'src/components/Global/BackButton';
import PartnerCreate from 'src/components/Website/Partners/PartnerCreate';
import {Button, Modal} from 'react-bootstrap';
import TalentCreate from 'src/components/Website/Talents/TalentCreate';
import RessourceCreate from 'src/components/Website/Ressources/RessourceCreate';
import styles from './RealisationCreate.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

interface IFormInputs {
  title: string;
  description: string;
  game: string;
  link: string;
  thumbnail: string;
  periodicity: string;
  ressources: { label: string; value: string }[];
  imageUrl: string;
  talents: { label: string; value: string }[];
  clients: { label: string; value: string }[];
  tags: { label: string; value: string }[];
  start_date: string;
  end_date: string;
}

interface RealisationImage {
  image: File | undefined;
}

const SignupSchema = yup.object().shape({
  title: yup.string().min(2, 'Title must be at least 2 characters'),
  description: yup.string().min(2, 'Description must be at least 2 characters'),
  periodicity: yup.string().min(2, 'Periodicity must be at least 2 characters'),
  link: yup.string().min(2, 'Link must be at least 2 characters'),
});

const RealisationCreate = () => {
  const {partnersList} = useAppSelector((s) => s.partners);
  const {talentsList} = useAppSelector((s) => s.talents);
  const {ressourcesList} = useAppSelector((s) => s.events);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {register, handleSubmit, control, formState: {errors, isValid}} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });
  const [createClient, setCreateClient] = useState(false);
  const [createTalent, setCreateTalent] = useState(false);
  const [createRessource, setCreateRessource] = useState(false);
  const [thumbnail, setThumbnail] = useState<RealisationImage>({
    image: undefined,
  });
  const [banner, setBanner] = useState<RealisationImage>({
    image: undefined,
  });
  const [photosArray, setPhotosArray] = useState<RealisationImage[]>([
    {image: undefined},
    {image: undefined},
    {image: undefined},
  ]);
  const onFileChange = (file: File | undefined, isBanner: boolean) => {
    if (isBanner) setBanner({image: file});
    else setThumbnail({image: file});
  };

  const onFileChangeArray1 = (file: File | undefined) => {
    setPhotosArray([{image: file}, ...photosArray.splice(1, 2)]);
  };
  const onFileChangeArray2 = (file: File | undefined) => {
    setPhotosArray([
      ...photosArray.splice(0, 1),
      {image: file},
      ...photosArray.splice(1, 2),
    ]);
  };
  const onFileChangeArray3 = (file: File | undefined) => {
    setPhotosArray([...photosArray.splice(0, 2), {image: file}]);
  };

  const onSubmitRealisationCreate = (data: IFormInputs) => {
    const newEvent = new FormData();
    if (data.ressources) data.ressources.forEach((ressource) => { newEvent.append('ressources[]', ressource.value); });
    if (data.clients) data.clients.forEach((client) => { newEvent.append('clients[]', client.value); });
    if (data.talents) data.talents.forEach((talent) => { newEvent.append('talents[]', talent.value); });
    if (data.tags) data.tags.forEach((tag) => { newEvent.append('tags[]', tag.value); });
    if (photosArray[0].image) newEvent.append('photo1', photosArray[0].image);
    if (photosArray[1].image) newEvent.append('photo2', photosArray[1].image);
    if (photosArray[2].image) newEvent.append('photo3', photosArray[2].image);
    if (data.periodicity) newEvent.append('periodicity', data.periodicity);
    if (data.description) newEvent.append('description', data.description);
    if (data.start_date) newEvent.append('start_date', data.start_date);
    if (thumbnail.image) newEvent.append('thumbnail', thumbnail.image);
    if (banner.image) newEvent.append('banner', banner.image);
    if (data.end_date) newEvent.append('end_date', data.end_date);
    if (data.title) newEvent.append('title', data.title);
    if (data.game) newEvent.append('game', data.game);
    if (data.link) newEvent.append('link', data.link);

    dispatch(createEvent(newEvent, navigate));
  };

  useEffect(() => {
    dispatch(loadPartnersList());
    dispatch(loadEventsList());
    dispatch(loadRessourcesList());
    dispatch(loadTalentsList());
  }, [dispatch]);

  // Create Options and default options for select component
  const selectOptionsPartners = [...createOptions(partnersList), {label: 'Ogaming', value: 'ogaming'}];
  const selectOptionsTalents = createOptions(talentsList);
  const selectOptionsRessources = createOptions(ressourcesList);

  return (
    <div>
      <div className={styles.title}>Create Realisation</div>
      <BackButton />
      <Modal
        show={createRessource}
        onHide={() => {
          setCreateRessource(false);
          setTimeout(() => dispatch(loadRessourcesList()), 2000);
        }
        }>
        <Modal.Body>
          <RessourceCreate external setCreateRessource={setCreateRessource}/>
        </Modal.Body>
      </Modal>
      <Modal
        show={createTalent}
        onHide={() => {
          setCreateTalent(false);
          setTimeout(() => dispatch(loadTalentsList()), 2000);
        }
        }>
        <Modal.Body>
          <TalentCreate external setCreateTalent={setCreateTalent}/>
        </Modal.Body>
      </Modal>
      <Modal
        show={createClient}
        onHide={() => {
          setCreateClient(false);
          setTimeout(() => dispatch(loadPartnersList()), 2000);
        }
        }>
        <Modal.Body>
          <PartnerCreate external setCreatePartner={setCreateClient} />
        </Modal.Body>
      </Modal>
      <form onSubmit={handleSubmit(onSubmitRealisationCreate)}>

        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Title</label>
            <input {...register('title')} />
            {errors.title && <p>{errors.title.message}</p>}
          </div>

          <div className="w-50 pl-3">
            <label>Periodicity</label>
            <input {...register('periodicity')} />
            {errors.periodicity && <p>{errors.periodicity.message}</p>}
          </div>
        </div>

        <label>Description</label>
        <textarea {...register('description')} rows={4} />
        <p>{errors.description?.message}</p>

        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Game (optional)</label>
            <input {...register('game')} />
            <p>{errors.game?.message}</p>
          </div>
          <div className="w-50 pl-3">
            <label>Link (Aftershow, VOD) - (enter &quot;null&quot; if none)</label>
            <input {...register('link')} />
            <p>{errors.link?.message}</p>
          </div>
        </div>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Start Date</label>
            <Controller
              control={control}
              name="start_date"
              render={({field}) => (
                <DatePicker
                  placeholderText="Select date"
                  onChange={(date: any) => field.onChange(date)}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  selected={field.value}
                />
              )}
            />
          </div>
          <div className="w-50 pl-3">
            <label>End Date</label>
            <Controller
              control={control}
              name="end_date"
              render={({field}) => (
                <DatePicker
                  placeholderText="Select date"
                  onChange={(date: any) => field.onChange(date)}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  selected={field.value}
                />
              )}
            />
          </div>
        </div>

        <label>Tags List</label>
          <Controller
            name="tags"
            control={control}
            render={({field}) => <Select
              {...field}
              isMulti
              options={tagsOptions}
               />}
          />
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Ressources List</label>
            <Controller
              name="ressources"
              control={control}
              render={({field}) => <Select
                {...field}
                isMulti
                options={selectOptionsRessources}
              />}
            />
          </div>
          <div className="w-50 pl-3">
            <label>Create New Ressource</label>
            <Button onClick={() => setCreateRessource(true)}>Create</Button>
          </div>
        </div>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Clients List</label>
            <Controller
              name="clients"
              control={control}
              render={({field}) => <Select
                {...field}
                isMulti
                options={selectOptionsPartners}
              />}
            />

          </div>
          <div className="w-50 pl-3">
            <label>Create New Client</label>
            <Button onClick={() => setCreateClient(true)}>Create</Button>
          </div>
        </div>

        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Talents List</label>
            <Controller
              name="talents"
              control={control}
              render={({field}) => <Select
                {...field}
                isMulti
                options={selectOptionsTalents}
              />}
            />
          </div>
          <div className="w-50 pl-3">
            <label>Create New Talent</label>
            <Button onClick={() => setCreateTalent(true)}>Create</Button>

          </div>
        </div>
        <div className="text-center text-white">Pick Banner</div>
        <div className={styles.imagePickerContainer}>
          <ImagePicker
            labelText="Pick Banner"
            fileHandler={(f) => onFileChange(f, true)}
            errorText=""
            previewHeight={200}
            previewWidth={200}
            heightRatio={1}
            widthRatio={3}
          />
        </div>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Pick thumbnail</label>
            <div className={styles.imagePickerContainer}>

              <ImagePicker
                labelText="Pick thumbnail"
                fileHandler={(f) => onFileChange(f, false)}
                errorText=""
                previewHeight={200}
                previewWidth={200}
                heightRatio={1}
                widthRatio={1}
              />
            </div>

          </div>
          <div className="w-50 pl-3">
            <label>Pick photo 1</label>
            <div className={styles.imagePickerContainer}>

              <ImagePicker
                labelText="Pick photo 1"
                fileHandler={onFileChangeArray1}
                errorText=""
                previewHeight={200}
                previewWidth={200}
                heightRatio={1}
                widthRatio={2}
              />
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Pick photo 2</label>
            <div className={styles.imagePickerContainer}>

              <ImagePicker
                labelText="Pick photo 2"
                fileHandler={onFileChangeArray2}
                errorText=""
                previewHeight={1}
                previewWidth={3}
                heightRatio={1}
                widthRatio={2}
              />
            </div>

          </div>
          <div className="w-50 pl-3">
            <label>Pick photo 3</label>
            <div className={styles.imagePickerContainer}>

              <ImagePicker
                labelText="Pick photo 3"
                fileHandler={onFileChangeArray3}
                errorText=""
                previewHeight={200}
                previewWidth={200}
                heightRatio={1}
                widthRatio={2}
              />
            </div>
          </div>
        </div>

        <input type="submit" value="Publish" disabled={!isValid} />
      </form>
    </div>
  );
};

export default RealisationCreate;
