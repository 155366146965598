import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {useAppSelector} from 'src/hooks/hooks';
import styles from './RedirectLogin.module.css';

const RedirectLogin = () => {
  const {isAuthenticated} = useAppSelector((s) => s.authentication);
  const navigate = useNavigate();
  useEffect(() => {
    if (isAuthenticated) navigate('/home');
  }, [isAuthenticated]);

  const handleLoginClick = () => {
    if (process.env.REACT_APP_GOOGLE_LOGIN_URL) window.location.href = process.env.REACT_APP_GOOGLE_LOGIN_URL;
  };

  return (
    <div className={`${styles.pageContainer} flex`}>
      {!isAuthenticated && (
        <div className={styles.center}>
          <button type = "button" className={`${styles.button} btn btn-success`} onClick = { () => handleLoginClick() }>Log in</button>
        </div >
      )}
    </div>
  );
};

export default RedirectLogin;
