/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {AlertTypes, createAlert} from '../global/alert';
import {AppThunk} from '../global/helpers';

export interface Vote {
  type: string;
  votes: VotesResult;
  votesPercentage: VotesResult;
  channel: string;
  alreadyVoted: string[];
  question: string;
  answerA: string;
  answerB: string;
  answerC: string;
  answerD: string;
  correctAnswer: string;
  voteFinished: boolean;
  _id: string;
}

export interface VotesResult {
  A: number;
  B: number;
  C: number;
  D: number;
}

export interface EventState {
  votesList: Vote[];
  activeVote: Vote | '';
  finalResult: Vote | null;
  currentId: string;
}
const initialState: EventState = {
  votesList: [],
  activeVote: '',
  finalResult: null,
  currentId: '',
};

const votes = createSlice({
  name: 'votes',
  initialState,
  reducers: {
    votesListLoaded(state, {payload}: PayloadAction<Vote[]>) {
      state.votesList = payload;
    },
    activeVoteLoaded(state, {payload} : PayloadAction<Vote>) {
      state.activeVote = payload;
    },
    finalResultLoaded(state, {payload}: PayloadAction<Vote>) {
      state.finalResult = payload;
    },
    idLoaded(state, {payload}: PayloadAction<string>) {
      state.currentId = payload;
    },

  },
});

export default votes.reducer;

export const {votesListLoaded, activeVoteLoaded, finalResultLoaded, idLoaded} = votes.actions;

const baseApiUrl = process.env.REACT_APP_BASE_URL;

export const loadVotesList = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/votes/fetch/all`);

    dispatch(votesListLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const loadActiveVote = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/votes/fetch/active`);
    dispatch(activeVoteLoaded(response.data));
    dispatch(idLoaded(response.data._id));
    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  }
};

export const startVote = (data: any): AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/votes/start-vote`, data);
    dispatch(createAlert('Starting Vote', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  }
};

export const stopVote = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/votes/stop-vote/${id}`);
    dispatch(createAlert('Stopping Vote', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  }
};

export const renderOverlay = (data : any): AppThunk => async () => {
  try {
    const response = await axios.post(`${baseApiUrl}/votes/render-overlay`, data);
    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  }
};

export const storeVotesData = (data: any) : AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/votes/store-question-answers`, data);
    dispatch(createAlert('Question and Answers saved!', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  } finally {
    setTimeout(() => {
      dispatch(loadVotesList());
    }, 2000);
  }
};

export const showResults = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/votes/show-results/${id}`);
    dispatch(createAlert('Showing Results', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  }
};

export const deleteVote = (id: string): AppThunk => async (dispatch) => {
  try {
    await axios.delete(`${baseApiUrl}/votes/delete-vote/${id}`);
    dispatch(createAlert('Vote deleted', AlertTypes.success));
  } catch (err) {
    console.log(err);
  }
};

export const hidePercentages = (percentageBox: string): AppThunk => async (dispatch) => {
  const body = {
    answerBox: percentageBox,
  };
  try {
    await axios.post(`${baseApiUrl}/votes/hide-percentages`, body);
    dispatch(createAlert(`Hiding ${percentageBox}`, AlertTypes.success));
  } catch (err) {
    console.log(err);
  }
};
