import {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import {User, userLoaded} from 'src/features/global/authentication';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

const GoogleCallback = () => {
  const dispatch = useAppDispatch();
  const {isAuthenticated} = useAppSelector((s) => s.authentication);
  const {token} = useParams();
  const decoded = jwt_decode(token!) as User;
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) navigate('/home');
  }, [isAuthenticated]);

  useEffect(() => {
    const user = {...decoded, token};
    dispatch(userLoaded(user));
  }, [token]);

  return <div>google callback</div>;
};

export default GoogleCallback;
