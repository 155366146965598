/* eslint-disable no-underscore-dangle */
import Icon from 'awesome-react-icons';
import {useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {deleteEvent, Event, eventDetailsUnloaded, loadEventsList} from 'src/features/website/events';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';
import {formDateDay} from 'src/utils/momentDateFormat';

import styles from './RealisationsList.module.css';

const RealisationsList = () => {
  const dispatch = useAppDispatch();
  const {eventsList} = useAppSelector((s) => s.events);
  const navigate = useNavigate();
  const [openDeleteModal, setDeleteOpenModal] = useState(false);
  const [currentEvent, setCurrentEvent] = useState<Event>();
  const [dateSortedList, setDateSortedList] = useState<Event[]>();
  const [searchInput, setSearchInput] = useState('');
  const [eventSearch, setEventSearch] = useState<Event[]>();

  console.log({eventsList});
  useEffect(() => {
    dispatch(eventDetailsUnloaded());
    dispatch(loadEventsList());
  }, [dispatch]);

  useEffect(() => {
    if (eventsList.length > 1) {
      setDateSortedList(eventsList.slice().sort((a, b) => {
        if (a.start_date && b.start_date) {
          const dateA = new Date(a.start_date);
          const dateB = new Date(b.start_date);
          return dateB.getTime() - dateA.getTime();
        }
        return 0;
      }));
    }
  }, [eventsList]);

  useEffect(() => {
    if (searchInput !== '') {
      setEventSearch(dateSortedList?.filter((event) => event.title.toUpperCase().includes(searchInput.toUpperCase())));
    } else {
      setEventSearch(dateSortedList);
    }
  }, [searchInput, dateSortedList]);

  useEffect(() => {
    setEventSearch(eventsList);
  }, [eventsList]);
  const handleDeleteClick = () => {
    if (currentEvent?._id) {
      dispatch(deleteEvent(currentEvent?._id));
      setDeleteOpenModal(false);
    }
  };

  return (
    <div className={styles.membersListContainer}>
      <Modal show={openDeleteModal} onHide={() => setDeleteOpenModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Event</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {`${currentEvent?.title}`} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteOpenModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleDeleteClick()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <div className={`${styles.title} mb-3`}>Realisations List</div>
      <div className={styles.tableContainer}>
        <div className="input-group mb-3">
          <input type="text" className="form-control" placeholder="Search..." onChange={(e) => setSearchInput(e.target.value)}/>
        </div>

        <table className="table table-dark">
          <thead>
            <tr>
              <th scope="col">Title</th>
              <th scope="col">Description</th>
              <th scope="col">Client</th>
              <th scope="col">Game</th>
              <th scope="col">Ressources</th>
              <th scope="col">Start Date</th>
              <th scope="col">End Date</th>
              <th scope="col">Thumbnail</th>
              <th scope="col">Displayed In Agence</th>
              <th scope="col">Update</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {!!eventSearch && eventSearch.map((event: Event) => (
              <tr key={event?.id}>
                <td>{event?.title}</td>
                <td>{`${event?.description?.substring(0, 50)}...`}</td>
                <td>
                  {event?.clients.map((c) => (`${c.title} -`)) || 'Ogaming'}
                </td>
                <td>{event?.game}</td>
                <td>{`${event?.ressources.length}`}</td>
                <td>{formDateDay(event?.start_date)}</td>
                <td>{formDateDay(event?.end_date)}</td>

                <td>
                  <img
                    src={event?.thumbnail || event?.clients[0].imageUrl}
                    alt={`${event?.title} profile`}
                    className={styles.photo}
                  />
                </td>
                <td><input type="checkbox" name={event._id}/></td>
                <td>
                  <div
                    className={styles.cursorPointer}
                    onClick={() => {
                      navigate(`/website/realisations/update/${event?._id}`);
                    }}
                  >
                    <Icon name="activity" />
                  </div>
                </td>
                <td>
                  <button
                    className="deleteButton"
                    onClick={() => {
                      setDeleteOpenModal(true);
                      setCurrentEvent(event);
                    }}
                  >
                    <Icon name="trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
    </div>
  );
};

export default RealisationsList;
