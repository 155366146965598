import styles from './NationsMatchesWaitingScreen.module.css';
import MatchContext from '../NationsWarsMatchContext';
import {useContext, useEffect} from 'react';

const NationsMatchesWaitingScreen = () => {
  const {currentMatch} = useContext(MatchContext);


  return (
    <div className={styles.container} style={{opacity : currentMatch?.currentStep === 0 ? 1 : 0}}>
      WAITING...
    </div>
  );
};

export default NationsMatchesWaitingScreen;
