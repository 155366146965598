/* eslint-disable react/jsx-props-no-spreading */
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import Select from 'react-select';
import {addPlayer, loadNation} from 'src/features/tools/nationwars';
import {useAppDispatch} from 'src/hooks/hooks';
import {raceSelectOptions} from 'src/utils/nationwarsHelpers';
import * as yup from 'yup';
import styles from './AddPlayer.module.css';

interface IFormInputs {
  firstname: string;
  lastname: string;
  nickname: string;
  race: { label: string; value: string };
}

interface AddPlayerProps {
  nationId: string;
  setOpenModal: (openModal: boolean) => void;
}
const SignupSchema = yup.object().shape({
  firstname: yup.string().min(2, 'Firstname must be at least 2 characters'),
  lastname: yup.string().min(2, 'Lastname must be at least 2 characters'),
  nickname: yup.string().min(2, 'Nickname must be at least 2 characters'),
  // race: yup.string().min(4, 'Race must be at least 4 characters'),
});

const AddPlayer = ({nationId, setOpenModal} : AddPlayerProps) => {
  const dispatch = useAppDispatch();
  const {register, handleSubmit, control, formState: {errors, isValid}} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });

  const onSubmitMemberCreate = async (data: IFormInputs) => {
    const newPlayer = {
      firstname: data.firstname,
      lastname: data.lastname,
      nickname: data.nickname,
      race: data.race.value,
    };
    dispatch(addPlayer(newPlayer, nationId));
    dispatch(loadNation(nationId));
    setOpenModal(false);
  };

  return (
    <div>
      <div className={styles.title}>Add Player</div>
      <form onSubmit={handleSubmit(onSubmitMemberCreate)}>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>First Name</label>
            <input {...register('firstname')} />
            {errors.firstname && <p>{errors.firstname.message}</p>}

          </div>
          <div className="w-50 pl-3">
            <label>Last Name</label>
            <input {...register('lastname')} />
            <p>{errors.lastname?.message}</p>
          </div>
        </div>
        <div className="d-flex">
          <div className="w-100 pr-3">
            <label>Nickname</label>
            <input {...register('nickname')} />
            <p>{errors.nickname?.message}</p>
          </div>

        </div>
        <div className={styles.select}>
          <label>Race</label>
          <Controller
            name="race"
            control={control}
            render={({field}) => <Select
              {...field}
              options={raceSelectOptions}
            />}
          />
        </div>
        <input type="submit" value="Publish" disabled={!isValid} />
      </form>
    </div>
  );
};

export default AddPlayer;
