/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {getUser} from 'src/utils/localUser';
import {AlertTypes, createAlert} from '../global/alert';
import {Event} from './events';
import {AppThunk} from '../global/helpers';

export interface Partner {
  type: 'Partner';
  id: string;
  title: string;
  description: string;
  date: Date;
  imageUrl: string;
  events: Event[];
  clientType: string;
  updatedAt: Date;
  displayRank: number;
}

export interface PartnerState {
  partnersList: Partner[];
  partnerDetails: Partner | null;
}

const initialState: PartnerState = {
  partnersList: [],
  partnerDetails: null,
};

const partners = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    partnersLoaded(state, {payload}: PayloadAction<Partner[]>) {
      state.partnersList = payload;
    },
    partnerDetailsLoaded(state, {payload}: PayloadAction<Partner>) {
      state.partnerDetails = payload;
    },
    partnerDetailsUnloaded(state, {payload}: PayloadAction<null>) {
      state.partnerDetails = payload;
    },
  },
});

export const {partnersLoaded, partnerDetailsLoaded, partnerDetailsUnloaded} = partners.actions;

export default partners.reducer;

const baseApiUrl = process.env.REACT_APP_BASE_URL;
let token: string | undefined;
if (getUser()) {
  token = getUser().token;
}

export const updatePartnerRank = (id: string, event: any): AppThunk => async (dispatch) => {
  const config = {headers: {Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.patch(
      `${baseApiUrl}/partners/update/rank/${id}`,
      event,
      config,
    );
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const loadPartnersList = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/partners/fetch/all`);

    dispatch(partnersLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const loadPartner = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/partners/fetch/${id}`, {headers: {Authorization: `Bearer ${token}`}});
    dispatch(partnerDetailsLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const createPartner = (partner: any, setCreatePartner: any, history?: any): AppThunk => async (dispatch) => {
  const config = {headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.post(
      `${baseApiUrl}/partners/create`,
      partner,
      config,
    );
    dispatch(createAlert('Partner successfully created!', AlertTypes.success));
    if (history) setTimeout(() => history(-1), 1500);
    if (setCreatePartner) setCreatePartner(false);

    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const updatePartner = (id: string, partner: any, history?: any): AppThunk => async (
  dispatch,
) => {
  const config = {headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.patch(`${baseApiUrl}/partners/update/${id}`, partner, config);
    dispatch(createAlert('Partner successfully updated!', AlertTypes.success));
    if (history) setTimeout(() => history(-1), 1500);

    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const deletePartner = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.delete(`${baseApiUrl}/partners/delete/${id}`, {headers: {Authorization: `Bearer ${token}`}});
    dispatch(createAlert('Partner successfully deleted!', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};
