import {Link, useNavigate} from 'react-router-dom';
import {deleteMatch, loadMatchsList, loadNationsListNoIp, NationMatch} from 'src/features/tools/nationwars';
import {Button, Modal} from 'react-bootstrap';
import Icon from 'awesome-react-icons';

import styles from './NationsVetoesMatchList.module.css';
import {useEffect, useState} from 'react';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';



const NationsVetoesMatchList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [openDeleteModal, setDeleteOpenModal] = useState(false);
  const [currentMatch, setCurrentMatch] = useState<NationMatch>();
  const {matchList} = useAppSelector((s) => s.nations);

  useEffect(() => {
    dispatch(loadNationsListNoIp());
    dispatch(loadMatchsList());
  }, []);




  const handleDeleteClick = () => {
    if (currentMatch?.id) {
      dispatch(deleteMatch(currentMatch?.id));
      setDeleteOpenModal(false);
    }
  };

  return (
    <div className={styles.pageContainer}>
      <Modal show={openDeleteModal} onHide={() => setDeleteOpenModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Match</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {`${currentMatch?.nations[0].title} vs ${currentMatch?.nations[1].title}`} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteOpenModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleDeleteClick()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <div className={styles.title}>Nation Wars Veto System</div>
      <div className={styles.tableContainer}>
        <table className="table table-dark">
          <thead>
            <tr>
              <th scope="col">Team Blue</th>
              <th scope="col">Team Red</th>
              <th scope="col">Dashboard</th>
              <th scope="col">Render</th>
              <th scope="col">Update</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {matchList && matchList.map((match: NationMatch) => (
              <tr key={match?.id}>
                <td>{match?.nations[0].title}</td>
                <td>{match?.nations[1].title}</td>
                <td>
                  <div
                    className={styles.cursorPointer}
                    onClick={() => {
                      navigate(`/tools/nationwars/matches/dashboard/${match.id}`);
                    }}
                  >
                    <Icon name="book" />
                  </div>
                </td>
                <td className={styles.nationRender}>
                  <div
                    className={[styles.nationRenderText, styles.team1].join(' ')}
                  >
                    <Link className={styles.link} to={`/tools/nationwars/matches/render/${match.id}/${match.nations[0].flag}`} target="_blank">{match.nations[0].flag}</Link>


                  </div>
                  <div
                    className={[styles.nationRenderText, styles.teamStream].join(' ')}
                  >
                    <Link className={styles.link} to={`/tools/nationwars/matches/render/${match.id}/stream`} target="_blank">Stream Overlay</Link>

                  </div>
                  <div
                    className={[styles.nationRenderText, styles.team2].join(' ')}
                  >
                    <Link className={styles.link} to={`/tools/nationwars/matches/render/${match.id}/${match.nations[1].flag}`} target="_blank">{match.nations[1].flag}</Link>

                  </div>
                </td>
                <td>
                  <div
                    className={styles.cursorPointer}
                    onClick={() => {
                      navigate(`/tools/nationwars/matches/${match.id}`);
                    }}
                  >
                    <Icon name="activity" />
                  </div>
                </td>
                <td>
                  <button
                    className="deleteButton"
                    onClick={() => {
                      setDeleteOpenModal(true);
                      setCurrentMatch(match);
                    }}
                  >
                    <Icon name="trash" />
                  </button>
                </td>


              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NationsVetoesMatchList;
