import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {loadMember, updateMember} from 'src/features/website/members';
import BackButton from 'src/components/Global/BackButton';
import {getUser} from 'src/utils/localUser';
import ImagePicker from 'src/components/Global/ImagePicker';
import styles from './MemberUpdate.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';


interface IFormInputs {
  firstname: string;
  lastname: string;
  description: string;
  pole: string;
  position: string;
}

interface MemberImage {
  image: File | undefined;
}

const SignupSchema = yup.object().shape({
  firstName: yup.string().min(2, 'Firstname must be at least 2 characters'),
  lastName: yup.string().min(2, 'Lastname must be at least 2 characters'),
  description: yup.string().min(2, 'Description must be at least 2 characters'),
  pole: yup.string().min(2, 'Pole must be at least 2 characters'),
  position: yup.string().min(2, 'Position must be at least 2 characters'),
});

const MemberUpdate = () => {
  const {id} = useParams();
  const {memberDetails} = useAppSelector((s) => s.members);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {register, handleSubmit, setValue, formState: {errors, isDirty, isValid}} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });

  // Route not allowed for non editor users
  useEffect(() => {
    if (getUser().role === 'editor') navigate('/');
  }, [navigate]);

  const [memberImage, setMemberImage] = useState<MemberImage>({
    image: undefined,
  });
  const onFileChange = (file: File | undefined) => {
    setMemberImage({image: file});
  };

  const onSubmitMemberUpdate = (data: IFormInputs) => {
    const updatedMember = new FormData();
    if (data.description) updatedMember.append('description', data.description);
    if (memberImage.image) updatedMember.append('image', memberImage.image);
    if (data.firstname) updatedMember.append('firstname', data.firstname);
    if (data.lastname) updatedMember.append('lastname', data.lastname);
    if (data.position) updatedMember.append('position', data.position);
    if (data.pole) updatedMember.append('pole', data.pole);

    dispatch(updateMember(id!, updatedMember, navigate));
  };

  useEffect(() => {
    dispatch(loadMember(id!));
  }, [dispatch, id]);

  useEffect(() => {
    if (memberDetails) {
      setValue('description', memberDetails.description, {shouldValidate: true});
      setValue('firstname', memberDetails.firstname, {shouldValidate: true});
      setValue('lastname', memberDetails.lastname, {shouldValidate: true});
      setValue('position', memberDetails.position, {shouldValidate: true});
      setValue('pole', memberDetails.pole, {shouldValidate: true});
    }
  }, [memberDetails, setValue]);

  return (
    <div>
      <div className={styles.title}>Update Member</div>
      <BackButton />
      <form onSubmit={handleSubmit(onSubmitMemberUpdate)}>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>First Name</label>
            <input
              {...register('firstname')}
              defaultValue={memberDetails?.firstname}
            />
            {errors.firstname && <p>{errors.firstname.message}</p>}

          </div>
          <div className="w-50 pl-3">
            <label>Last Name</label>
            <input {...register('lastname')} defaultValue={memberDetails?.lastname} />
            <p>{errors.lastname?.message}</p>
          </div>
        </div>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Pole</label>
            <input {...register('pole')} defaultValue={memberDetails?.pole} />
            <p>{errors.pole?.message}</p>

          </div>
          <div className="w-50 pl-3">
            <label>Position</label>
            <input
              {...register('position')}
              defaultValue={memberDetails?.position}
            />
            <p>{errors.position?.message}</p>
          </div>
        </div>

        <label>Description</label>
        <input
          {...register('description')}
          defaultValue={memberDetails?.description}
        />
        <p>{errors.description?.message}</p>

        <label>Pick Photo</label>
        <div className={styles.imagePickerContainer}>
          {memberDetails?.imageUrl
            && <img
            src={memberDetails?.imageUrl}
            alt="Memberpic"
            className={styles.oldImage}
          />
          }
          <ImagePicker
            labelText="Add A Banner For Your Event"
            fileHandler={onFileChange}
            errorText=""
            previewHeight={200}
            previewWidth={200}
            heightRatio={1}
            widthRatio={1}
          />
        </div>
        <input type="submit" value="Update" disabled={!isDirty}/>
      </form>
    </div>
  );
};

export default MemberUpdate;
