import {Fragment, useState} from 'react';
import {Navigation} from 'react-minimal-side-navigation';
import Icon from 'awesome-react-icons';
import {useNavigate, useLocation} from 'react-router-dom';
import {getUser} from 'src/utils/localUser';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import styles from './Navbar.module.css';

const NavbarSiteWeb = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const role = getUser() ? getUser().role : null;
  const navbarItems = [
    {
      title: 'Home',
      itemId: '/home',
      elemBefore: () => <Icon name="cloud-snow" />,
    },
    {
      title: 'Dashboard',
      itemId: '/website/dashboard',
      elemBefore: () => <Icon name="coffee" />,
    },
    {
      title: 'Realisations',
      itemId: '/website/realisations/list',
      elemBefore: () => <Icon name="star" />,
      subNav: [
        {
          title: 'List',
          itemId: '/website/realisations/list',
        },
        {
          title: 'Order',
          itemId: '/website/realisations/order',
        },
        {
          title: 'Add new',
          itemId: '/website/realisations/create',
        },
      ],
    },
    {
      title: 'Ressources',
      itemId: '/website/ressources/list',
      elemBefore: () => <Icon name="eye" />,
      subNav: [
        {
          title: 'List',
          itemId: '/website/ressources/list',
        },
        {
          title: 'Add new',
          itemId: '/website/ressources/create',
        },
      ],
    },
    {
      title: 'Partners',
      itemId: '/website/partners/list',
      elemBefore: () => <Icon name="user" />,
      subNav: [
        {
          title: 'List',
          itemId: '/website/partners/list',
        },
        {
          title: 'Order',
          itemId: '/website/partners/order',
        },
        {
          title: 'Add new',
          itemId: '/website/partners/create',
        },
      ],
    },
    {
      title: 'Talents',
      itemId: '/website/talents/list',
      elemBefore: () => <Icon name="user" />,
      subNav: [
        {
          title: 'List',
          itemId: '/website/talents/list',
        },
        {
          title: 'Order',
          itemId: '/website/talents/order',
        },
        {
          title: 'Add new',
          itemId: '/website/talents/create',
        },
      ],
    },
    {
      title: 'Articles',
      itemId: '/website/articles/list',
      elemBefore: () => <Icon name="message-square" />,
      subNav: [
        {
          title: 'List',
          itemId: '/website/articles/list',
        },
        {
          title: 'Order',
          itemId: '/website/articles/order',
        },
        {
          title: 'Add new',
          itemId: '/website/articles/create',
        },
      ],
    },
  ];

  if (role === 'admin') {
    navbarItems.push({
      title: 'Members',
      itemId: '/website/members/list',
      elemBefore: () => <Icon name="coffee" />,
      subNav: [
        {
          title: 'List',
          itemId: '/website/members/list',
        },
        {
          title: 'Add new',
          itemId: '/website/members/create',
        },
      ],
    });
  }

  return (
    <div className={styles.navbarContainer}>
      <Fragment>
        <div
          onClick={() => setIsSidebarOpen(false)}
          className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
            isSidebarOpen ? 'block' : 'hidden'
          }`}
        />

        {/* Sidebar */}
        <div
          className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 ease-out transform translate-x-0
          bg-white border-r-2 lg:translate-x-0 lg:static lg:inset-0 ${
            isSidebarOpen
              ? 'ease-out translate-x-0'
              : 'ease-in -translate-x-full'
          }`}
        >
          <Navigation
            activeItemId={location.pathname}
            onSelect={({itemId}) => {
              navigate(itemId);
            }}
            items={navbarItems}
          />
        </div>
      </Fragment>
    </div>
  );
};

export default NavbarSiteWeb;
