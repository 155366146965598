/* eslint-disable no-param-reassign */
import {useEffect, useState} from 'react';
import io from 'socket.io-client';
import backgroundAR from 'src/assets/votes/jmqcm4/AR.png';
import backgroundAN from 'src/assets/votes/jmqcm4/AN.png';
import backgroundAW from 'src/assets/votes/jmqcm4/AW.png';
import backgroundBR from 'src/assets/votes/jmqcm4/BR.png';
import backgroundBN from 'src/assets/votes/jmqcm4/BN.png';
import backgroundBW from 'src/assets/votes/jmqcm4/BW.png';
import backgroundCN from 'src/assets/votes/jmqcm4/CN.png';
import backgroundCR from 'src/assets/votes/jmqcm4/CR.png';
import backgroundCW from 'src/assets/votes/jmqcm4/CW.png';
import backgroundDR from 'src/assets/votes/jmqcm4/DR.png';
import backgroundDN from 'src/assets/votes/jmqcm4/DN.png';
import backgroundDW from 'src/assets/votes/jmqcm4/DW.png';
import {loadActiveVote, Vote} from 'src/features/tools/votes';

import styles from './VotesRender.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

// stable
const baseApiUrl = process.env.REACT_APP_BASE_URL ?? '';

const basicVote = {
  _id: '',
  type: '',
  votes: {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
  },
  votesPercentage: {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
  },
  channel: '',
  alreadyVoted: [],
  question: '',
  answerA: '',
  answerB: '',
  answerC: '',
  answerD: '',
  correctAnswer: '',
  voteFinished: false,
  voteActive: false,
};

type HiddenPerc = 'A' | 'B' | 'C' | 'D' | 'ALL' | 'NONE';

const hiddenPercentagesKeys = ['A', 'B', 'C', 'D', 'ALL', 'NONE'] as const;
const voteAnswerKeys = ['A', 'B', 'C', 'D'] as const;

type AnswerId = typeof voteAnswerKeys[number];

type AnswerContainerState = 'neutral' | 'right' | 'wrong';

const BACKGROUND_COLOR: {
  readonly [State in AnswerContainerState]: string;
} = {
  neutral: '#eaf71b',
  right: '#150538',
  wrong: '#8b8b8b',
};

const containerBackgroundImage: {
  readonly [Id in AnswerId]: {
    readonly [State in AnswerContainerState]: string;
  };
} = {
  A: {
    right: backgroundAR,
    neutral: backgroundAN,
    wrong: backgroundAW,
  },
  B: {
    right: backgroundBR,
    neutral: backgroundBN,
    wrong: backgroundBW,
  },
  C: {
    right: backgroundCR,
    neutral: backgroundCN,
    wrong: backgroundCW,
  },
  D: {
    right: backgroundDR,
    neutral: backgroundDN,
    wrong: backgroundDW,
  },
};

const VotesRender = () => {
  const dispatch = useAppDispatch();
  const {activeVote} = useAppSelector((s) => s.votes);

  const [socket, setSocket] = useState<any>();
  const [actVote, setActVote] = useState<Vote | null | ''>(activeVote);
  const [hiddenPerc, setHiddenPerc] = useState<HiddenPerc>('ALL');
  useEffect(() => {
    dispatch(loadActiveVote());
  }, [dispatch]);

  useEffect(() => {
    setActVote(activeVote);
  }, [activeVote, dispatch]);

  useEffect(() => {
    setSocket(io(`${baseApiUrl}/backseat-poll`));
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('msgToClient', (data: any) => {
        setActVote(data);
      });
      socket.on('VoteOverToClient', () => {
        setActVote(basicVote);
      });
      socket.on('HidePercentages', (data: HiddenPerc) => {
        setHiddenPerc(data);
      });
      socket.on('RenderToClient', () => {});
    }
    return () => {
      if (socket?.connected) socket.disconnect();
    };
  }, [socket]);

  console.log({hiddenPerc});
  const handlePercDisplay = (answerKey: AnswerId) => {
    if (hiddenPerc === 'ALL') return 'block';
    if (hiddenPerc === 'NONE') return 'none';
    if (hiddenPerc !== answerKey) return 'block';
    return 'none';
  };
  return (
    <div className={styles.pageContainer}>
      <div className={styles.questionBackground}>
        <div className={styles.question}>{actVote && actVote.question}</div>

        {voteAnswerKeys.map((answerKey) => (
          <div
            key={answerKey}
            className={styles[`answer${answerKey}Container`]}
            style={
              actVote && actVote.voteFinished
                ? {
                  background: `url(${
                    actVote.correctAnswer === answerKey
                      ? containerBackgroundImage[answerKey].right
                      : containerBackgroundImage[answerKey].wrong
                  })`,
                  color:
                      actVote.correctAnswer === answerKey
                        ? BACKGROUND_COLOR.right
                        : BACKGROUND_COLOR.wrong,
                }
                : {}
            }
          >
            <div className={styles[`answer${answerKey}`]}>
              {actVote && actVote[`answer${answerKey}`]}
            </div>
            <div className={styles[`percentage${answerKey}`]}>
              {actVote && `${actVote.votesPercentage[answerKey]}%`}
              <div className={styles.hidePerc} style={
                {display: handlePercDisplay(answerKey)}
              }/>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VotesRender;
