
import {useEffect, useState} from 'react';
import Icon from 'awesome-react-icons';
import {useNavigate} from 'react-router-dom';
import {Button, Modal} from 'react-bootstrap';
import {deleteNation, loadNationsListNoIp, Nation, nationDetailsUnloaded} from 'src/features/tools/nationwars';
import Flag from 'react-world-flags';
import styles from './NationsList.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

const NationsList = () => {
  const dispatch = useAppDispatch();
  const {nationsList} = useAppSelector((s) => s.nations);
  const navigate = useNavigate();
  const [openDeleteModal, setDeleteOpenModal] = useState(false);
  const [currentNation, setCurrentNation] = useState<Nation>();

  useEffect(() => {
    dispatch(nationDetailsUnloaded());
    dispatch(loadNationsListNoIp());
  }, [dispatch]);

  const handleDeleteClick = () => {
    if (currentNation?._id) {
      dispatch(deleteNation(currentNation?._id));
      setDeleteOpenModal(false);
    }
  };

  return (
    <div className={styles.pageContainer}>
      <Modal show={openDeleteModal} onHide={() => setDeleteOpenModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Member</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete{' '}
          {`${currentNation?.title}`} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteOpenModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleDeleteClick()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <div className={styles.title}>Nations List</div>
      <div className={styles.tableContainer}>
        <table className="table table-dark">
          <thead>
            <tr>
              <th scope="col">Flag</th>
              <th scope="col">Title</th>
              <th scope="col">#Players</th>
              <th scope="col">Update</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {!!nationsList.length && nationsList.map((nation: Nation) => (
              <tr key={nation?._id}>
                <td><Flag code={nation.flag} height="16" /></td>
                <td>{nation?.title}</td>
                <td>{nation?.players.length}</td>
                <td>
                  <div
                    className={styles.cursorPointer}
                    onClick={() => {
                      navigate(`/tools/nationwars/nations/update/${nation._id}`);
                    }}
                  >
                    <Icon name="activity" />
                  </div>
                </td>
                <td>
                  <button
                    className="deleteButton"
                    onClick={() => {
                      setDeleteOpenModal(true);
                      setCurrentNation(nation);
                    }}
                  >
                    <Icon name="trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default NationsList;
