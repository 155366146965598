/* eslint-disable react/jsx-props-no-spreading */
import {useEffect, useState} from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import {useNavigate, useParams} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm, Controller} from 'react-hook-form';
import * as yup from 'yup';
import {loadTalent, loadTalentsList, updateTalent} from 'src/features/website/talents';
import {createOptions} from 'src/utils/selectOptions';
import ImagePicker from 'src/components/Global/ImagePicker';
import {loadEventsList} from 'src/features/website/events';
import BackButton from 'src/components/Global/BackButton';
import styles from './TalentUpdate.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

interface IFormInputs {
  title: string;
  description: string;
  firstname: string;
  lastname: string;
  nickname: string;
  nationality: string;
  game: string;
  teamName: string;
  birth_date: string;
  member_since: string;
  events: { label: string; value: string }[];
}

interface TalentImage {
  image: File | undefined;
}

const SignupSchema = yup.object().shape({
  title: yup.string().min(2, 'firstname must be at least 2 characters long'),
  description: yup.string().min(2, 'firstname must be at least 2 characters long'),
  firstname: yup.string().min(2, 'firstname must be at least 2 characters long'),
  lastname: yup.string().min(2, 'lastname must be at least 2 characters long'),
  nickname: yup.string().min(2, 'nickname must be at least 2 characters long'),
  nationality: yup.string().min(2, 'nationality must be at least 2 characters long'),
  teamName: yup.string().min(2, 'teamName must be at least 2 characters long'),
  game: yup.string().min(2, 'game must be at least 2 characters long'),

});

const TalentUpdate = () => {
  const {id} = useParams();
  const {talentDetails, talentsList} = useAppSelector((s) => s.talents);
  const {eventsList} = useAppSelector((s) => s.events);
  const currentTalent = talentsList.find((t) => t.id === id);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {register, handleSubmit, setValue, control, formState: {errors, isDirty, isValid}} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });
  const [talentImage, setTalentImage] = useState<TalentImage>({
    image: undefined,
  });

  const onFileChange = (file: File | undefined) => {
    setTalentImage({image: file});
  };

  const onSubmitTalentUpdate = (data: IFormInputs) => {
    const updatedTalent = new FormData();
    if (data.title) updatedTalent.append('title', data.title);
    if (data.description) updatedTalent.append('description', data.description);
    if (data.firstname) updatedTalent.append('firstname', data.firstname);
    if (data.lastname) updatedTalent.append('lastname', data.lastname);
    if (data.nickname) updatedTalent.append('nickname', data.nickname);
    if (data.nationality) updatedTalent.append('nationality', data.nationality);
    if (data.teamName) updatedTalent.append('teamName', data.teamName);
    if (data.game) updatedTalent.append('game', data.game);
    if (data.birth_date) updatedTalent.append('birth_date', data.birth_date);
    if (data.member_since) updatedTalent.append('member_since', data.member_since);
    if (talentImage.image) updatedTalent.append('image', talentImage.image);
    if (data.events) {
      data.events.forEach((event) => {
        updatedTalent.append('events[]', event.value);
      });
    }
    dispatch(updateTalent(id!, updatedTalent, navigate));
  };

  useEffect(() => {
    dispatch(loadTalentsList());
    dispatch(loadTalent(id!));
    dispatch(loadEventsList());
  }, [dispatch, id]);

  useEffect(() => {
    if (currentTalent) {
      setValue('title', currentTalent.title, {shouldValidate: true});
      setValue('description', currentTalent.description, {shouldValidate: true});
      setValue('firstname', currentTalent.firstname, {shouldValidate: true});
      setValue('lastname', currentTalent.lastname, {shouldValidate: true});
      setValue('nickname', currentTalent.nickname, {shouldValidate: true});
      setValue('nationality', currentTalent.nationality, {shouldValidate: true});
      setValue('teamName', currentTalent.teamName, {shouldValidate: true});
      setValue('game', currentTalent.game, {shouldValidate: true});
    }
  }, [currentTalent, setValue]);
  const selectOptions = createOptions(eventsList);
  const defaultOptions = currentTalent && createOptions(currentTalent.events);
  return (
    <div>
      <div className={styles.title}>Update Talent</div>
      <BackButton />
      <form onSubmit={handleSubmit(onSubmitTalentUpdate)}>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Firstname</label>
            <input {...register('firstname')} defaultValue={talentDetails?.firstname} />
            {errors.firstname && <p>{errors.firstname.message}</p>}
          </div>
          <div className="w-50 pl-3">
            <label>Lastname</label>
            <input {...register('lastname')} defaultValue={talentDetails?.lastname} />
            {errors.lastname && <p>{errors.lastname.message}</p>}
          </div>
        </div>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Nickname</label>
            <input {...register('nickname')} defaultValue={talentDetails?.nickname} />
            {errors.nickname && <p>{errors.nickname.message}</p>}
          </div>
          <div className="w-50 pl-3">
            <label>Team Name</label>
            <input {...register('teamName')} defaultValue={talentDetails?.teamName} />
            {errors.teamName && <p>{errors.teamName.message}</p>}
          </div>
        </div>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Game</label>
            <input {...register('game')} defaultValue={talentDetails?.game} />
            {errors.game && <p>{errors.game.message}</p>}
          </div>
          <div className="w-50 pl-3">
            <label>Nationality</label>
            <input {...register('nationality')} defaultValue={talentDetails?.nationality} />
            {errors.nationality && <p>{errors.nationality.message}</p>}
          </div>
        </div>
        <div className="d-flex">
          <div className="w-50 pr-3" />
          <div className="w-50 pl-3" />
        </div>
        <div className="d-flex">
          <div className="w-50 pr-3" />
          <div className="w-50 pl-3" />
        </div>
        <div className="d-flex">
          <div className="w-50 pr-3" />
          <div className="w-50 pl-3" />
        </div>

        <label>Description</label>
        <input {...register('description')} defaultValue={talentDetails?.description} />
        <p>{errors.description?.message}</p>

        <label>Member Since</label>
        <Controller
          control={control}
          name="member_since"
          render={({field}) => (
            <DatePicker
              placeholderText="Select date"
              onChange={(date: any) => field.onChange(date)}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              selected={field.value}
            />
          )}
        />
        <label>Birth Date</label>
        <Controller
          control={control}
          name="birth_date"
          render={({field}) => (
            <DatePicker
              placeholderText="Select date"
              onChange={(date: any) => field.onChange(date)}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              selected={field.value}
            />
          )}
        />
        <br />

        <label>Events List</label>
        {defaultOptions && (
          <Controller
            name="events"
            control={control}
            render={({field}) => <Select
              defaultValue={defaultOptions}
              {...field}
              isMulti
              options={selectOptions}
            />}
          />
        )}

        <label>Pick Photo</label>
        <div className={styles.imagePickerContainer}>
          {talentDetails?.photo
            && <img
            src={talentDetails?.photo}
            alt="TalentPic"
            className={styles.oldImage}
          />
          }
          <ImagePicker
            labelText="Pick a photo"
            fileHandler={onFileChange}
            errorText=""
            previewHeight={200}
            previewWidth={200}
            heightRatio={1}
            widthRatio={1}
          />
        </div>
        <input type="submit" value="Update" disabled={!isDirty}/>
      </form>
    </div>

  );
};

export default TalentUpdate;
