import {useEffect} from 'react';
import {loadAllChats, currentWordCloudDataLoaded, loadActiveChat, startChatListener, stopChatListener} from 'src/features/tools/wordcloud';
import {useNavigate} from 'react-router-dom';
import Icon from 'awesome-react-icons';
import {Button} from 'react-bootstrap';
import styles from './WordCloudList.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

const WordCloudList = () => {
  const dispatch = useAppDispatch();
  const {streamChat, activeStreamChat} = useAppSelector((s) => s.wordcloud);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(loadAllChats());
    dispatch(loadActiveChat());
  }, [dispatch]);

  if (streamChat) {
    for (const chat of streamChat) {
      const date = new Date(chat.date);
      date.toISOString();
    }
  }

  return (
    <div className={styles.pageContainer}>
      <div className="d-flex">
        <Button
          color="success"
          onClick={() => dispatch(startChatListener())}
          disabled={!!activeStreamChat}
        >
          Start Chat Listener
        </Button>
        <Button
          color="danger"
          onClick={() => dispatch(stopChatListener())}
          disabled={!activeStreamChat}
        >
          Stop Chat Listener
        </Button>
      </div>
      {!!activeStreamChat && <div className={styles.tableContainer}>
        <div className={`${styles.title} mb-3`}>Active chat listener</div>
        <table className="table table-dark">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            <tr key={activeStreamChat?._id}>
              <td>{activeStreamChat?._id}</td>
              <td>{`${new Date(activeStreamChat?.date).toLocaleDateString()} ${new Date(activeStreamChat?.date).toLocaleTimeString('fr-FR')}`}</td>
            </tr>
          </tbody>
        </table>

      </div>}

      <div className={`${styles.title} mb-3`}>Stream chat List</div>
      <div className={styles.tableContainer}>

        <table className="table table-dark">
          <thead>
            <tr>
              <th scope="col">ID</th>
              <th scope="col">Date</th>
              <th scope="col">Generate Word Cloud</th>
            </tr>
          </thead>
          <tbody>
            {!!streamChat && streamChat.map((chat) => (
              <tr key={chat?._id}>
                <td>{chat?._id}</td>
                <td>{`${new Date(chat?.date).toLocaleDateString()} ${new Date(chat?.date).toLocaleTimeString('fr-FR')}`}</td>

                <td>
                  <div
                    className={styles.cursorPointer}
                    onClick={() => {
                      dispatch(currentWordCloudDataLoaded(chat.chatData));
                      navigate(`/tools/wordcloud/generate/${chat?._id}`);
                    }}
                  >
                    <Icon name="activity" />
                  </div>
                </td>

              </tr>
            ))}
          </tbody>
        </table>

      </div>
    </div>
  );
};

export default WordCloudList;
