import Icon from 'awesome-react-icons';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {RootState} from 'src/app/rootReducer';
import {Event, loadEventsList} from 'src/features/website/events';
import {loadMembersList, Member} from 'src/features/website/members';
import {loadPartnersList, Partner} from 'src/features/website/partners';
import {loadTalentsList, Talent} from 'src/features/website/talents';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';
import {formDateSec} from 'src/utils/momentDateFormat';
import styles from './DashboardPage.module.css';

type DBElement = Event | Talent | Member | Partner;
const DashboardPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {talentsList} = useAppSelector((s: RootState) => s.talents);
  const {eventsList} = useAppSelector((s: RootState) => s.events);
  const {membersList} = useAppSelector((s: RootState) => s.members);
  const {partnersList} = useAppSelector((s: RootState) => s.partners);
  const [updateList, setUpdateList] = useState<DBElement[]>();

  useEffect(() => {
    dispatch(loadTalentsList());
    dispatch(loadMembersList());
    dispatch(loadEventsList());
    dispatch(loadPartnersList());
  }, [dispatch]);

  useEffect(() => {
    if (talentsList && eventsList && membersList && partnersList) {
      const globalList = [...eventsList, ...talentsList, ...membersList, ...partnersList];
      const globalListUpdatedEvents = globalList.filter((element: DBElement) => element.updatedAt);
      setUpdateList(globalListUpdatedEvents.sort((a, b) => {
        const dateA = new Date(a.updatedAt);
        const dateB = new Date(b.updatedAt);
        if (dateA && dateB) {
          return dateB.getTime() - dateA.getTime();
        }
        return 0;
      }));
    }
  }, [talentsList, eventsList, membersList, partnersList]);

  const displayTitleOrFirstName = (element: DBElement) => {
    if (element.type === 'Member') return `${element.firstname} ${element.lastname}`;
    if (element.type === 'Talent') return `${element.firstname} - ${element.nickname} - ${element.lastname}`;
    return `${element.title}`;
  };

  const redirectToUpdate = (element: DBElement) => {
    // if (element.type === 'Member') dispatch(load)
    if (element.type === 'Event') navigate(`/website/realisations/update/${element.id}`);
    else navigate(`/website/${element.type.toLocaleLowerCase()}s/update/${element.id}`);
  };

  return (
    <div>
      <div className={styles.title}>Database Updated/Created Elements List</div>
      <table className="table table-dark">
        <thead>
          <tr>
            <th scope="col">Title</th>
            <th scope="col">Description</th>
            <th scope="col">Updated At</th>
            <th scope="col">Type</th>
            <th scope="col">Update</th>
          </tr>
        </thead>
        <tbody>
          {updateList && updateList.slice(0, 15).map((element: DBElement) => (
            <tr key={element?.id} >
              <td>{displayTitleOrFirstName(element)}</td>
              <td>{`${element?.description?.substring(0, 50)}...`}</td>
              <td>{formDateSec(element?.updatedAt)}</td>
              <td>{element?.type}</td>
              <td>
                <div
                  style={{cursor: 'pointer'}}
                  onClick={() => redirectToUpdate(element)}
                >
                  <Icon name="activity" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DashboardPage;
