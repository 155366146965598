/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {AlertTypes, createAlert} from '../global/alert';
import {AppThunk} from '../global/helpers';

export interface Product {
  id: string;
  title: string;
  image: string;
  link: string;
  description: string;
  position: number;
}

export interface Cart {
  _id: string;
  products: Product[],
  title: string;
  isActive: boolean;
  currentPosition: number;
}

export interface ShoppingState {
  cartsList: Cart[];
  cartDetails: Cart | null;
}

const initialState: ShoppingState = {
  cartsList: [],
  cartDetails: null,
};

const shopping = createSlice({
  name: 'shopping',
  initialState,
  reducers: {
    cartsLoaded(state, {payload}: PayloadAction<Cart[]>) {
      state.cartsList = payload;
    },
    cartDetailsLoaded(state, {payload}: PayloadAction<Cart>) {
      state.cartDetails = payload;
    },
    cartDetailsUnloaded(state) {
      state.cartDetails = null;
    },
  },
});

export const {cartsLoaded, cartDetailsLoaded, cartDetailsUnloaded} = shopping.actions;

export default shopping.reducer;

const baseApiUrl = process.env.REACT_APP_BASE_URL;

export const loadCartsList = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/shopping/cart/fetch/all`);
    dispatch(cartsLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const loadCart = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/shopping/cart/fetch/${id}`);
    dispatch(cartDetailsLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const insertProduct = (product: any, id: string): AppThunk => async (dispatch) => {
  const config = {headers: {'Content-Type': 'multipart/form-data'}};
  try {
    await axios.post(`${baseApiUrl}/shopping/product/insert/${id}`, product, config);
    dispatch(createAlert('Product successfully created!', AlertTypes.success));
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const createCart = (cart: any): AppThunk => async (dispatch) => {
  try {
    await axios.post(`${baseApiUrl}/shopping/cart/create`, cart);
    dispatch(createAlert('Cart successfully created!', AlertTypes.success));
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const deleteCart = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/shopping/cart/delete/${id}`);
    dispatch(createAlert(response.data.message, AlertTypes.success));
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const loadCartDetails = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/shopping/cart/fetch/${id}`);
    dispatch(cartDetailsLoaded(response.data));
    if (!response) throw new Error();
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const updateProductsOrder = (cartId: string, productId: string, position: any): AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/shopping/cart/position/${cartId}/${productId}`, position);
    dispatch(createAlert(`Current Product: ${response.data.message}`, AlertTypes.success));
  } catch (err) {
    console.log(err);
  }
};

export const startShopping = (cartId: string) : AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/shopping/cart/start-shopping/${cartId}`);
    if (response.data.error) {
      dispatch(createAlert(`Current Product: ${response.data.error}`, AlertTypes.error));
      return;
    }
    dispatch(loadCartDetails(cartId));
    dispatch(createAlert('Shopping starting!', AlertTypes.success));

    if (!response) throw new Error();
  } catch (err) {
    console.log(err);
  }
};

export const stopShopping = (cartId: string) : AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/shopping/cart/stop-shopping/${cartId}`);
    if (response.data.error) {
      dispatch(createAlert(`Current Product: ${response.data.error}`, AlertTypes.error));
      return;
    }
    dispatch(loadCartDetails(cartId));
    dispatch(createAlert('Shopping stopped!', AlertTypes.success));
  } catch (err) {
    console.log(err);
  }
};

export const nextProduct = (cartId: string) : AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/shopping/cart/next-product/${cartId}`);
    if (response.data.error) {
      dispatch(createAlert(`Current Product: ${response.data.error}`, AlertTypes.error));
      return;
    }
    dispatch(loadCartDetails(cartId));
    dispatch(createAlert(`Current Product: ${response.data.message}`, AlertTypes.success));
  } catch (err) {
    console.log(err);
  }
};

export const previousProduct = (cartId: string) : AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/shopping/cart/previous-product/${cartId}`);
    if (response.data.error) {
      dispatch(createAlert(`Current Product: ${response.data.error}`, AlertTypes.error));
      return;
    }
    dispatch(loadCartDetails(cartId));
    dispatch(createAlert(`Current Product: ${response.data.message}`, AlertTypes.success));
  } catch (err) {
    console.log(err);
  }
};
