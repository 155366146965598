import {useContext, useEffect, useState} from 'react';
import MatchContext from '../NationsWarsMatchContext';
import styles from './NationsMatchesCountdownTimer.module.css';

const NationsMatchesCountdownTimer = () => {
  const [countDown, setCountDown] = useState<number>(3);
  const {currentMatch} = useContext(MatchContext);

  const handleTimer = () => {
    if (countDown === 0) return;
    if (currentMatch?.currentStep === 1) {
      setCountDown(countDown - 1);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleTimer();
    }, 2000);

    return () => clearInterval(interval);

  }, [currentMatch, countDown]);

  return (
    <div className={styles.countDownContainer} style={currentMatch?.currentStep === 1 ? {display: 'block'} : {display: 'none'}}>
      {countDown !== 0 && (<div>
        {countDown}
      </div>) }
    </div>
  );
};

export default NationsMatchesCountdownTimer;
