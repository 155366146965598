import {useContext} from 'react';
import MatchContext from '../NationsWarsMatchContext';
import playerSelection from 'src/assets/nationwars/PLAYER_SELECTION.png';
import styles from './NationsMatchesPlayerSelection.module.css';

const NationsMatchesPlayerSelection = () => {
  const {currentMatch} = useContext(MatchContext);
  const remainingTime = currentMatch?.timer?.remainingTime;
  return (
    <div className={styles.componentContainer}>
      <img className={styles.playerSelectionImage} src={playerSelection}/>
      <div className={styles.timerContainer}>
        {remainingTime === 91 ? 90 : remainingTime}
      </div>
    </div>
  );

};

export default NationsMatchesPlayerSelection;
