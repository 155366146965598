import {useContext, useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Timer} from 'src/features/tools/nationwars';
import MatchContext from '../NationsWarsMatchContext';
import styles from './NationsMatchesTimer.module.css';




const NationsMatchesTimer = () => {
  const {nation} = useParams();

  const {currentMatch} = useContext(MatchContext);
  const {currentStep, timer, startingNation} = currentMatch!;
  const [vetoOrPickStep, setVetoOrPickStep] = useState<string>('veto');
  const {remainingTime, nationTurn} = timer!;
  const [timerPosition, setTimerPosition] = useState<number>(0);

  const calculateVetoDisplay = () => {
    if (currentMatch?.currentStep === 0 ) return;
    if (currentMatch?.currentStep === 1 || currentMatch?.currentStep === 2 || currentMatch?.currentStep === 8 || currentMatch?.currentStep === 9) setVetoOrPickStep('veto');

    else setVetoOrPickStep('pick');

  };

  const calculateTimerPosition = () => {
    const nationPositionInArray = currentMatch?.nations.findIndex((n) => n.flag === nation);

    if (nationPositionInArray === 0) setTimerPosition(nation === nationTurn ? 0 : 1);
    if (nationPositionInArray === 1) setTimerPosition(nation === nationTurn ? nationPositionInArray : 0);

  };



  useEffect(() => {
    calculateTimerPosition();
    calculateVetoDisplay();
  }, [remainingTime, nationTurn]);


  return (
    <>
      {(currentStep !== 7 ) && <div>
        <>
          <div className={[styles.vetoPickContainer, styles.leftContainer].join(' ')} style={timerPosition === 1 ? {opacity: 0} : {}} >
            <div className={styles.veto} style={vetoOrPickStep === 'pick' ? {display: 'none'} : {}}/>
            <div className={styles.pick} style={vetoOrPickStep === 'veto' ? {display: 'none'} : {}}/>
          </div>
          <div className={[styles.vetoPickContainer, styles.rightContainer].join(' ')} style={timerPosition === 0 ? {opacity: 0} : {}}>
            <div className={styles.veto} style={vetoOrPickStep === 'pick' ? {display: 'none'} : {}}/>
            <div className={styles.pick} style={vetoOrPickStep === 'veto' ? {display: 'none'} : {}}/>
          </div>
        </>
        {currentMatch && (
          <div className={[styles.timerContainer, timerPosition === 0 ? styles.left : styles.right].join(' ')}>
            {remainingTime === 91 ? 90 : remainingTime}
          </div>
        )}
      </div>}
    </>
  );
};

export default NationsMatchesTimer;
