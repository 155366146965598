import {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {createNewMatch, loadNationsListNoIp} from 'src/features/tools/nationwars';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';
import Select from 'react-select';
import styles from './NationsMatchesCreate.module.css';
import {createOptions} from 'src/utils/selectOptions';

interface IFormInputs {
  nations: { label: string; value: string }[];
}

const NationsMatchesCreate = () => {
  const dispatch = useAppDispatch();
  const {nationsList, matchList} = useAppSelector((s) => s.nations);
  const [selectedOptions, setSelectedOptions] = useState(0);
  const {register, handleSubmit, control, watch, formState: {errors, isValid}} = useForm<IFormInputs>({
    mode: 'onChange',
  });


  useEffect(() => {
    dispatch(loadNationsListNoIp());
  }, []);

  useEffect(() => {
    const subscription = watch((value) => {
      const amountOfPickedCountries = value?.nations?.length;
      if (amountOfPickedCountries) setSelectedOptions(amountOfPickedCountries);
    });
    return () => subscription.unsubscribe();
  }, [watch, selectedOptions]);


  const onSubmitRealisationUpdate = (data: IFormInputs) => {
    const nations = data.nations.map((c) => c.value);
    dispatch(createNewMatch(nations));
  };
  const selectOptionsTalents = createOptions(nationsList);

  return (
    <div className={styles.pageContainer}>
      <form onSubmit={handleSubmit(onSubmitRealisationUpdate)}>
        <div className="w-50 pr-3">
          <label>Countries in Match</label>
            <Controller
              name="nations"
              control={control}
              render={({field}) => <Select
                className={styles.select}
                isOptionDisabled={() => selectedOptions >= 2}
                {...field}
                isMulti
                options={selectOptionsTalents}
              />}
            />
        </div>

        <input type="submit" value="Create"/>
      </form>
    </div>
  );
};

export default NationsMatchesCreate;
