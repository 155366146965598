import {StrictMode} from 'react';
import './index.css';
import {Provider} from 'react-redux';
import store from 'src/app/store';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './app/App';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthContextProvider from './utils/auth-context/AuthContextProvider';
import {createRoot} from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <StrictMode>
    <AuthContextProvider>
      <Provider store={store}>
        <Router>
          <App />
        </Router>
      </Provider>
    </AuthContextProvider>
  </StrictMode>,
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
