import {Navigate, Outlet} from 'react-router-dom';
import {useAppSelector} from 'src/hooks/hooks';



const ProtectedRoute = () => {
  const {isAuthenticated} = useAppSelector((s) => s.authentication);
  if (!isAuthenticated && !window.location.href.includes('render')) {
    return <Navigate to="/" replace />;
  }
  return <Outlet />;
};

export default ProtectedRoute;
