/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {AppThunk} from '../global/helpers';

export interface ChatData {
  count: number;
  value: string;
}

export interface StreamChat {
  chatData: ChatData[];
  _id: string;
  date: Date;
}

export interface WordcloudState {
  streamChat: StreamChat[] | null;
  currentWordCloudData: ChatData[];
  activeStreamChat: StreamChat | null;
}

const initialState: WordcloudState = {
  streamChat: null,
  currentWordCloudData: [],
  activeStreamChat: null,
};

const wordcloud = createSlice({
  name: 'wordcloud',
  initialState,
  reducers: {
    wordListsLoaded(state, {payload}: PayloadAction<any>) {
      state.streamChat = payload;
    },
    currentWordCloudDataLoaded(state, {payload}: PayloadAction<ChatData[]>) {
      state.currentWordCloudData = payload;
    },
    activeWordCloudDataLoaded(state, {payload}: PayloadAction<StreamChat>) {
      state.activeStreamChat = payload;
    },
  },
});

export default wordcloud.reducer;

export const {wordListsLoaded, currentWordCloudDataLoaded, activeWordCloudDataLoaded} = wordcloud.actions;

const baseApiUrl = process.env.REACT_APP_BASE_URL;

export const loadAllChats = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/wordcloud/fetch/all`);
    dispatch(wordListsLoaded(response.data));
    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  }
};

export const fetchChatData = (id : string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/wordcloud/fetch-data/${id}`);
    dispatch(currentWordCloudDataLoaded(response.data));
    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  }
};

export const loadActiveChat = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/wordcloud/fetch-data-recent`);
    dispatch(activeWordCloudDataLoaded(response.data));
    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  }
};

export const startChatListener = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/wordcloud/start-chat-fetcher`);
    dispatch(activeWordCloudDataLoaded(response.data));
    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  }
};

export const stopChatListener = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/wordcloud/stop-chat-fetcher`);
    dispatch(activeWordCloudDataLoaded(response.data));
    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  }
};
