import TalentsList from 'src/components/Website/Talents/TalentsList';
import styles from './TalentsPage.module.css';

const TalentsPage = () => (
    <div className={styles.pageContainer}>
      <TalentsList />
    </div>
);

export default TalentsPage;
