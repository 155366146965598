import {useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {io, Socket} from 'socket.io-client';
import {adminGetDonationData, PostAdminDonationData} from 'src/features/tools/basecamp';
import {useAppDispatch} from 'src/hooks/hooks';
import AdminDonationUpdate from '../AdminDonationUpdate';
import styles from './AdminPage.module.css';

const baseURL = process.env.REACT_APP_BASE_URL2 ?? '';

const AdminPage = () => {
  const dispatch = useAppDispatch();
  const [totalRaised, setTotalRaised] = useState(0);
  const [totalPast10, setTotalPast10] = useState(0);
  const [socket, setSocket] = useState<Socket>();
  const [openUpdateDonation, setOpenUpdateDonation] = useState(false);
  const [openUpdateLight, setOpenUpdateLight] = useState(false);
  const [donationsData, setDonationsData] = useState<PostAdminDonationData>();

  useEffect(() => {
    const a = adminGetDonationData();
    console.log(a);
  }, []);
  useEffect(() => {
    setSocket(io(`${baseURL}/basecamp-donation`, {query: {channel: 'admin'}}));
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('refresh', (data:any) => {
        setTotalRaised(data);
        // setDonations(data)
      });
      socket.on('refresh-hypebar', (data:any) => {
        setTotalPast10(data);
        // setDonations(data)
      });
      socket.on('admin-data', (data:any) => {
        setDonationsData(data);
        console.log(data);
      });
    }
    return () => {
      if (socket?.connected) socket.disconnect();
    };
  }, [socket]);

  const renderUpdateModal = () => (
    <Modal show={openUpdateDonation} onHide={() => setOpenUpdateDonation(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Update Player</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {donationsData && <AdminDonationUpdate donationsData={donationsData}/>}
      </Modal.Body>
    </Modal>
  );

  return (
    <div className={styles.pageContainer}>
      {renderUpdateModal()}
      <div className={styles.title}>DONATIONS</div>
      <div className="d-flex justify-content-around">
        <div className="w-25">
          <label>Amount Raised</label>
          <input type="number" value={donationsData?.amountRaised}/>
        </div>
        <div className="w-25">
          <label>Starting Amount</label>
          <input type="number" value={donationsData?.startingAmount}/>
        </div>
        <div className="w-25">
          <Button onClick={() => setOpenUpdateDonation(true)}>Update Donations Data</Button>
        </div>
      </div>
      {renderUpdateModal()}
      <div className={styles.title}>LIGHTS</div>
      <div className="d-flex justify-content-around">
        <div className="w-25">
          <label>Amount Raised</label>
          <input type="number" value={donationsData?.amountRaised}/>
        </div>
        <div className="w-25">
          <label>Starting Amount</label>
          <input type="number" value={donationsData?.startingAmount}/>
        </div>
        <div className="w-25">
          <Button onClick={() => setOpenUpdateDonation(true)}>Update Donations Data</Button>
        </div>
      </div>
    </div>
  );
};

export default AdminPage;
