import {User} from 'src/features/global/authentication';

declare const localStorage: any;

const deleteUser = () => {
  localStorage.removeItem('user');
};

const setUser = (user: User) => {
  localStorage.setItem('user', JSON.stringify(user));
};

const getUser = () => JSON.parse(localStorage.getItem('user')) as User;

export {getUser, setUser, deleteUser};
