/* eslint-disable no-underscore-dangle */
import Icon from 'awesome-react-icons';
import {useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {deletePartner, loadPartnersList, Partner, partnerDetailsUnloaded} from 'src/features/website/partners';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';
import {formDateDay} from 'src/utils/momentDateFormat';
import styles from './PartnersList.module.css';

const PartnersList = () => {
  const dispatch = useAppDispatch();
  const {partnersList} = useAppSelector((s) => s.partners);
  const navigate = useNavigate();
  const [openDeleteModal, setDeleteOpenModal] = useState(false);
  const [currentPartner, setCurrentPartner] = useState<Partner>();

  useEffect(() => {
    dispatch(loadPartnersList());
    dispatch(partnerDetailsUnloaded(null));
  }, [dispatch]);

  const handleDeleteClick = () => {
    if (currentPartner?.id) {
      dispatch(deletePartner(currentPartner?.id));
      setDeleteOpenModal(false);
    }
  };

  return (
    <div className={styles.membersListContainer}>
      <Modal show={openDeleteModal} onHide={() => setDeleteOpenModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Partner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {`${currentPartner?.title}`} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteOpenModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleDeleteClick()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <div className={styles.title}>Partners List</div>
      <div className={styles.tableContainer}>
        <table className="table table-dark">
          <thead>
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Description</th>
              <th scope="col">Client Type</th>
              <th scope="col">Logo</th>
              <th scope="col">Date</th>
              <th scope="col">Update</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {partnersList && partnersList.map((partner: Partner) => (
              <tr key={partner.id}>
                <td>{partner?.title}</td>
                <td>{`${partner?.description.substring(0, 50)}...`}</td>
                <td>{partner?.clientType}</td>

                <td>
                  <img
                    src={partner?.imageUrl}
                    alt={`${partner?.title} profile`}
                    className={styles.photo}
                  />
                </td>
                <td>{formDateDay(partner.date)}</td>
                <td>
                  <div
                    className={styles.cursorPointer}
                    onClick={() => {
                      navigate(`/website/partners/update/${partner.id}`);
                    }}
                  >
                    <Icon name="activity" />
                  </div>
                </td>
                <td>
                  <button
                    className="deleteButton"
                    onClick={() => {
                      setDeleteOpenModal(true);
                      setCurrentPartner(partner);
                    }}
                  >
                    <Icon name="trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PartnersList;
