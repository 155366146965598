/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {getUser} from 'src/utils/localUser';
import {AlertTypes, createAlert} from '../global/alert';
import {Event} from './events';
import {AppThunk} from '../global/helpers';

export interface Talent {
  type: 'Talent';
  id: string;
  title: string;
  description: string;
  firstname: string;
  lastname: string;
  nickname: string;
  photo: string;
  nationality: string;
  game: string;
  teamName: string;
  birth_date: Date;
  member_since: Date;
  events: Event[];
  updatedAt: Date;
  displayRank: number;

}

interface TalentState {
  talentsList: Talent[];
  talentDetails: Talent | null;
}

const initialState: TalentState = {
  talentsList: [],
  talentDetails: null,

};

const talents = createSlice({
  name: 'talents',
  initialState,
  reducers: {
    talentsLoaded(state, {payload}: PayloadAction<Talent[]>) {
      state.talentsList = payload;
    },
    talentDetailsLoaded(state, {payload}: PayloadAction<Talent>) {
      state.talentDetails = payload;
    },
    talentDetailsUnloaded(state, {payload}: PayloadAction<null>) {
      state.talentDetails = payload;
    },
  },
});

export const {talentsLoaded, talentDetailsLoaded, talentDetailsUnloaded} = talents.actions;
export default talents.reducer;

const baseApiUrl = process.env.REACT_APP_BASE_URL;
let token: string | undefined;
if (getUser()) {
  token = getUser().token;
}

export const loadTalentsList = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/talents/fetch/all`, {headers: {Authorization: `Bearer ${token}`}});
    dispatch(talentsLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const loadTalent = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/talents/fetch/${id}`, {headers: {Authorization: `Bearer ${token}`}});
    dispatch(talentDetailsLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const createTalent = (talent: any, setCreateTalent: any, history?: any): AppThunk => async (dispatch) => {
  const config = {headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.post(`${baseApiUrl}/talents/create`, talent, config);
    dispatch(createAlert('Talent successfully created!', AlertTypes.success));
    if (history) setTimeout(() => history(-1), 1500);
    if (setCreateTalent) setCreateTalent(false);
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const updateTalent = (id: string, talent: any, history: any): AppThunk => async (
  dispatch,
) => {
  const config = {headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.patch(`${baseApiUrl}/talents/update/${id}`, talent, config);
    dispatch(createAlert('Talent successfully updated!', AlertTypes.success));
    setTimeout(() => history(-1), 1500);
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const updateTalentRank = (id: string, talent: any): AppThunk => async (dispatch) => {
  const config = {headers: {Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.patch(`${baseApiUrl}/talents/update/rank/${id}`, talent, config);
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const deleteTalent = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.delete(`${baseApiUrl}/talents/delete/${id}`, {headers: {Authorization: `Bearer ${token}`}});
    dispatch(createAlert('Talent successfully deleted!', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};
