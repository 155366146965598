import React, {createContext} from 'react';
import {NationMatch, SelectedMap, StarcraftMaps} from 'src/features/tools/nationwars';
import {VETOSTEPS} from 'src/utils/nationwarsHelpers';

interface NationWarsMatchContext {
  currentMatch: NationMatch | null;
  nationFlags: string[];
  selectedMap: SelectedMap | undefined;
  setSelectedMap: (data: SelectedMap | undefined) => void;
  setCurrentMatch: (match: NationMatch) => void;
  VS1Maps: StarcraftMaps[];
  setVS1Maps: (maps: StarcraftMaps[]) => void;
  VS2Maps: StarcraftMaps[];
  setVS2Maps: (maps: StarcraftMaps[]) => void;
  handleMapSelection: (map: StarcraftMaps, solo: boolean) => void;
  hoveredPlayer: number | null;
  setHoveredPlayer: (player: number | null) => void;
  handlePlayerSelection: (player1: string, player2: string, nation: number) => void;
}

const matchContextObject = {
  currentMatch: null,
  nationFlags: [],
  selectedMap: undefined,
  setSelectedMap: () => {},
  setCurrentMatch: () => {},
  VS1Maps: [],
  setVS1Maps: () => {},
  VS2Maps: [],
  setVS2Maps: () => {},
  handleMapSelection: () => {},
  handleMapDisplay: () => {},
  hoveredPlayer: null,
  setHoveredPlayer: () => {},
  handlePlayerSelection: () => {},
};

const MatchContext = createContext<NationWarsMatchContext>({
  ...matchContextObject,
});

export default MatchContext;
