/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {AlertTypes, createAlert} from '../global/alert';
import {AppThunk} from '../global/helpers';

export interface Credentials {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  app_id: string;
  scope: string;
  token_type: string;
}
interface PayerInfo {
  email: string;
  first_name: string;
  last_name: string;
  payer_id: string;
  shipping_address: ShippingAddress;
  country_code: string,
}
interface ShippingAddress {
  recipient_name: string;
  line1: string;
  city: string,
  state: string,
  postal_code: string,
  country_code: string,
}

export interface Donation {
  _id: string;
  total: string;
  currency: string
  payer_info: PayerInfo;
  streamer: string;
  displayed: boolean;
}
export interface DonationState {
  donationsList: Donation[];
  credentials: Credentials | null;
}

const initialState: DonationState = {
  donationsList: [],
  credentials: null,
};

const donations = createSlice({
  name: 'votes',
  initialState,
  reducers: {
    donationsListLoaded(state, {payload}: PayloadAction<Donation[]>) {
      state.donationsList = payload;
    },
    credentialsLoaded(state, {payload}: PayloadAction<Credentials>) {
      state.credentials = payload;
    },
    removeDonation(state, {payload}: PayloadAction<string>) {
      const index = state.donationsList.findIndex((donation) => donation._id === payload);
      state.donationsList.splice(index, 1);
    },
  },
});

export default donations.reducer;

export const {donationsListLoaded, credentialsLoaded, removeDonation} = donations.actions;

const baseApiUrl = process.env.REACT_APP_BASE_URL;

// export const generateAccessToken = (): AppThunk => async (dispatch) => {
//   try {
//     const response = await axios.post(`${baseApiUrl}/donations/generateToken`)
//     console.log(response.data)
//   } catch (err) {
//     console.log(err);
//   }
// }

// export const generateWebhook = (): AppThunk => async (dispatch) => {
//   try {
//     const response = await axios.post(`${baseApiUrl}/donations/create_paypal_webhook`);
//   } catch (err) {
//     console.log(err)
//   }
// }

export const fetchDonationsList = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/donations/fetch/all`);
    dispatch(donationsListLoaded(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const executePayment = (): AppThunk => async () => {
  try {
    await axios.post(`${baseApiUrl}/donations/execute_payment`);
  } catch (err) {
    console.log(err);
  }
};

export const removeDonationFromList = (id: string): AppThunk => async (dispatch) => {
  try {
    await axios.patch(`${baseApiUrl}/donations/update/${id}`);
    dispatch(removeDonation(id));
  } catch (err) {
    console.log(err);
  }
};

export const sendMerchPayment = (data: any): AppThunk => async (dispatch) => {
  try {
    await axios.post(`${baseApiUrl}/donations/amount/final_merch`, data);
    dispatch(createAlert('Merch payment successfully sent!', AlertTypes.success));
  } catch (err) {
    console.log(err);
  }
};
