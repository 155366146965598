import styles from './LightEffectsDashboard.module.css';

const LightEffectsDashboard = () => {
  const a = 0;

  return (
    <div className={styles.pageContainer}>
      <div className={styles.title}>
        Light Effects Dashboard
      </div>
    </div>
  );
};

export default LightEffectsDashboard;
