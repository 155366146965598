import {combineReducers} from 'redux';
import alert from 'src/features/global/alert';
import events from 'src/features/website/events';
import partners from 'src/features/website/partners';
import talents from 'src/features/website/talents';
import members from 'src/features/website/members';
import authentication from 'src/features/global/authentication';
import votes from 'src/features/tools/votes';
import donations from 'src/features/tools/donations';
import shopping from 'src/features/tools/shopping';
import articles from 'src/features/website/articles';
import nations from 'src/features/tools/nationwars';
import wordcloud from 'src/features/tools/wordcloud';
import basecamp from 'src/features/tools/basecamp';

const rootReducer = combineReducers({
  alert,
  events,
  partners,
  talents,
  members,
  authentication,
  votes,
  donations,
  shopping,
  articles,
  nations,
  wordcloud,
  basecamp,

});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
