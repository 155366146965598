export const raceSelectOptions = [
  {
    value: 'z',
    label: 'Zerg',
  },
  {
    value: 't',
    label: 'Terran',
  },
  {
    value: 'p',
    label: 'Protoss',
  },
  {
    value: 'r',
    label: 'Random',
  },
];

export interface StarcraftMaps {
  name: string;
  mapUrl: string;
}

export const soloMaps: StarcraftMaps[] = [
  {
    name: '2000_Atmospheres',
    mapUrl: '/assets/nationwarriors/maps/2000_Atmospheres.jpg',
  },
  {
    name: 'Berlingrad',
    mapUrl: '/assets/nationwarriors/maps/Berlingrad.jpg',
  },
  {
    name: 'Blackburn',
    mapUrl: '/assets/nationwarriors/maps/Blackburn.jpg',
  },
  {
    name: 'Curious_Minds',
    mapUrl: '/assets/nationwarriors/maps/Curious_Minds.jpg',
  },
  {
    name: 'Glittering_Ashes',
    mapUrl: '/assets/nationwarriors/maps/Glittering_Ashes.jpg',
  },
  {
    name: 'Hardwire',
    mapUrl: '/assets/nationwarriors/maps/Hardwire.jpg',
  },
  {
    name: 'Pride_Of_Altaris',
    mapUrl: '/assets/nationwarriors/maps/Pride_Of_Altaris.jpg',
  },

];

export const duoMaps: StarcraftMaps[] = [
  {
    name: 'Emerald_City',
    mapUrl: '/assets/nationwarriors/maps/Emerald_City.jpg',
  },
  {
    name: 'Heavy_Artillery_LE',
    mapUrl: '/assets/nationwarriors/maps/Heavy_Artillery_LE.jpg',
  },
  {
    name: 'Nightscape_LE',
    mapUrl: '/assets/nationwarriors/maps/Nightscape_LE.jpg',
  },
  {
    name: 'Reclamation_LE',
    mapUrl: '/assets/nationwarriors/maps/Reclamation_LE.jpg',
  },
  {
    name: 'Rhoskallian_LE',
    mapUrl: '/assets/nationwarriors/maps/Rhoskallian_LE.jpg',
  },

];

export enum VETOSTEPS {
  NOT_STARTED = 0,
  VETO1 = 1,
  VETO2 = 2,
  PICK1 = 3,
  PICK2 = 4,
  PICK3 = 5,
  PICK4 = 6,
}
