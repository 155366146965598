/* eslint-disable no-alert */
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {ChatData, fetchChatData} from 'src/features/tools/wordcloud';
import {TagCloud} from 'react-tagcloud';
import styles from './WordCloudGenerate.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

const WordCloudGenerate = () => {
  const {idparam} = useParams();
  const dispatch = useAppDispatch();
  const {currentWordCloudData} = useAppSelector((s) => s.wordcloud);
  const [filteredWordCloud, setFilteredCloud] = useState<ChatData[]>([]);

  useEffect(() => {
    dispatch(fetchChatData(idparam!));
  }, [dispatch, idparam]);

  useEffect(() => {
  //   if (currentWordCloudData) {
  //   const set = new Set();
  //   for (const word of currentWordCloudData) {
  //     set.add(typeof word.count)
  //   }
  // }
    if (currentWordCloudData.length > 2) {
      const filteredWords = currentWordCloudData.slice().sort((a, b) => b.count - a.count).slice(0, 150);
      setFilteredCloud(filteredWords);
    }
  }, [currentWordCloudData]);

  // Order currentWordCloudData by count
  // const orderedWordCloud = currentWordCloudData.sort((a, b) => b.count - a.count)

  // const customRenderer = (tag : any) => (
  //     <span key={tag.value} className={styles.tagSpan}>
  //       {tag.value}
  //     </span>
  //   )

  const options = {
    luminosity: 'light',
    hue: 'blue',
  };
  return (
    <div className={styles.pageContainer}>
     <div className={styles.pageContainer} id="canvas">
        {/* <ReactWordcloud words={currentWordCloudData} size={[400, 600]} options={{ fontSizes: [16, 30] }}/> */}
        {filteredWordCloud.length > 0 && <TagCloud
          minSize={15}
          maxSize={35}
          tags={filteredWordCloud}
          colorOptions={options}
          className={styles.tagCloud}
          // renderer={customRenderer}
          onClick={(tag: any) => alert(`'${tag.value}': ${tag.count} occurences`)}
        /> }
     </div>
    </div>
  );
};

export default WordCloudGenerate;
