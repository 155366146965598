import {Route, Routes} from 'react-router-dom';
import ArticlesPage from 'src/pages/WebsiteSection/ArticlesPage';
import MembersPage from 'src/pages/WebsiteSection/MembersPage';
import PartnersPage from 'src/pages/WebsiteSection/PartnersPage';
import TalentsPage from 'src/pages/WebsiteSection/TalentsPage';
import RealisationsPage from 'src/pages/WebsiteSection/RealisationsPage';
import DashboardPage from 'src/pages/WebsiteSection/DashboardPage';
import MemberUpdate from 'src/components/Website/Members/MemberUpdate';
import MemberCreate from 'src/components/Website/Members/MemberCreate';
import PartnerUpdate from 'src/components/Website/Partners/PartnerUpdate';
import PartnerCreate from 'src/components/Website/Partners/PartnerCreate';
import PartnerOrder from 'src/components/Website/Partners/PartnerOrder';
import RealisationUpdate from 'src/components/Website/Realisations/RealisationUpdate';
import RealisationCreate from 'src/components/Website/Realisations/RealisationCreate';
import RealisationOrder from 'src/components/Website/Realisations/RealisationOrder';
import TalentUpdate from 'src/components/Website/Talents/TalentUpdate';
import TalentCreate from 'src/components/Website/Talents/TalentCreate';
import TalentOrder from 'src/components/Website/Talents/TalentOrder';
import RessourceCreate from 'src/components/Website/Ressources/RessourceCreate';
import RessourceUpdate from 'src/components/Website/Ressources/RessourceUpdate';
import RessourcesPage from 'src/pages/WebsiteSection/RessourcesPage';
import ArticleUpdate from 'src/components/Website/Articles/ArticleUpdate';
import ArticleCreate from 'src/components/Website/Articles/ArticleCreate';
import ArticleOrder from 'src/components/Website/Articles/ArticleOrder';
import NavbarSiteWeb from 'src/components/Navbars/NavbarAdmin';
import styles from './WebSiteSection.module.css';

const WebSiteSection = () => (
    <div className={styles.websiteSection}>
      <NavbarSiteWeb />

      <Routes>
        {/* User session active => redirect to dashboard
            Else redirect to google login
        */}

        {/* <Route element={<ProtectedRoute />}> */}
          <Route path="/dashboard" element={<DashboardPage/>} />
          <Route path="/ressources/list" element={<RessourcesPage/>} />
          <Route path="/ressources/update/:id" element={<RessourceUpdate/>} />
          <Route path="/ressources/create" element={<RessourceCreate />} />

          <Route path="/members/list" element={<MembersPage />} />
          <Route path="/members/update/:id" element={<MemberUpdate />} />
          <Route path="/members/create" element={<MemberCreate />} />

          <Route path="/partners/list" element={<PartnersPage />} />
          <Route path="/partners/update/:id" element={<PartnerUpdate />} />
          <Route path="/partners/create" element={<PartnerCreate />} />
          <Route path="/partners/order" element={<PartnerOrder />} />

          <Route path="/realisations/list" element={<RealisationsPage />} />
          <Route path="/realisations/update/:id" element={<RealisationUpdate />} />
          <Route path="/realisations/create" element={<RealisationCreate />} />
          <Route path="/realisations/order" element={<RealisationOrder />} />

          <Route path="/talents/list" element={<TalentsPage />} />
          <Route path="/talents/update/:id" element={<TalentUpdate />} />
          <Route path="/talents/create" element={<TalentCreate />} />
          <Route path="/talents/order" element={<TalentOrder />} />

          <Route path="/articles/list" element={<ArticlesPage />} />
          <Route path="/articles/update/:id" element={<ArticleUpdate />} />
          <Route path="/articles/create" element={<ArticleCreate />} />
          <Route path="/articles/order" element={<ArticleOrder />} />
        {/* </Route> */}



      </Routes>
    </div>
);

export default WebSiteSection;
