import {Fragment, useState} from 'react';
import {Navigation} from 'react-minimal-side-navigation';
import Icon from 'awesome-react-icons';
import {useNavigate, useLocation} from 'react-router-dom';
import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';
import styles from './Navbar.module.css';

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const navbarItems = [
    {
      title: 'Home',
      itemId: '/home',
      elemBefore: () => <Icon name="cloud-snow" />,
    },
    {
      title: 'Backseat',
      itemId: '/tools/votes/QCM',
      elemBefore: () => <Icon name="star" />,
      subNav: [
        {
          title: 'QCM',
          itemId: '/tools/votes/QCM',
        },
        {
          title: 'Wordcloud',
          itemId: '/tools/wordcloud',
        },
      ],
    },
    {
      title: 'Donations',
      itemId: '/tools/donations/donnetempo',
      elemBefore: () => <Icon name="cloud-lightning" />,
      subNav: [
        {
          title: 'Donne Tempo',
          itemId: '/tools/donations/donnetempo',
        },
      ],
    },
    {
      title: 'Shopping',
      itemId: '/tools/shopping/cart/list',
      elemBefore: () => <Icon name="briefcase" />,
      subNav: [
        {
          title: 'Carts List',
          itemId: '/tools/shopping/cart/list',
        },
        {
          title: 'Cart Create',
          itemId: '/tools/shopping/cart/create',
        },
      ],
    },
    {
      title: 'NationWars',
      itemId: '/tools/nationwars/nations/list',
      elemBefore: () => <Icon name="eye" />,
      subNav: [
        {
          title: 'Nations List',
          itemId: '/tools/nationwars/nations/list',
        },
        {
          title: 'Nations Create',
          itemId: '/tools/nationwars/nations/create',
        },
      ],
    },
    {
      title: 'NationWars Matches',
      itemId: '/tools/nationwars/matches/list',
      elemBefore: () => <Icon name="check-square" />,
      subNav: [
        {
          title: 'Create Match',
          itemId: '/tools/nationwars/matches/create',
        },
      ],
    },
    {
      title: 'Twitch Analytics',
      itemId: '/tools/twitch-analytics',
      elemBefore: () => <Icon name="activity" />,
      subNav: [
        {
          title: 'Dashboard',
          itemId: '/tools/analytics',
        },
        {
          title: 'Channels List',
          itemId: '/tools/analytics/channels/list',
        },
      ],
    },
    {
      title: 'Basecamp LFI',
      itemId: '/tools/basecamp/list',
      elemBefore: () => <Icon name="bell" />,
      subNav: [
        {
          title: 'Streams List',
          itemId: '/tools/basecamp/list',
        },
        {
          title: 'Stream Create',
          itemId: '/tools/basecamp/create',
        },
        {
          title: 'Admin',
          itemId: '/tools/basecamp/admin',
        },
        // {
        //   title: 'Overlay',
        //   itemId: '/tools/analytics/channels/list',
        // }
      ],
    },
    {
      title: 'Light Effects',
      itemId: '/tools/lighteffects/dashboard',
      elemBefore: () => <Icon name="bell" />,
      subNav: [
        {
          title: 'Streams List',
          itemId: '/tools/lighteffects/dashboard',
        },
        // {
        //   title: 'Overlay',
        //   itemId: '/tools/analytics/channels/list',
        // }
      ],
    },

  ];

  return (
    <div className={styles.navbarContainer}>
      <Fragment>
        <div
          onClick={() => setIsSidebarOpen(false)}
          className={`fixed inset-0 z-20 block transition-opacity bg-black opacity-50 lg:hidden ${
            isSidebarOpen ? 'block' : 'hidden'
          }`}
        />

        {/* Sidebar */}
        <div
          className={`fixed inset-y-0 left-0 z-30 w-64 overflow-y-auto transition duration-300 ease-out transform translate-x-0
          bg-white border-r-2 lg:translate-x-0 lg:static lg:inset-0 ${
            isSidebarOpen
              ? 'ease-out translate-x-0'
              : 'ease-in -translate-x-full'
          }`}
        >
          <Navigation
            activeItemId={location.pathname}
            onSelect={({itemId}) => {
              navigate(itemId);
            }}
            items={navbarItems}
          />
        </div>
      </Fragment>
    </div>
  );
};

export default Navbar;
