import styles from './NWMap.module.css';
import arrow from 'src/assets/nationwars/SVG/CHECK.png';
import cross from 'src/assets/nationwars/SVG/CROSS_RED.png';
import {matchDetailsLoaded, NationMatch} from 'src/features/tools/nationwars';
import {useContext, useEffect, useState} from 'react';
import {useAppSelector} from 'src/hooks/hooks';
import {useParams} from 'react-router-dom';
import {VETOSTEPS} from 'src/utils/nationwarsHelpers';
import MatchContext from '../NationsWarsMatchContext';
interface NWMapProps {
  name: string;
  mapUrl: string;
}

const NWMap = ({mapUrl, name}: NWMapProps) => {
  const {nation} = useParams();
  const {currentMatch} = useContext(MatchContext);
  const {timer} = currentMatch!;
  const {nationTurn} = timer!;
  const [displayCross, setDisplayCross] = useState(false);
  const [displayArrow, setDisplayArrow] = useState(false);

  const checkIfMapIsVetoed = () => {
    for (const map of currentMatch!.maps) {
      if (map.map.name === name && map.veto === true) return true;
    }
    return false;
  };
  const checkIfMapIsSelected = () => {
    for (const map of currentMatch!.maps) {
      if (map.map.name === name && map.veto === false) return true;
    }
    return false;
  };

  // function to find the index of the map that is not in the current match.maps
  const findMapIndex = () => {
    for (let i = 0; i < currentMatch!.maps.length; i++) {
      if (currentMatch!.maps[i].map.name === name) return i;
    }
    return -1;
  };


  const checkIsLastMap = () => {
    const lastMapIndex = findMapIndex();
    if (lastMapIndex === -1 && currentMatch?.currentStep === 12) return true;
    else return false;
  };

  useEffect(() => {
    const isMapVetoed = checkIfMapIsVetoed();
    const isMapPicked = checkIfMapIsSelected();
    const isLastUnpickedMap = checkIsLastMap();
    setDisplayCross(isLastUnpickedMap || isMapVetoed);
    setDisplayArrow(isMapPicked);
  }, [currentMatch]);


  return (
    <div className={[styles.mapContainer, nationTurn === nation ? styles.pointer : styles.notAllowed].join(' ')}>
      {<img src={cross} alt="pick" className={styles.cross} style={ displayCross ? {display:'block'} : {display: 'none'}} />}
      {<img src={arrow} alt="pick" className={styles.cross} style={ displayArrow ? {display:'block'} : {display: 'none'}} />}
      {/* {displayArrowCondition && <img src={arrow} alt="pick" className={styles.arrow} />} */}
      <img src={mapUrl} alt={name} className={styles.image}/>
    </div>
  );
};

export default NWMap;
