/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/jsx-props-no-spreading */
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {adminSetDonationData, PostAdminDonationData} from 'src/features/tools/basecamp';
import {useAppDispatch} from 'src/hooks/hooks';
import * as yup from 'yup';
import styles from './AdminDonationUpdate.module.css';

interface IFormInputs {
  amountRaised: number;
  startingAmount: number
}

const SignupSchema = yup.object().shape({
  amountRaised: yup.number().min(2, 'startingAmount must be at least 1'),
  startingAmount: yup.number().min(1, 'startingAmount must be at least 1'),

});

interface AdminDonationUpdateProps {
  donationsData: PostAdminDonationData;
}
const AdminDonationUpdate = ({donationsData} : AdminDonationUpdateProps) => {
  const dispatch = useAppDispatch();
  const {register, handleSubmit, formState: {errors, isValid}} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });

  const onSubmitPlayerUpdate = async (data: IFormInputs) => {
    const updatedDonationData :PostAdminDonationData = {
      amountRaised: data.amountRaised,
      startingAmount: data.startingAmount,
    };
    dispatch(adminSetDonationData(updatedDonationData));
  };

  return (
    <div>
      <div className={styles.title}>Update Donation Data</div>
      <form onSubmit={handleSubmit(onSubmitPlayerUpdate)}>
        <div className="w-100 pr-3">
          <label>Amount Raised</label>
          <input {...register('amountRaised')} defaultValue={donationsData.amountRaised}/>
          {errors.amountRaised && <p>{errors.amountRaised.message}</p>}

        </div>
        <div>
          <label>Starting Amount</label>
          <input
            {...register('startingAmount')}
            defaultValue={donationsData.startingAmount}
          />
          {errors.startingAmount && <p>{errors.startingAmount.message}</p>}
        </div>
        <input type="submit" value="Update" disabled={!isValid} />
      </form>
    </div>
  );
};

export default AdminDonationUpdate;
