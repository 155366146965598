import {Button} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import styles from './Homepage.module.css';

const HomePage = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.pageContainer}>
      <div className={`${styles.buttonContainer} d-flex`}>
        <Button onClick={() => navigate('/website/dashboard')} color="primary" size="lg">
          Site ATP
        </Button>
        <Button onClick={() => navigate('/tools/votes')} color="success" size="lg">
          Outils
        </Button>
        <Button onClick={() => navigate('/admin')} color="danger" size="lg">
          Admin
        </Button>
      </div>
    </div>
  );
};

export default HomePage;
