/* eslint-disable no-underscore-dangle */
export const createOptions = (array: any[]) => {
  const options: { value: string; label: string; }[] = [];
  if (array !== [] || array !== [null]) {
    array.forEach((e) => {
      if (e === null) return;
      const option = {
        value: e.id || e._id,
        label: e.title || e.description,
      };
      options.push(option);
    });
  }
  return options;
};

export const createOptionsFlags = (array: any[]) => {
  const options: { value: string; label: string; }[] = [];
  if (array !== [] || array !== [null]) {
    array.forEach((e) => {
      if (e === null) return;
      const option = {
        value: e.flag,
        label: e.title,
      };
      options.push(option);
    });
  }
  return options;
};


/* eslint-disable no-underscore-dangle */
export const createTagsOptions = (array: any[]) => {
  const options: { value: string; label: string; }[] = [];
  if (array !== []) {
    array.forEach((e: string) => {
      if (e === null) return;
      const option = {
        value: e,
        label: e[0].toUpperCase() + e.slice(1),
      };
      options.push(option);
    });
  }
  return options;
};

export const tagsOptions = [
  {value: 'Agent de Talent', label: 'Agent de Talent'},
  {value: 'Partenariats et Sponsoring', label: 'Partenariats et Sponsoring'},
  {value: 'Expert en communauté', label: 'Expert en communauté'},
  {value: 'Evenementiel', label: 'Evenementiel'},
  {value: 'Audiovisuel ', label: 'Audiovisuel'},
  {value: "Conception d'Histoires Originales", label: "Conception d'Histoires Originales"},
];
