import PartnersList from 'src/components/Website/Partners/PartnersList';
import styles from './PartnersPage.module.css';

const PartnersPage = () => (
    <div className={styles.pageContainer}>
      <PartnersList />
    </div>
);

export default PartnersPage;
