import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import BackButton from 'src/components/Global/BackButton';
import {loadNation, loadNationsListNoIp, updateNation} from 'src/features/tools/nationwars';
import {Button, Modal} from 'react-bootstrap';
import styles from './NationUpdate.module.css';
import PlayersList from '../../Players/PlayersList';
import AddPlayer from '../../Players/AddPlayer';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';
interface IFormInputs {
  title: string;
  flag: string;
}

const SignupSchema = yup.object().shape({
  title: yup.string().min(2, 'Title must be at least 2 characters'),
  flag: yup.string().min(2, 'Flag must be at least 2 characters'),
});

const NationUpdate = () => {
  const {id} = useParams();
  const {nationDetails} = useAppSelector((s) => s.nations);
  const [createPlayer, setCreatePlayer] = useState(false);
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const {register, handleSubmit, setValue, formState: {errors, isDirty, isValid}} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });

  // Route not allowed for non editor users

  const onSubmitNationUpdate = async (data: IFormInputs) => {
    const updatedNation = {...nationDetails};
    if (data.title) updatedNation.title = data.title;
    if (data.flag) updatedNation.flag = data.flag;

    dispatch(updateNation(id!, data, history));
    dispatch(loadNationsListNoIp());
  };

  useEffect(() => {
    dispatch(loadNation(id!));
  }, [dispatch, id]);

  useEffect(() => {
    if (nationDetails) {
      setValue('flag', nationDetails.flag, {shouldValidate: true});
      setValue('title', nationDetails.title, {shouldValidate: true});
    }
  }, [nationDetails, setValue]);

  return (
    <div className={styles.pageContainer}>
      <Modal
        show={createPlayer}
        onHide={() => {
          setCreatePlayer(false);
        }}>
        <Modal.Body>
          {nationDetails && <AddPlayer nationId={nationDetails?._id} setOpenModal={setCreatePlayer} />}
        </Modal.Body>
      </Modal>
      <div className={styles.title}>Update Nation</div>
      <BackButton />
      <form onSubmit={handleSubmit(onSubmitNationUpdate)}>
        <div>
          <label>Country Name</label>
          <input
            {...register('title')}
            defaultValue={nationDetails?.title}
          />
          {errors.title && <p>{errors.title.message}</p>}

        </div>
        <div >
          <div>
            <a href="https://www.iban.com/country-codes" target="_blank" rel="noreferrer" >Country Code List</a>
          </div>
          <label />
          <label>Country Code</label>
          <input {...register('flag')} defaultValue={nationDetails?.flag} />
          <p>{errors.flag?.message}</p>
        </div>
        <input type="submit" value="Update" disabled={!isDirty}/>
      </form>
      <label>Add New Player</label>
      <Button onClick={() => setCreatePlayer(true)}>Create</Button>

      {nationDetails?.players && (
        <PlayersList nation={nationDetails}/>
      )}
    </div>
  );
};

export default NationUpdate;
