import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Player} from 'src/features/tools/nationwars';
import MatchContext from '../NationsWarsMatchContext';
import styles from './NationsMatchesPlayers.module.css';



const NationsMatchesPlayers = () => {
  const {nation} = useParams();
  const {nationFlags, currentMatch, setHoveredPlayer, hoveredPlayer, handlePlayerSelection} = useContext(MatchContext);
  const [teamACaptain, setTeamACaptain] = useState<string>();
  const [teamAPickedPlayer, setTeamAPickedPlayer] = useState<string>();
  const [teamBCaptain, setTeamBCaptain] = useState<string>();
  const [teamBPickedPlayer, setTeamBPickedPlayer] = useState<string>();

  const orderPlayersByVotes = (players: Player[]) => {
    if (!players) return;
    const arrayToSort = [...players];
    const sortedPlayers = arrayToSort.sort((a, b) => b.votes - a.votes);
    return sortedPlayers;
  };


  useEffect(() => {
    if (currentMatch) {
      const sortedPlayersA = orderPlayersByVotes(currentMatch!.nations[0].players);
      if (sortedPlayersA) setTeamACaptain(sortedPlayersA![0].nickname);
      if (sortedPlayersA) setTeamAPickedPlayer(sortedPlayersA!.filter((player) => player.pickedByCaptain === true)[0].nickname);
      const sortedPlayersB = orderPlayersByVotes(currentMatch!.nations[1].players);
      if (sortedPlayersB) setTeamBCaptain(sortedPlayersB![0].nickname);
      if (sortedPlayersB) setTeamBPickedPlayer(sortedPlayersB!.filter((player) => player.pickedByCaptain === true)[0].nickname);
    }
  }, [currentMatch?.nations]);

  return (
    <div className={styles.componentContainer}>
      {nationFlags.map((n, index) => {
        if (n === nation) {
          return (
            <div key={index}>
              <div  className={styles.playersContainer} style={ index === 0 ? {left: '0'} : {right : '0'}}>
                <div className={styles.captainContainer}
                  onMouseEnter={() => {setHoveredPlayer(0);}}
                  onMouseLeave={() => setHoveredPlayer(null)}
                  onClick={() => {
                    if (index === 0) handlePlayerSelection(teamACaptain!, teamAPickedPlayer!, index);
                    else handlePlayerSelection(teamBCaptain!, teamBPickedPlayer!, index);
                  }}
                  style={hoveredPlayer === 0 ? {border: '1px dashed blue'} : hoveredPlayer === 1 ? {border: '1px dashed orange'} : {}}
                >
                  {index === 0 ? teamACaptain : teamBCaptain}
                </div>
                <div
                  className={styles.pickedPlayerContainer}
                  onMouseEnter={() => setHoveredPlayer(1)}
                  onMouseLeave={() => setHoveredPlayer(null)}
                  onClick={() => {
                    if (index === 0) handlePlayerSelection(teamAPickedPlayer!, teamACaptain!, index);
                    else handlePlayerSelection(teamBPickedPlayer!, teamBCaptain!, index);
                  }}
                  style={hoveredPlayer === 1 ? {border: '1px dashed blue'} : hoveredPlayer === 0 ? {border: '1px dashed orange'} : {}}

                >
                  {index === 0 ? teamAPickedPlayer : teamBPickedPlayer}
                </div>
              </div>
            </div>
          );
        }
      })
      }
    </div>
  );
};

export default NationsMatchesPlayers;
