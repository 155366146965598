import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';
import styles from './NationsVetoesDashboard.module.css';
import {Button} from 'react-bootstrap';
import Select from 'react-select';
import {useNavigate, useParams} from 'react-router-dom';
import {loadMatch, loadNation, loadNationsListNoIp, startVetoes, updateMatch, pauseTimer, startTimer} from 'src/features/tools/nationwars';
import {Controller, useForm} from 'react-hook-form';
import {createOptions, createOptionsFlags} from 'src/utils/selectOptions';
import {useEffect} from 'react';



interface IFormInputs {
  startingNation: { label: string; value: string };
  background: boolean;
}
const NationsMatchesDashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {nationsList, matchList, matchDetails} = useAppSelector((s) => s.nations);
  const {id} = useParams();
  const {register, handleSubmit, control, watch, formState: {errors, isValid}} = useForm<IFormInputs>({
    mode: 'onChange',
  });

  const startVetoClick = () => {
    dispatch(startVetoes(id!));
  };

  const onStopTimerClick = () => {
    dispatch(pauseTimer(id!));
  };

  const onStartTimerClick = () => {
    dispatch(startTimer(id!));
  };

  useEffect(() => {
    dispatch(loadMatch(id!));
    dispatch(loadNationsListNoIp());
  }, [id]);

  const onSubmitRealisationUpdate = (data: IFormInputs) => {
    const {startingNation, background} = data;
    dispatch(updateMatch(id!, {startingNation: startingNation.value, background}, navigate));
  };
  const selectOptionsStartingNation = matchDetails && createOptionsFlags([matchDetails!.nations[0], matchDetails!.nations[1]]);

  return (
    <div className={styles.pageContainer}>
      <div className='d-flex'>
        <Button variant='primary' onClick={startVetoClick}>
          START VETO
        </Button>
        <Button variant='danger' onClick={onStopTimerClick}>
          PAUSE TIMER
        </Button>
        <Button variant='success' onClick={onStartTimerClick}>
          START TIMER
        </Button>
      </div>
      <form onSubmit={handleSubmit(onSubmitRealisationUpdate)}>
        <div className='d-flex'>
          <div className="w-50 pr-3">
            <label>Starting Country</label>
              <Controller
                name="startingNation"
                control={control}
                render={({field}) => <Select
                  className={styles.select}
                  {...field}
                  options={selectOptionsStartingNation!}
                />}
              />
          </div>

          <div className="w-50 pr-3">
            <label>Background</label>
            <input type="checkbox" placeholder="background" {...register('background')} />
          </div>
        </div>

        <input type="submit" value="UPDATE"/>

      </form>
    </div>
  );
};

export default NationsMatchesDashboard;
