/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {getUser} from 'src/utils/localUser';
import {AlertTypes, createAlert} from '../global/alert';
import {AppThunk} from '../global/helpers';

export interface Article {
  id: string;
  _id: string;
  url: string;
  image: string;
  title: string;
  displayRank: number;
}

export interface UpdateArticle {
  url: string;
  image: string;
  title: string;
}
export interface ArticleState {
  articlesList: Article[];
  articleDetails: Article | null;
}

const initialState: ArticleState = {
  articlesList: [],
  articleDetails: null,
};

const articles = createSlice({
  name: 'articles',
  initialState,
  reducers: {
    articlesLoaded(state, {payload}: PayloadAction<Article[]>) {
      state.articlesList = payload;
    },
    articleDetailsLoaded(state, {payload}: PayloadAction<Article>) {
      state.articleDetails = payload;
    },
    articleDetailsUnloaded(state) {
      state.articleDetails = null;
    },
  },
});

export const {articlesLoaded, articleDetailsLoaded, articleDetailsUnloaded} = articles.actions;

export default articles.reducer;

const baseApiUrl = process.env.REACT_APP_BASE_URL;
let token: string | undefined;
if (getUser()) {
  token = getUser().token;
}

export const loadArticlesList = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/articles/fetch/all`, {headers: {Authorization: `Bearer ${token}`}});
    dispatch(articlesLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const loadArticle = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/articles/fetch/${id}`, {headers: {Authorization: `Bearer ${token}`}});
    dispatch(articleDetailsLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const createArticle = (member: any, history: any): AppThunk => async (dispatch) => {
  const config = {headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.post(`${baseApiUrl}/articles/create`, member, config);
    dispatch(createAlert('Article successfully created!', AlertTypes.success));
    setTimeout(() => history(-1), 1500);
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const updateArticle = (id: string, member: any, history: any): AppThunk => async (
  dispatch,
) => {
  const config = {headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.patch(
      `${baseApiUrl}/articles/update/${id}`,
      member,
      config,
    );
    dispatch(createAlert('Article successfully updated!', AlertTypes.success));
    setTimeout(() => history(-1), 1500);

    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const deleteArticle = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.delete(`${baseApiUrl}/articles/delete/${id}`, {headers: {Authorization: `Bearer ${token}`}});
    dispatch(createAlert('Article successfully deleted!', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const updateArticleRank = (id: string, event: any): AppThunk => async (dispatch) => {
  const config = {headers: {Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.patch(
      `${baseApiUrl}/articles/update/rank/${id}`,
      event,
      config,
    );
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};
