/* eslint-disable max-len */
import {useContext} from 'react';
import {useParams} from 'react-router-dom';
import {MatchMaps, NationMatch} from 'src/features/tools/nationwars';
import {soloMaps} from 'src/utils/nationwarsHelpers';
import MatchContext from '../NationsWarsMatchContext';
import styles from './NationsMatchesCentralPanel.module.css';

const encountersArray = [[1, 1], [2, 2], [1, 2], [2, 1]];

const mapCorrectOrder = [0, 1, 2, 3, 8, 4, 5, 6, 7, 9];
const NationsMatchesCentralPanel = () => {
  const {nation} = useParams();
  const {currentMatch, hoveredPlayer} = useContext(MatchContext);
  const {currentStep} = currentMatch!;

  const reorderMaps = () => {
    const orderedMaps = [];
    if (!currentMatch) return;
    console.log(currentMatch!.maps);
    for (let index = 0; index < currentMatch!.maps.length; index++) {
      orderedMaps.push(currentMatch!.maps[mapCorrectOrder[index]]);
    }
    return orderedMaps;
  };

  const lineUp = currentStep !== 12 ? currentMatch?.maps.filter((m) => m.veto === false) : reorderMaps()?.filter((m) => m.veto === false);
  const isSecondNation = currentMatch?.nations[1].flag === nation;

  const displayMapDefaultPlayer = (index: number) => {
    switch (index) {
      case 0:
        return encountersArray[0];
      case 2:
        return 2;
      default:
        break;
    }
  };

  const calculateMapBackground = (order: number) => {
    if (order === 3  || order === 4 || order === 5 ||  order === 6) return styles.mapBackgroundSingle;
    if (order === 9  || order === 10) return styles.mapBackgroundDuo;
    if (order === 11) return styles.mapBackgroundAce;
    return styles.mapBackgroundSingle;
  };

  return (
    <div className={styles.componentContainer}>
      <div className={styles.panelTable}>
        {lineUp!.map((map: MatchMaps, index: number ) => (
          <div
            key={Math.random() * 100}
            className={calculateMapBackground(map.order)}
            style={hoveredPlayer !== null ? (!isSecondNation ? (index === 0 || index === 2) : (index === 0 || index === 3)) ? {border: '2px ridge blue'} : {border: '2px ridge orange'} : {}}
          >
            <div className={styles.playerContainer}>{map.players.playerA && currentStep > 7 ? map.players.playerA : (map.order !== 9 && map.order !== 10) ? `A${encountersArray[index][0]}` : `Team ${lineUp![0].nation}`}</div>
            <div className={styles.imageContainer}>
              <img src={map.map.mapUrl} alt={map.map.name} className={styles.image}/>
            </div>
            <div className={styles.playerContainer}>{map.players.playerB && currentStep > 7 ? map.players.playerB : (map.order !== 9 && map.order !== 10) ? `B${encountersArray[index][1]}` : `Team ${lineUp![1].nation}`}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NationsMatchesCentralPanel;
