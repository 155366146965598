import {useEffect} from 'react';
import {loadMembersList} from 'src/features/website/members';
import MembersList from 'src/components/Website/Members/MembersList';
import styles from './MembersPage.module.css';
import {useAppDispatch} from 'src/hooks/hooks';

const MembersPage = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadMembersList());
  }, [dispatch]);
  return (
    <div className={styles.pageContainer}>
      <MembersList />
    </div>
  );
};

export default MembersPage;
