/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {getUser} from 'src/utils/localUser';
import {AlertTypes, createAlert} from '../global/alert';
import {AppThunk} from '../global/helpers';

export interface Member {
  type: 'Member';
  id: string;
  firstname: string;
  lastname: string;
  position: string;
  description: string;
  pole: string;
  member_since: Date;
  age: number;
  imageUrl: string;
  updatedAt: Date;

}

export interface UpdateMember {
  firstname: string;
  lastname: string;
  position: string;
  description: string;
  pole: string;
}
export interface MemberState {
  membersList: Member[];
  memberDetails: Member | null;
}

const initialState: MemberState = {
  membersList: [],
  memberDetails: null,
};

const members = createSlice({
  name: 'members',
  initialState,
  reducers: {
    membersLoaded(state, {payload}: PayloadAction<Member[]>) {
      state.membersList = payload;
    },
    memberDetailsLoaded(state, {payload}: PayloadAction<Member>) {
      state.memberDetails = payload;
    },
    memberDetailsUnloaded(state) {
      state.memberDetails = null;
    },
  },
});

export const {membersLoaded, memberDetailsLoaded, memberDetailsUnloaded} = members.actions;

export default members.reducer;

const baseApiUrl = process.env.REACT_APP_BASE_URL;
let token: string | undefined;
if (getUser()) {
  token = getUser().token;
}

export const loadMembersList = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/members/fetch/all`, {headers: {Authorization: `Bearer ${token}`}});
    dispatch(membersLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const loadMember = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/members/fetch/${id}`, {headers: {Authorization: `Bearer ${token}`}});
    dispatch(memberDetailsLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const createMember = (member: any, history: any): AppThunk => async (dispatch) => {
  const config = {headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.post(`${baseApiUrl}/members/create`, member, config);
    dispatch(createAlert('Member successfully created!', AlertTypes.success));
    setTimeout(() => history(-1), 1500);
    if (!response) {
      throw new Error();
    }
  } catch (err : any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const updateMember = (id: string, member: any, history: any): AppThunk => async (
  dispatch,
) => {
  const config = {headers: {'Content-Type': 'multipart/form-data', Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.patch(
      `${baseApiUrl}/members/update/${id}`,
      member,
      config,
    );
    dispatch(createAlert('Member successfully updated!', AlertTypes.success));
    setTimeout(() => history(-1), 1500);

    if (!response) {
      throw new Error();
    }
  } catch (err : any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const deleteMember = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.delete(`${baseApiUrl}/members/delete/${id}`, {headers: {Authorization: `Bearer ${token}`}});
    dispatch(createAlert('Member successfully deleted!', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};
