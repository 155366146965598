import RealisationsList from 'src/components/Website/Realisations/RealisationsList';
import styles from './RealisationsPage.module.css';

const RealisationsPage = () => (
    <div className={styles.pageContainer}>
      <RealisationsList />
    </div>
);

export default RealisationsPage;
