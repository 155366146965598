/* eslint-disable react/jsx-props-no-spreading */
import {useEffect, useState} from 'react';
import Select from 'react-select';
import {Controller, useForm} from 'react-hook-form';
import io from 'socket.io-client';
import {Button, Modal} from 'react-bootstrap';
import {
  deleteVote, hidePercentages, loadActiveVote, loadVotesList, renderOverlay, showResults, startVote, stopVote, storeVotesData, Vote,
} from 'src/features/tools/votes';
import Icon from 'awesome-react-icons';
import styles from './VotesPage.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

enum VotesSteps {
  RENDER = 'render',
  START = 'start',
  RESULT = 'result',
  STOP = 'stop',
}
interface IFormInputs {
  _id: string;
  question: string;
  answerA: string;
  answerB: string;
  answerC: string;
  answerD: string;
  correctAnswer: string;
  channel: { label: string; value: string };
}

const VoteInitialised = {
  _id: '',
  type: '',
  votes: {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
  },
  votesPercentage: {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
  },
  channel: '',
  alreadyVoted: [],
  question: '',
  answerA: '',
  answerB: '',
  answerC: '',
  answerD: '',
  correctAnswer: '',
  voteFinished: false,
};

const baseApiUrl = process.env.REACT_APP_BASE_URL ?? '';
const VotesPage = () => {
  const [currentStep, setCurrentStep] = useState<VotesSteps>(VotesSteps.RENDER);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentID, setCurrentID] = useState<string>('');
  const [socket, setSocket] = useState<any>();
  const dispatch = useAppDispatch();
  const {votesList, activeVote} = useAppSelector((s) => s.votes);
  const {register, handleSubmit, getValues, setValue, control} = useForm<IFormInputs>({
    mode: 'onChange',
  });

  const [voteResult, setVoteResult] = useState<Vote>(VoteInitialised);

  const handleStopVote = () => {
    if (activeVote) {
      dispatch(stopVote(activeVote._id));
    } else {
      dispatch(stopVote(currentID));
    }
    socket.emit('refreshRenderToServer', true);
    setTimeout(() => {
      dispatch(loadActiveVote());
    }, 1000);
  };

  const handleShowResults = () => {
    if (activeVote) {
      dispatch(showResults(activeVote._id));
    } else {
      dispatch(showResults(currentID));
    }
  };

  useEffect(() => {
    setSocket(io(`${baseApiUrl}/backseat-poll`));
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on('msgToClient', (message: Vote) => {
        setVoteResult(message);
      });
    }
    return () => {
      if (socket?.connected) socket.disconnect();
    };
  }, [socket]);

  useEffect(() => {
    if (activeVote !== '') {
      if (activeVote.voteFinished === false) setCurrentStep(VotesSteps.RESULT);
    }
  }, [activeVote]);

  useEffect(() => {
    dispatch(loadVotesList());
    dispatch(loadActiveVote());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadValues = (data: Vote) => {
    const fields = Object.getOwnPropertyNames(getValues());
    fields.forEach((field) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setValue(field, data[`${field}`]);
    });
    setValue('channel', {value: data.channel, label: data.channel});
  };
  const handleSubmitForm = async (data: IFormInputs) => {
    setCurrentStep(VotesSteps.RESULT);
    const newVote: any = {
      question: '',
      answerA: '',
      answerB: '',
      answerC: '',
      answerD: '',
      correctAnswer: '',
      channel: '',
      _id: '',
    };
    if (data.correctAnswer) newVote.correctAnswer = data.correctAnswer;
    if (data.answerA) newVote.answerA = data.answerA;
    if (data.answerB) newVote.answerB = data.answerB;
    if (data.answerC) newVote.answerC = data.answerC;
    if (data.answerD) newVote.answerD = data.answerD;
    if (data.channel) newVote.channel = data.channel.value;
    if (data.question) newVote.question = data.question;
    if (currentID) newVote._id = currentID;
    await dispatch(startVote(newVote));
    await dispatch(loadActiveVote());
  };

  const handleDeleteVote = async (id: string) => {
    await dispatch(deleteVote(id));
    setOpenDeleteModal(false);
    await dispatch(loadVotesList());
  };
  const channelSelectOptions: { value: string; label: string; }[] = [
    {value: 'jeanmassiet', label: 'jeanmassiet'},
    {value: 'gostephano', label: 'gostephano'},
    {value: 'stephanodev', label: 'stephanodev'},
    {value: 'loltyler1', label: 'loltyler1'},
  ];

  return (
    <>
      <div className={` ${styles.pageContainer}`}>

      <Modal show={openDeleteModal} onHide={() => setOpenDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Vote</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete the vote ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOpenDeleteModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleDeleteVote(currentID)}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

        <form onSubmit={handleSubmit(handleSubmitForm)}>
          <div>
            <label>Question</label>
            <input {...register('question')} defaultValue={activeVote && activeVote.question}/>
          </div>
          <div className="d-flex">
            <div className="w-75 pr-3">
              <label>Answer A</label>
              <input {...register('answerA')} defaultValue={activeVote && activeVote.answerA}/>

            </div>
            <div className={`w-25 ${styles.marginLeft}`}>
              <label># Votes</label>
              <input value={voteResult?.votes?.A} type="number" defaultValue={activeVote && activeVote.votes.A}/>

            </div>
          </div>
          <div className="d-flex">
            <div className="w-75 pr-3">
              <label>Answer B</label>
              <input {...register('answerB')} defaultValue={activeVote && activeVote.answerB}/>
            </div>
            <div className={`w-25 ${styles.marginLeft}`}>
              <label># Votes</label>
              <input value={voteResult?.votes?.B} type="number" defaultValue={activeVote && activeVote.votes.B}/>

            </div>
          </div>
          <div className="d-flex">
            <div className="w-75 pr-3">
              <label>Answer C</label>
              <input {...register('answerC')} defaultValue={activeVote && activeVote.answerC}/>
            </div>
            <div className={`w-25 ${styles.marginLeft}`}>
              <label># Votes</label>
              <input value={voteResult?.votes?.C} type="number" defaultValue={activeVote && activeVote.votes.C}/>

            </div>
          </div>
          <div className="d-flex">
            <div className="w-75 pr-3">
              <label>Answer D</label>
              <input {...register('answerD')} defaultValue={activeVote && activeVote.answerD}/>
            </div>
            <div className={`w-25 ${styles.marginLeft}`}>
              <label># Votes</label>
              <input value={voteResult?.votes?.D} type="number" defaultValue={activeVote && activeVote.votes.D}/>

            </div>
          </div>
          <div className="d-flex">
            <div className={`w-50 ${styles.marginRight}`}>
              <label>Channel</label>
              <Controller
                name="channel"
                control={control}
                render={({field}) => <Select
                  {...field}
                  options={channelSelectOptions}
                />}
              />
            </div>
            <div className={`w-50 ${styles.marginLeft}`}>
              <label>Correct Answer</label>
              <input {...register('correctAnswer')} defaultValue={activeVote && activeVote.correctAnswer}/>
            </div>
          </div>

          <input type="submit" value="2-Start Vote" disabled={currentStep !== VotesSteps.START}/>
        </form>

        <label>Link</label>
        <input type="text" value="https://admin.alttabprod.com/tools/votes/QCM/render"/>
        <div className="d-flex mt-4">
          <button
            className={`buttonBlue ${styles.width30}`}
            onClick={async () => {
              await dispatch(storeVotesData({...getValues(), channel: getValues().channel.value}));
              await dispatch(loadVotesList());
            }}
          >
              Store Q&A
          </button>
          <button
            className={`buttonPink ${styles.width30}`}
            onClick={() => {
              dispatch(renderOverlay(getValues()));
              setCurrentStep(VotesSteps.START);
            }}
            style={{opacity: currentStep !== VotesSteps.RENDER ? 0.3 : 1}}
            disabled={currentStep !== VotesSteps.RENDER}
          >
              1-Render overlay
          </button>
          <button
            className={`buttonYellow ${styles.width30}`}
            onClick={() => {
              handleShowResults();
              setCurrentStep(VotesSteps.STOP);
            }}
            style={{opacity: currentStep !== VotesSteps.RESULT ? 0.3 : 1}}
            disabled={currentStep !== VotesSteps.RESULT}

          >
            3-Show Result
          </button>
          <button
            onClick={() => {
              handleStopVote();
              setCurrentStep(VotesSteps.RENDER);
            }}
            className={`buttonRed ${styles.width30}`}
            style={{opacity: currentStep !== VotesSteps.STOP ? 0.3 : 1}}
            disabled={currentStep !== VotesSteps.STOP}

          >
            4-Stop Vote
          </button>
        </div>
        <div className="d-flex mt-4">
          <button
            className={`buttonYellow ${styles.width15}`}
            onClick={() => { dispatch(hidePercentages('NONE')); }}
          >
              Show ALL
          </button>
          <button
            className={`buttonRed ${styles.width15}`}
            onClick={() => { dispatch(hidePercentages('ALL')); }}

          >
              Hide ALL
          </button>
          <button
            className={`buttonPink ${styles.width15}`}
            onClick={() => { dispatch(hidePercentages('A')); }}

          >
              Show A
          </button>
          <button
            className={`buttonPink ${styles.width15}`}
            onClick={() => { dispatch(hidePercentages('B')); }}
          >
            Show B
          </button>
          <button
            onClick={() => { dispatch(hidePercentages('C')); }}
            className={`buttonPink ${styles.width15}`}
          >
            Show C
          </button>
          <button
            onClick={() => { dispatch(hidePercentages('D')); }}
            className={`buttonPink ${styles.width15}`}
          >
            Show D
          </button>
        </div>

        <div className={styles.voteListContainer}>
          <div className={styles.title}>Previous Votes</div>
          <div className={styles.tableContainer}>
            <table className="table table-dark">
              <thead>
                <tr>
                  <th scope="col">Channel</th>
                  <th scope="col">Question</th>
                  <th scope="col">A</th>
                  <th scope="col">B</th>
                  <th scope="col">C</th>
                  <th scope="col">D</th>
                  <th scope="col">Correct Answer</th>
                  <th scope="col">Load</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                {votesList && votesList.map((vote: Vote) => (
                  <tr key={vote?._id}>
                    <td>{vote?.channel}</td>
                    <td>{vote?.question}</td>
                    <td>{vote?.answerA}</td>
                    <td>{vote?.answerB}</td>
                    <td>{vote?.answerC}</td>
                    <td>{vote?.answerD}</td>
                    <td>{vote?.correctAnswer}</td>
                    <td>
                      <div
                        className={styles.cursorPointer}
                        onClick={() => {
                          handleLoadValues(vote);
                          setCurrentID(vote._id);
                        }
                        }
                      >
                        <Icon name="activity" />
                      </div>
                    </td>
                    <td>
                      <div
                        className={styles.cursorPointer}
                        onClick={() => {
                          setCurrentID(vote._id);
                          setOpenDeleteModal(true);
                        } }
                      >
                        <Icon name="trash" />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default VotesPage;
