/* eslint-disable react/jsx-props-no-spreading */
import {yupResolver} from '@hookform/resolvers/yup';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import BackButton from 'src/components/Global/BackButton';
import {AlertTypes, createAlert} from 'src/features/global/alert';
import {createMember} from 'src/features/website/members';
import {useAppDispatch} from 'src/hooks/hooks';
import {getUser} from 'src/utils/localUser';
import * as yup from 'yup';
import ImagePicker from '../../../Global/ImagePicker';
import styles from './MemberCreate.module.css';

interface MemberImage {
  image: File | undefined;
}

interface IFormInputs {
  firstname: string;
  lastname: string;
  description: string;
  pole: string;
  position: string;
}

const SignupSchema = yup.object().shape({
  firstname: yup.string().min(2, 'Firstname must be at least 2 characters'),
  lastname: yup.string().min(2, 'Lastname must be at least 2 characters'),
  description: yup.string().min(6, 'Description must be at least 6 characters'),
  pole: yup.string().min(2, 'Pole must be at least 2 characters'),
  position: yup.string().min(4, 'Position must be at least 4 characters'),
});

const CreateMemberComponent = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {register, handleSubmit, formState: {errors, isValid}} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });

  const [memberImage, setMemberImage] = useState<MemberImage>({
    image: undefined,
  });

  // Route not allowed for non editor users
  useEffect(() => {
    if (getUser().role === 'editor') navigate('/');
  }, [navigate]);

  const onFileChange = (file: File | undefined) => {
    setMemberImage({image: file});
  };

  const onSubmitMemberCreate = (data: IFormInputs) => {
    const newMember = new FormData();
    if (!memberImage.image) {
      dispatch(createAlert('Please pick a photo', AlertTypes.warning));
      return;
    }
    if (data.description) newMember.append('description', data.description);
    if (memberImage.image) newMember.append('image', memberImage.image);
    if (data.firstname) newMember.append('firstname', data.firstname);
    if (data.lastname) newMember.append('lastname', data.lastname);
    if (data.position) newMember.append('position', data.position);
    if (data.pole) newMember.append('pole', data.pole);

    dispatch(createMember(newMember, navigate));
  };

  return (
    <div>
      <div className={styles.title}>Create Member</div>
      <BackButton />
      <form onSubmit={handleSubmit(onSubmitMemberCreate)}>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>First Name</label>
            <input {...register('firstname')} />
            {errors.firstname && <p>{errors.firstname.message}</p>}

          </div>
          <div className="w-50 pl-3">
            <label>Last Name</label>
            <input {...register('lastname')} />
            <p>{errors.lastname?.message}</p>
          </div>
        </div>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Pole</label>
            <input {...register('pole')} />
            <p>{errors.pole?.message}</p>
          </div>
          <div className="w-50 pl-3">
            <label>Position</label>
            <input {...register('position')} />
            <p>{errors.position?.message}</p>
          </div>
        </div>

        <label>Description</label>
        <input {...register('description')} />
        <p>{errors.description?.message}</p>

        <label>Pick Photo</label>
        <div className={styles.imagePickerContainer}>
          <ImagePicker
            labelText="Add A Banner For Your Event"
            fileHandler={onFileChange}
            errorText=""
            previewHeight={200}
            previewWidth={200}
            heightRatio={1}
            widthRatio={1}
          />
        </div>
        <input type="submit" value="Publish" disabled={!isValid}/>
      </form>
    </div>
  );
};

export default CreateMemberComponent;
