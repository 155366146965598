import {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useNavigate, useParams} from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import ImagePicker from 'src/components/Global/ImagePicker';
import * as yup from 'yup';
import {loadPartner, loadPartnersList, updatePartner} from 'src/features/website/partners';
import {yupResolver} from '@hookform/resolvers/yup';
import {createOptions} from 'src/utils/selectOptions';
import {loadEventsList} from 'src/features/website/events';
import BackButton from 'src/components/Global/BackButton';
import styles from './PartnerUpdate.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

interface PartnerUpdateParams {
  idparam: string;
}

interface IFormInputs {
  title: string;
  description: string;
  date: string;
  events: { label: string; value: string }[];
  clientType: string;
}

interface PartnerImage {
  image: File | undefined;
}

const SignupSchema = yup.object().shape({
  title: yup.string().min(2, 'Title must be at least 2 characters'),
  description: yup.string().min(2, 'Description must be at least 2 characters'),
  clientType: yup.string().min(2, 'Client Type must be at least 2 characters'),
});

const PartnerUpdate = () => {
  const {id} = useParams();
  const {partnerDetails, partnersList} = useAppSelector((s) => s.partners);
  const {eventsList} = useAppSelector((s) => s.events);
  const currentPartner = partnersList.filter((p) => p.id === id)[0];
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(loadPartnersList());
    dispatch(loadPartner(id!));
    dispatch(loadEventsList());
  }, [dispatch, id]);

  const {register, handleSubmit, setValue, control, formState: {errors, isDirty, isValid}} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });
  const [partnerImage, setPartnerImage] = useState<PartnerImage>({
    image: undefined,
  });

  const onFileChange = (file: File | undefined) => {
    setPartnerImage({image: file});
  };

  const onSubmitPartnerUpdate = (data: IFormInputs) => {
    const updatedPartner = new FormData();
    if (data.events) data.events.forEach((event) => updatedPartner.append('events[]', event.value));
    if (data.description) updatedPartner.append('description', data.description);
    if (partnerImage.image) updatedPartner.append('image', partnerImage.image);
    if (data.clientType) updatedPartner.append('clientType', data.clientType);
    if (data.title) updatedPartner.append('title', data.title);
    if (data.date) updatedPartner.append('date', data.date);
    dispatch(updatePartner(id!, updatedPartner, navigate));
  };

  useEffect(() => {
    if (currentPartner) {
      setValue('title', currentPartner.title, {shouldValidate: true});
      setValue('description', currentPartner.description, {shouldValidate: true});
      setValue('clientType', currentPartner.clientType, {shouldValidate: true});
    }
  }, [currentPartner, setValue]);

  // Create Options and default options for select component
  const selectOptions = createOptions(eventsList);
  const defaultOptions = currentPartner && createOptions(currentPartner.events);

  return (
    <div>
      <div className={styles.title}>Update Partner</div>
      <BackButton />
      <form onSubmit={handleSubmit(onSubmitPartnerUpdate)}>

        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Title</label>
            <input {...register('title')} defaultValue={partnerDetails?.title} />
            {errors.title && <p>{errors.title.message}</p>}
          </div>
          <div className="w-50 pl-3">
            <label>Date</label>
            <Controller
              control={control}
              name="date"
              render={({field}) => (
                <DatePicker
                  placeholderText="Select date"
                  onChange={(date: any) => field.onChange(date)}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  selected={field.value}
                />
              )}
            />
          </div>
        </div>

        <label>Description</label>
        <textarea {...register('description')} defaultValue={partnerDetails?.description} rows={5}/>
        <p>{errors.description?.message}</p>

        <label>Events List</label>
        {defaultOptions && (
          <Controller
            name="events"
            control={control}
            render={({field}) => <Select
              {...field}
              isMulti
              options={selectOptions}
              defaultValue={defaultOptions}
            />}
          />
        )}

        <label>Client Type</label>
        <input {...register('clientType')} defaultValue={partnerDetails?.clientType} />
        <p>{errors.clientType?.message}</p>

        <label>Pick Logo</label>
        <div className={styles.imagePickerContainer}>
          {partnerDetails?.imageUrl
            && <img
            src={partnerDetails?.imageUrl}
            alt="PartnerLogo"
            className={styles.oldImage}
          />
          }
          <ImagePicker
            labelText="Pick a logo"
            fileHandler={onFileChange}
            errorText=""
            previewHeight={200}
            previewWidth={200}
            heightRatio={130}
            widthRatio={215}
          />
        </div>
        <input type="submit" value="Update" disabled={!isDirty} />
      </form>
    </div>
  );
};

export default PartnerUpdate;
