import {useState} from 'react';
import Icon from 'awesome-react-icons';
import {Button, Modal} from 'react-bootstrap';
import {deletePlayer, Nation, Player} from 'src/features/tools/nationwars';
import styles from './PlayersList.module.css';
import UpdatePlayer from '../UpdatePlayer';
import {useAppDispatch} from 'src/hooks/hooks';

interface PlayersListProps {
  nation: Nation;
}
const PlayersList = ({nation} : PlayersListProps) => {
  const dispatch = useAppDispatch();
  const [currentPlayer, setCurrentPlayer] = useState<Player>();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);

  const handleDeleteClick = async () => {
    if (currentPlayer) dispatch(deletePlayer(nation._id, currentPlayer?.id));
    setOpenDeleteModal(false);
  };

  const renderUpdateModal = () => (
    <Modal show={openUpdateModal} onHide={() => setOpenUpdateModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Update Player</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentPlayer && <UpdatePlayer nationId={nation._id} player={currentPlayer} setUpdateModal={setOpenUpdateModal}/>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setOpenDeleteModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const renderDeleteModal = () => (
    <Modal show={openDeleteModal} onHide={() => setOpenDeleteModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Player</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete{' '}
        {`${currentPlayer?.nickname}`} ?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setOpenDeleteModal(false)}>
          Close
        </Button>
        <Button variant="primary" onClick={() => handleDeleteClick()}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
  return (
    <div className={styles.pageContainer}>
      {renderUpdateModal()}
      {renderDeleteModal()}
      <div className={styles.title}>Players List</div>
      <div className={styles.tableContainer}>
        <table className="table table-dark">
          <thead>
            <tr>
              <th scope="col">Firstname</th>
              <th scope="col">Nickname</th>
              <th scope="col">Lastname</th>
              <th scope="col">#Votes</th>
              <th scope="col">Picked By Captain</th>
              <th scope="col">Update</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {nation.players && nation.players.map((player: Player) => (
              <tr key={player?.id}>
                <td>{player?.firstname}</td>
                <td>{player?.nickname}</td>
                <td>{player?.lastname}</td>
                <td>{player?.votes}</td>
                <td>{player?.pickedByCaptain ? 'True' : 'False'}</td>
                <td>
                  <div
                    className={styles.cursorPointer}
                    onClick={() => {
                      setOpenUpdateModal(true);
                      setCurrentPlayer(player);
                    }}
                  >
                    <Icon name="activity" />
                  </div>
                </td>
                <td>
                  <button
                    className="deleteButton"
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setCurrentPlayer(player);
                    }}
                  >
                    <Icon name="trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlayersList;
