/* eslint-disable @typescript-eslint/ban-ts-comment */
import {useEffect, useState} from 'react';
import {ReactSortable} from 'react-sortablejs';
import {AlertTypes, createAlert} from 'src/features/global/alert';
import {loadTalentsList, Talent, updateTalentRank} from 'src/features/website/talents';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';
import styles from './TalentOrder.module.css';

const TalentOrder = () => {
  const dispatch = useAppDispatch();
  const {talentsList} = useAppSelector((s) => s.talents);
  const [rankList, setRankList] = useState<Talent[]>(talentsList);
  const displayedTalents = 3;

  useEffect(() => {
    setRankList(talentsList.slice().sort((a, b) => a.displayRank - b.displayRank));
  }, [talentsList]);

  useEffect(() => {
    dispatch(loadTalentsList());
  }, [dispatch]);

  const updateClientsOrder = (id: string, newDisplayOrder: number) => {
    const updatedEvent = {
      displayRank: newDisplayOrder,
    };
    dispatch(updateTalentRank(id, updatedEvent));
  };

  const handleClientOrderSubmit = () => {
    for (let index = 0; index < rankList.length; index++) {
      const talent = rankList[index];
      if (index + 1 !== talent.displayRank && index < displayedTalents) {
        updateClientsOrder(talent.id, index + 1);
      }
    }
    dispatch(createAlert('Talents Order successfully updated!', AlertTypes.success));
  };
  return (
    <div className="container">
      <div className={`${styles.title} mb-3`}>Order Talents</div>
      <div className={`${styles.description} mb-3`}>
        Order talents from 1 to {displayedTalents}, any partner {'>='} {displayedTalents} will not be displayed
      </div>
      {/*//@ts-ignore */}
      <ReactSortable tag="div" list={rankList} setList={setRankList} className="row">
        {!!rankList && rankList.map((talent, index) => (
          <div
            key={talent.displayRank}
            className={
              `${index < displayedTalents ? styles.elementDisplayed : styles.elementNotDisplayed}
              col-4
              ${styles.cardContainer}`}
          >
            <img
              src={talent?.photo}
              alt={`${talent?.title} profile`}
              className={styles.photo}
            />
          </div>
        ))}
      </ReactSortable>
      <input type="submit" value="Update List Order" onClick={handleClientOrderSubmit} />
    </div>
  );
};

export default TalentOrder;
