/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {io, Socket} from 'socket.io-client';
import {Donation, Palier} from 'src/features/tools/basecamp';
import turtle1 from 'src/assets/basecamp/donation/GIF_TORTUE_01.gif';
import turtle2 from 'src/assets/basecamp/donation/GIF_TORTUE_02.gif';
import turtle3 from 'src/assets/basecamp/donation/GIF_TORTUE_03.gif';
import turtle4 from 'src/assets/basecamp/donation/GIF_TORTUE_04.gif';
import styles from './DonationOverlay.module.css';

export const paliers: Palier = {
  0: {
    palier: 1,
    amount: 10000,
    unlocks: 'Le Discord insoumis annonce son tout nouveau jeu vidéo !',
    amountToRemove: 0,
  },
  1: {
    palier: 2,
    amount: 10000,
    unlocks: 'David Guiraud tente de battre un député sur LoL en équipe',
    amountToRemove: 10000,
  },
  2: {
    palier: 3,
    amount: 15000,
    unlocks: "Une personne du chat Melenchon_2022 remporte un exemplaire dédicacé de l'AEC",
    amountToRemove: 20000,
  },
  3: {
    palier: 4,
    amount: 15000,
    unlocks: 'Toute la salle de stream jump sur le dernier clip de campagne',
    amountToRemove: 35000,
  },
  4: {
    palier: 5,
    amount: 25000,
    unlocks: 'Usul et DépuTwitch imitent Plenel et Sarkozy en duo',
    amountToRemove: 50000,
  },
  5: {
    palier: 6,
    amount: 25000,
    unlocks: 'Des personnalités de la campagne tentent une chorégraphie sur la musique de campagne',
    amountToRemove: 75000,
  },
  6: {
    palier: 7,
    amount: 25000,
    unlocks: 'Jean-Luc Mélenchon savoure un lait fraise en direct',
    amountToRemove: 100000,
  },
  7: {
    palier: 8,
    amount: 25000,
    unlocks: "les député.e.s présent.e.s s'affrontent dans une bataille de punchlines",
    amountToRemove: 125000,
  },
  8: {
    palier: 9,
    amount: 50000,
    unlocks: "On organise un Allô Melenchon depuis l'Elysée après la victoire !",
    amountToRemove: 150000,
  },
};


const baseApiUrl = process.env.REACT_APP_BASE_URL2 ?? '';

const hypebarTotal = 1000;
const DonationOverlay = () => {
  const [socket, setSocket] = useState<Socket>();
  const [totalRaised, setTotalRaised] = useState(0);
  const [totalPast20, setTotalPast20] = useState(0);
  const [isHypebarAnimated, setIsHypebarAnimated] = useState(false);
  const [hypeBarFullTimestamps, setHypeBarFullTimestamps] = useState<Date[]>([]);
  const [palierNumber, setPalierNumber] = useState<number>(0);
  const [newPalierReached, setNewPalierReached] = useState(false);
  const [currentDonation, setCurrentDonation] = useState<Donation>();

  useEffect(() => {
    setSocket(io(`${baseApiUrl}/basecamp-donation`, {query: {channel: 'donation'}}));
  }, []);

  useEffect(() => {
    const onRefreshHypebar = (data:any) => {
      console.log(data);
      if (totalPast20 < hypebarTotal) setTotalPast20(data);
    };
    const onHypebarFull = () => {
      setHypeBarFullTimestamps((hypeBarFullTimestamp) => [...hypeBarFullTimestamp, new Date()]);
    };

    const onNextPalier = (serverPalierNumber: number) => {
      if (palierNumber !== serverPalierNumber) {
        setPalierNumber(serverPalierNumber);
      }
    };
    const onRefresh = (data:any) => {
      setTotalRaised(data - paliers[palierNumber].amountToRemove);
    };
    const onNewDonation = (donation: Donation) => {
      setCurrentDonation(donation);
      setTimeout(() => { setCurrentDonation(undefined); }, 5000);
    };
    if (socket) {
      socket.on('refresh-hypebar', onRefreshHypebar);
      socket.on('hypebar-full', onHypebarFull);
      socket.on('next-palier', onNextPalier);

      socket.on('refresh', onRefresh);
      socket.on('new-donation', onNewDonation);
    }
    return () => {
      socket?.off('refresh-hypebar', onRefreshHypebar);
      socket?.off('hypebar-full', onHypebarFull);
      socket?.off('next-palier', onNextPalier);
      socket?.off('refresh', onRefresh);
      socket?.off('new-donation', onNewDonation);
    };
  }, [socket, palierNumber]);

  useEffect(() => {
    if (hypeBarFullTimestamps.length === 0) return () => {};
    const mostRecentHypeBarFullTimestamp = hypeBarFullTimestamps[hypeBarFullTimestamps.length - 1];
    const timeUntilEndAnimated = Date.now() - mostRecentHypeBarFullTimestamp.getTime() + 10000;
    if (timeUntilEndAnimated < 0) return () => {};
    setIsHypebarAnimated(true);
    const hypebarTimeout = setTimeout(() => {
      setIsHypebarAnimated(false);
    }, timeUntilEndAnimated);

    return () => {
      clearTimeout(hypebarTimeout);
      setIsHypebarAnimated(false);
    };
  }, [hypeBarFullTimestamps]);

  useEffect(() => () => {
    if (socket?.connected) socket.disconnect();
  }, [socket]);

  useEffect(() => {
    setTotalRaised(totalRaised - paliers[palierNumber].amountToRemove);
  }, [palierNumber]);

  useEffect(() => {
    if (palierNumber !== 0) {
      setNewPalierReached(true);
      setTimeout(() => {
        setNewPalierReached(false);
      }, 10000);
    }
  }, [palierNumber]);

  const numberWithSpaces = (number: number) => number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  const calculateCorrectTurtle = () => {
    const percentage = +((totalPast20 / hypebarTotal) * 100).toFixed(0);
    if (percentage) {
      if (percentage < 25) return turtle1;
      if (percentage < 50) return turtle2;
      if (percentage < 75) return turtle3;
      if (percentage <= 100) return turtle4;
    }
    return turtle1;
  };
  console.log({turtleHeight: (totalPast20)});
  return (
    <div className={styles.pageContainer}>
      {/* <div className={styles.testButtons}>

        <button onClick={() => {
          setCurrentDonation({ amount: 100, date: new Date() });
          setTimeout(() => setCurrentDonation(undefined), 5000);
        }}>
          donation
        </button>
        <button onClick={() => {
          setNewPalierReached(true);
          setTimeout(() => setNewPalierReached(false), 10000);
        }}>
          palier
        </button>
      </div> */}
      <div className={styles.backgroundTransparent}>

        {/* //* Donation Notifs */}
        <div className={[
          styles.donationNotif,
          currentDonation ? styles.showDonation : styles.hideDonation,
        ].join(' ')}
        style={
          currentDonation === undefined ? {
            opacity: 0,
          } : {
            transform: 'translateY(900px)',
            transition: 'transform 1s ease-in-out',
          }
        }
        >
          <div className={styles.donationNotifText}>{currentDonation && numberWithSpaces(currentDonation?.amount)} €</div>
        </div>

        {/* //* Palier Notifs */}
        <div className={[
          styles.palierNotif,
          newPalierReached ? styles.showPalier : styles.hidePalier,
        ].join(' ')}
          style={
            !newPalierReached ? {
              opacity: 0,
            } : {
              transform: 'translateY(1133px)',
              transition: 'transform 1s ease-in-out',
            }
          }
          >
          <div className={styles.palierAmount}>{(palierNumber >= 1) && numberWithSpaces(paliers[palierNumber - 1].amount)} €</div>
          <div className={styles.palierText}>{(palierNumber >= 1) && paliers[palierNumber - 1].unlocks}</div>
          <div className={styles.palierNumber}>PALIER N°{(palierNumber >= 1) && paliers[palierNumber - 1].palier}</div>
        </div>

        {/* //* Hypebar */}
        <div className={styles.hypeBar}>
          <div className={styles.hypeBarPosition}>
            <div className={[
              styles.hypeBarFill,
              isHypebarAnimated && styles.hypeBarAnimate,
            ].join(' ')} style={{height: `${+((isHypebarAnimated ? 1 : (totalPast20 / hypebarTotal)) * 100).toFixed(0)}%`} } >

                {/* //* turtle */}
              <img alt="turtle" src={calculateCorrectTurtle()} className={styles.hypbarTurtle}
                style={
                   // eslint-disable-next-line no-nested-ternary
                   totalPast20 < 150 ? {top: '-160px'} : {top: '-90px'}
                }
              />
              </div>
          </div>

          {/* <div className={styles.hypeBarPerc}>{currentPalier && Math.floor(currentPalier?.amount / totalRaised)}%</div> */}
        </div>

        {/* //* Progress Bar */}
        <div className={styles.progressBar}>
            <div className={styles.progressBarPosition}>
              {/* <div className={styles.progressBarFill} style={{ width: `${totalRaised}%` }} /> */}
              <div
                className={styles.progressBarFill}
                style={{width: `${paliers[palierNumber] && +((totalRaised / paliers[palierNumber].amount) * 100)}%`} }
              />
            </div>
            <div className={styles.progressBarText}>{paliers[palierNumber] && paliers[palierNumber].unlocks}</div>
            <div className={styles.progressBarPerc}>{ +((totalRaised / paliers[palierNumber].amount) * 100).toFixed(0)}%</div>
        </div>

      </div>
    </div>
  );
};

export default DonationOverlay;
