import RessourcesList from 'src/components/Website/Ressources/RessourcesList';
import styles from './RessourcesPage.module.css';

const RessourcesPage = () => (
    <div className={styles.pageContainer}>
      <RessourcesList />
    </div>
);

export default RessourcesPage;
