import {useEffect, useState} from 'react';
import Icon from 'awesome-react-icons';
import {useNavigate} from 'react-router-dom';
import {Button, Modal} from 'react-bootstrap';
import {Article, articleDetailsUnloaded, deleteArticle, loadArticlesList} from 'src/features/website/articles';
import styles from './ArticlesList.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

const ArticlesList = () => {
  const dispatch = useAppDispatch();
  const {articlesList} = useAppSelector((s) => s.articles);
  const navigate = useNavigate();
  const [openDeleteModal, setDeleteOpenModal] = useState(false);
  const [currentArticle, setCurrentArticle] = useState<Article>();

  useEffect(() => {
    dispatch(articleDetailsUnloaded());
    dispatch(loadArticlesList());
  }, [dispatch]);

  const handleDeleteClick = () => {
    if (currentArticle?._id) {
      dispatch(deleteArticle(currentArticle?._id));
      setDeleteOpenModal(false);
    }
  };

  return (
    <div className={styles.membersListContainer}>
      <Modal show={openDeleteModal} onHide={() => setDeleteOpenModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Article</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete{' '}
          {`${currentArticle?.title}`} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteOpenModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleDeleteClick()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <div className={styles.title}>Articles List</div>
      <div className={styles.tableContainer}>
        <table className="table table-dark">
          <thead>
            <tr>
              <th scope="col">Title</th>
              <th scope="col">URL</th>
              <th scope="col">Photo</th>
              <th scope="col">Update</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {articlesList && articlesList.map((article: Article) => (
              <tr key={article?.id}>
                <td>{article?.title}</td>
                <td>{article?.url}</td>
                <td>
                  <img
                    src={article?.image}
                    alt={`${article?.title} profile`}
                    className={styles.photo}
                  />
                </td>
                <td>
                  <div
                    className={styles.cursorPointer}
                    onClick={() => {
                      navigate(`/website/articles/update/${article.id}`);
                    }}
                  >
                    <Icon name="activity" />
                  </div>
                </td>
                <td>
                  <button
                    className="deleteButton"
                    onClick={() => {
                      setDeleteOpenModal(true);
                      setCurrentArticle(article);
                    }}
                  >
                    <Icon name="trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ArticlesList;
