import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {sendMerchPayment} from 'src/features/tools/donations';
import {useAppDispatch} from 'src/hooks/hooks';
import * as yup from 'yup';
import styles from './DonneTempoPage.module.css';

interface IFormInputs {
  total: string;
}

const SignupSchema = yup.object().shape({
  total: yup.string().min(2, 'Amount must be at least 2 characters'),
});

const DonneTempoPage = () => {
  const dispatch = useAppDispatch();
  const {handleSubmit} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });

  const onSubmitMerchSubmit = (data: IFormInputs) => {
    const merchDonation = new FormData();
    if (data.total) merchDonation.append('total', data.total);
    dispatch(sendMerchPayment(merchDonation));
  };

  return (
    <div className={styles.pageContainer}>
      <form onSubmit={handleSubmit(onSubmitMerchSubmit)}>
        <label>Merch final Amount</label>
        <input type="number" name="total"/>
        <input type="submit" />
      </form>
    </div>
  );
};

export default DonneTempoPage;
