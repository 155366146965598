/* eslint-disable @typescript-eslint/no-loop-func */
/* eslint-disable no-underscore-dangle */
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import io from 'socket.io-client';
import {fetchDonationsList, removeDonationFromList} from 'src/features/tools/donations';
import $ from 'jquery';
import styles from './DonneTempoRender.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

const baseApiUrl = process.env.REACT_APP_BASE_URL ?? '';

const DonneTempoRender = () => {
  const {streamer} = useParams();
  const [socket, setSocket] = useState<any>();
  const {donationsList} = useAppSelector((s) => s.donations);
  const dispatch = useAppDispatch();
  // const styles = import(`./${idparam.streamer}.module.css`)

  // if (idparam) import styles from `DonneTempoRender.module.css`;
  // ${ idparam }

  useEffect(() => {
    const newSocket = io(baseApiUrl);
    setSocket(newSocket);
  }, []);

  useEffect(() => {
    dispatch(fetchDonationsList());
  }, [dispatch]);

  useEffect(() => {
    if (socket) {
      socket.on(`donationTo${streamer}`, () => {
        dispatch(fetchDonationsList());
      });
    }

    if (socket && streamer === 'main') {
      socket.on('donationTomainstream', () => {
        dispatch(fetchDonationsList());
      });
    }
  }, [dispatch, streamer, socket]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (donationsList.length > 0) {
        for (const donation of donationsList) {
          if (donation.displayed === false && streamer === donation.streamer) {
            dispatch(removeDonationFromList(donation?._id));
            $('.notifs').removeClass('d-none');
            // $('#notifs').show("slide", { direction: "up", duration: 500 });
            $('.notifs').show(500, 'slide');
            $('.notifs-name').html(`${donation.payer_info.first_name} ${donation.payer_info.last_name}`);
            $('.notifs-amount').html(`€${donation.total}`);
            setTimeout(() => {
              $('.notifs').addClass('d-none');
            }, 4500);
            break;
          }
        }
        dispatch(fetchDonationsList());
      }
    }, 5000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [donationsList]);

  return (
    <div className={styles.DTPRenderpageContainer}>
      <div id="notifs" className={['d-none notifs', styles.notifs].join(' ')}>
        <p className={['notifs-name', styles.notifsName].join(' ')} />
        <p className={['notifs-amount', styles.notifsAmount].join(' ')} />
      </div>
    </div>
  );
};

export default DonneTempoRender;
