/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {AlertTypes, createAlert} from '../global/alert';
import {AppThunk} from '../global/helpers';

export interface Palier {
  [palier: number] : {
    amountToRemove: number;
    palier: number;
    amount: number;
    unlocks: string;
  }
}

export interface Donation {
  amount: number;
  date: Date;
}
export interface PostPollDto {
  question: string;
  answers: Answers;
  correctAnswer: string;
}

export interface PostChannelDto {
  channel: string;
}

export interface PostAdminDonationData {
  startingAmount?: number;

  amountRaised?: number;

}

export interface Donator {
  donatorName: string;
  amount: number;
  originChannel: string;
}

export interface PollResult {
  A: number;
  B: number;
  C: number;
  D: number;
}
export interface Answers {
  answerA: string;
  answerB: string;
  answerC: string;
  answerD: string;
}

export interface Poll {
  _id: string;
  channel: string;
  answers: Answers;
  votes: PollResult;
  votesPercentage: PollResult;
  question: string;
  correctAnswer: string;
  alreadyVoted: string[];
  voteFinished: boolean;
}

export interface Channel {
  _id: string;
  channel: string;
  polls: Poll[];
  currentPoll: Poll | null;
  totalAmount: number;
  viewers: number;
}

export interface BasecampState {
  channels: Channel[];
  currentChannel: Channel | undefined;
  pollsList: Poll[];
  activePoll: Poll | undefined;
  totalAmount: number;
  donators: Donator[]
}

const initialState: BasecampState = {
  channels: [],
  currentChannel: undefined,
  activePoll: undefined,
  pollsList: [],
  totalAmount: 0,
  donators: [],
};

const basecampChannels = createSlice({
  name: 'basecampChannels',
  initialState,
  reducers: {
    channelsListLoaded(state, {payload}: PayloadAction<Channel[]>) {
      state.channels = payload;
    },
    channelDetailLoaded(state, {payload}: PayloadAction<Channel>) {
      state.currentChannel = payload;
    },
    pollsListLoaded(state, {payload}: PayloadAction<Poll[]>) {
      state.pollsList = payload;
    },
    activePollLoadedForChannel(state, {payload}: PayloadAction<Poll>) {
      state.activePoll = payload;
    },
  },
});

export default basecampChannels.reducer;

export const {channelsListLoaded, channelDetailLoaded, activePollLoadedForChannel, pollsListLoaded} = basecampChannels.actions;

const baseApiUrl = process.env.REACT_APP_BASE_URL2;

export const fetchChannelsList = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/basecamp/channel/fetch/all`);
    dispatch(channelsListLoaded(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const fetchChannel = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/basecamp/channel/fetch/${id}`);
    dispatch(channelDetailLoaded(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const createChannel = (data: PostChannelDto, history: any): AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/basecamp/channel/add`, data);
    dispatch(createAlert('Channel added!', AlertTypes.success));
    setTimeout(() => history(-1), 1500);
    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  } finally {
    setTimeout(() => {
      dispatch(fetchChannelsList());
    }, 2000);
  }
};

export const updateChannel = (channelId: string, data: any): AppThunk => async (dispatch) => {
  try {
    const response = await axios.patch(`${baseApiUrl}/basecamp/channel/update/${channelId}`, data);
    dispatch(createAlert('Channel updated!', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  } finally {
    setTimeout(() => {
      dispatch(fetchChannelsList());
    }, 2000);
  }
};

export const deleteChannel = (channelId: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.delete(`${baseApiUrl}/basecamp/channel/delete/${channelId}`);
    dispatch(createAlert('Channel deleted!', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  } finally {
    setTimeout(() => {
      dispatch(fetchChannelsList());
    }, 2000);
  }
};

export const fetchPollsList = (channelId: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/basecamp/poll/fetch/all/${channelId}`);
    dispatch(pollsListLoaded(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const fetchPoll = (channelId: string, pollId: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/basecamp/poll/fetch/${channelId}/${pollId}`);
    dispatch(activePollLoadedForChannel(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const fetchActivePollForChannel = (channelId: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/basecamp/poll/fetch/active/${channelId}`);
    dispatch(activePollLoadedForChannel(response.data));
  } catch (err) {
    console.log(err);
  }
};

export const createPoll = (data: PostPollDto, channelId: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/basecamp/poll/add/${channelId}`, data);
    dispatch(createAlert('Question and Answers saved!', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  } finally {
    setTimeout(() => {
      dispatch(fetchPollsList(channelId));
    }, 2000);
  }
};

export const deletePoll = (channelId: string, pollId: string) : AppThunk => async (dispatch) => {
  try {
    const response = await axios.delete(`${baseApiUrl}/basecamp/poll/delete/${channelId}/${pollId}`);
    dispatch(createAlert('Poll successfuly deleted', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  } finally {
    setTimeout(() => {
      dispatch(fetchPollsList(channelId));
    }, 2000);
  }
};

export const renderOverlay = (data: any, channel: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/basecamp/poll/render-overlay/${channel}`, data);
    dispatch(createAlert('Render successful', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  }
};

export const startPoll = (channelId: string, pollId: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/basecamp/poll/start/${channelId}/${pollId}`);
    dispatch(createAlert('Vote starting!', AlertTypes.success));

    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  }
};

export const showResults = (channelId: string, pollId: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/basecamp/poll/show-results/${channelId}/${pollId}`);
    dispatch(createAlert('Showing results', AlertTypes.success));

    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  }
};

export const stopPoll = (channelId: string, channel: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/basecamp/poll/stop/${channelId}/${channel}`);
    dispatch(createAlert('Poll stopped!', AlertTypes.success));

    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  }
};

export const adminGetDonationData = () : AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/basecamp/admin/donation/get-data`);
  } catch (err) {
    console.log(err);
  }
};

export const adminSetDonationData = (data: PostAdminDonationData) : AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/basecamp/admin/donation/set-data`, data);
    dispatch(createAlert('!', AlertTypes.success));

    if (!response) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  }
};
