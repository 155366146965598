/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {io, Socket} from 'socket.io-client';
import backgroundAR from 'src/assets/basecamp/poll/AR.png';
import backgroundAN from 'src/assets/basecamp/poll/AN.png';
import backgroundAW from 'src/assets/basecamp/poll/AW.png';
import backgroundBR from 'src/assets/basecamp/poll/BR.png';
import backgroundBN from 'src/assets/basecamp/poll/BN.png';
import backgroundBW from 'src/assets/basecamp/poll/BW.png';
import backgroundCN from 'src/assets/basecamp/poll/CN.png';
import backgroundCR from 'src/assets/basecamp/poll/CR.png';
import backgroundCW from 'src/assets/basecamp/poll/CW.png';
import backgroundDR from 'src/assets/basecamp/poll/DR.png';
import backgroundDN from 'src/assets/basecamp/poll/DN.png';
import backgroundDW from 'src/assets/basecamp/poll/DW.png';
import {fetchActivePollForChannel, fetchChannel, Poll} from 'src/features/tools/basecamp';
import styles from './PollOverlay.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

interface Params {
  channel: string;
  channelId: string;
}

const basicPoll = {
  _id: '',
  votes: {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
  },
  votesPercentage: {
    A: 0,
    B: 0,
    C: 0,
    D: 0,
  },
  channel: '',
  alreadyVoted: [],
  question: '',
  answers: {
    answerA: '',
    answerB: '',
    answerC: '',
    answerD: '',
  },
  correctAnswer: '',
  voteFinished: true,
};


const voteAnswerKeys = ['A', 'B', 'C', 'D'] as const;

type AnswerId = typeof voteAnswerKeys[number];

type AnswerContainerState = 'neutral' | 'right' | 'wrong';

const BACKGROUND_COLOR: {
  readonly [State in AnswerContainerState]: string;
} = {
  neutral: '#eaf71b',
  right: '#150538',
  wrong: '#8b8b8b',
};

const containerBackgroundImage: {
  readonly [Id in AnswerId]: {
    readonly [State in AnswerContainerState]: string;
  };
} = {
  A: {
    right: backgroundAR,
    neutral: backgroundAN,
    wrong: backgroundAW,
  },
  B: {
    right: backgroundBR,
    neutral: backgroundBN,
    wrong: backgroundBW,
  },
  C: {
    right: backgroundCR,
    neutral: backgroundCN,
    wrong: backgroundCW,
  },
  D: {
    right: backgroundDR,
    neutral: backgroundDN,
    wrong: backgroundDW,
  },
};

const baseURL = process.env.REACT_APP_BASE_URL2 ?? '';

const PollOverlay = () => {
  const dispatch = useAppDispatch();
  const {channel, channelId} = useParams();
  const {activePoll} = useAppSelector((s) => s.basecamp);
  const [actPoll, setActPoll] = useState<Poll | null | ''>();
  const [socket, setSocket] = useState<Socket>();

  const calculatePercentage = (pollToUpdate: Poll) => {
    const {A, B, C, D} = pollToUpdate.votes;
    const total = A + B + C + D;
    const {votesPercentage} = pollToUpdate;
    votesPercentage.A = +((A / total) * 100).toFixed(1);
    votesPercentage.B = +((B / total) * 100).toFixed(1);
    votesPercentage.C = +((C / total) * 100).toFixed(1);
    votesPercentage.D = +((D / total) * 100).toFixed(1);
    setActPoll(pollToUpdate);
  };
  useEffect(() => {
    setActPoll(activePoll);
  }, [activePoll, dispatch]);

  useEffect(() => {
    dispatch(fetchChannel(channelId!));
    setSocket(io(`${baseURL}/basecamp-poll`, {query: {channel}}));
  }, []);

  useEffect(() => {
    if (socket) {
      // Poll Routes
      socket.on('refresh-render', (data: any) => {
        calculatePercentage(data);
      });
      socket.on('render-overlay', (data: Poll) => {
        setActPoll(data);
      });

      socket.on('show-results', (data: Poll) => {
        calculatePercentage(data);
      });

      socket.on('stop-poll', () => {
        setActPoll(basicPoll);
      });
    }
    return () => {
      if (socket?.connected) socket.disconnect();
    };
  }, [socket]);

  useEffect(() => {
    dispatch(fetchActivePollForChannel(channelId!));
  }, [dispatch]);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.questionBackground}>
        <div className={styles.question}>{actPoll && actPoll.question}</div>

        {voteAnswerKeys.map((answerKey) => (
          <div
            key={answerKey}
            className={styles[`answer${answerKey}Container`]}
            style={
              actPoll && actPoll.voteFinished
                ? {
                  background: `url(${
                    actPoll.correctAnswer === answerKey
                      ? containerBackgroundImage[answerKey].right
                      : containerBackgroundImage[answerKey].wrong
                  })`,
                  color:
                      actPoll.correctAnswer === answerKey
                        ? BACKGROUND_COLOR.right
                        : BACKGROUND_COLOR.wrong,
                }
                : {}
            }
          >
            <div className={styles[`answer${answerKey}`]}>
              {actPoll && actPoll.answers[`answer${answerKey}`]}
            </div>
            <div className={styles[`percentage${answerKey}`]}>
              {actPoll && `${actPoll.votesPercentage[answerKey]}%`}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PollOverlay;
