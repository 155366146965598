/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import axios from 'axios';
import {getUser} from 'src/utils/localUser';
import {AlertTypes, createAlert} from '../global/alert';
import {AppThunk} from '../global/helpers';

export type VetoSteps = 0 | 1 | 2;
export type PickSteps = 3 | 4 | 5 | 6 | 7;

export interface StarcraftMaps {
  name: string;
  mapUrl: string;
}

export type Timer = {
  remainingTime: number;
  nationTurn: string;
};

export type Vetoes = {
  order: number;
  nation: string,
  map: string,
};

export interface MatchMaps {
  order: number;
  nation: string,
  map: StarcraftMaps,
  players: { playerA: string, playerB: string },
  winner: string;
  veto: boolean
}

export interface SelectedMap {
  id: string;
  name: string;
  veto: boolean;
  nation: string;
}

export interface NationMatch {
  id: string;
  nations: Nation[];
  maps: MatchMaps[];
  startingNation: string;
  currentStep: number;
  vetoes: Vetoes[];
  timer: Timer;
  background: boolean;
}

export interface Player {
  id: string;
  firstname: string;
  lastname: string;
  nickname: string;
  race: string;
  votes: number;
  pickedByCaptain: boolean;
}

export interface Nation {
  _id: string;
  flag: string;
  title: string;
  players: Player[];
  voters: string[];
}

export interface NationState {
  nationsList: Nation[];
  nationsListNoIp: Nation[];
  nationDetails: Nation | null;
  matchList: NationMatch[];
  matchDetails: NationMatch | null;
}

const initialState: NationState = {
  nationsList: [],
  nationsListNoIp: [],
  nationDetails: null,
  matchList: [],
  matchDetails: null,
};

const nations = createSlice({
  name: 'nations',
  initialState,
  reducers: {
    nationsLoaded(state, {payload}: PayloadAction<Nation[]>) {
      state.nationsList = payload;
    },
    nationDetailsLoaded(state, {payload}: PayloadAction<Nation>) {
      state.nationDetails = payload;
    },
    nationsListNoIpLoaded(state, {payload}: PayloadAction<Nation[]>) {
      state.nationsList = payload;
    },
    nationDetailsUnloaded(state) {
      state.nationDetails = null;
    },
    matchsListLoaded(state, {payload}: PayloadAction<NationMatch[]>) {
      state.matchList = payload;
    },
    matchDetailsLoaded(state, {payload}: PayloadAction<NationMatch>) {
      state.matchDetails = payload;
    },
  },
});

export const {nationsLoaded, nationDetailsLoaded, nationDetailsUnloaded, nationsListNoIpLoaded, matchsListLoaded, matchDetailsLoaded} = nations.actions;

export default nations.reducer;

const baseApiUrl = process.env.REACT_APP_BASE_URL;
let token: string | undefined;
if (getUser()) {
  token = getUser().token;
}


//* NATIONWARRIORS NATIONS

export const loadNationsListNoIp = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/nations/fetch/all`);
    dispatch(nationsListNoIpLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const loadNation = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/nations/fetch/${id}`);
    dispatch(nationDetailsLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const createNation = (nation: any, history: any): AppThunk => async (dispatch) => {
  const config = {headers: {Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.post(
      `${baseApiUrl}/nations/add-nation`,
      nation,
      config,
    );
    dispatch(createAlert('Nation successfully added!', AlertTypes.success));
    setTimeout(() => history(-1), 1500);
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const addPlayer = (player: any, nationId: string): AppThunk => async (dispatch) => {
  const config = {headers: {Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.post(
      `${baseApiUrl}/nations/add-player/${nationId}`,
      player,
      config,
    );
    dispatch(createAlert('Player successfully added!', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const updatePlayer = (nationId: string, playerId: string, player: any): AppThunk => async (dispatch) => {
  const config = {headers: {Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.patch(
      `${baseApiUrl}/nations/update-player/${nationId}/${playerId}`,
      player,
      config,
    );
    dispatch(createAlert('Player successfully updated!', AlertTypes.success));

    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const deletePlayer = (nationId: string, playerId: string): AppThunk => async (dispatch) => {
  const config = {headers: {Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.delete(
      `${baseApiUrl}/nations/delete-player/${nationId}/${playerId}`,
      config,
    );
    dispatch(createAlert('Player successfully deleted!', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const deleteNation = (id: string): AppThunk => async (dispatch) => {
  const config = {headers: {Authorization: `Bearer ${token}`}};

  try {
    const response = await axios.delete(
      `${baseApiUrl}/nations/delete/${id}`,
      config,
    );
    dispatch(createAlert('Nation successfully deleted!', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const updateNation = (id: string, nation: any, history: any): AppThunk => async (dispatch) => {
  const config = {headers: {Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.patch(
      `${baseApiUrl}/nations/update/${id}`,
      nation,
      config,
    );
    dispatch(createAlert('Nation successfully updated!', AlertTypes.success));
    setTimeout(() => history(-1), 1500);
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};


//* NATIONWARRIORS VETOES
// eslint-disable-next-line @typescript-eslint/no-shadow
export const createNewMatch = (nations: string[]) : AppThunk => async (dispatch) => {
  const config = {headers: {Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.post(
      `${baseApiUrl}/nationsmatch/create/new-match`,
      nations,
      config,
    );
    dispatch(createAlert('Match successfully created!', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const loadMatchsList = () : AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/nationsmatch/fetch/all`);
    dispatch(matchsListLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const updateMatch = (id: string, match: any, navigate?: any): AppThunk => async (dispatch) => {
  const config = {headers: {Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.patch(
      `${baseApiUrl}/nationsmatch/update/${id}`,
      match,
      config,
    );
    dispatch(createAlert('Match successfully updated!', AlertTypes.success));
    setTimeout(() => navigate('/tools/nationwars/matches/list'), 1500);
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const deleteMatch = (id: string): AppThunk => async (dispatch) => {
  const config = {headers: {Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.delete(
      `${baseApiUrl}/nationsmatch/delete/${id}`,
      config,
    );
    dispatch(createAlert('Match successfully deleted!', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const loadMatch = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/nationsmatch/fetch/by-id/${id}`);
    dispatch(matchDetailsLoaded(response.data));
  } catch (error) {
    console.log(error);
  }
};

export const startVetoes = (id: string): AppThunk => async (dispatch) => {
  const config = {headers: {Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.patch(
      `${baseApiUrl}/nationsmatch/start-vetoes/${id}`,
      {},
      config,
    );
    dispatch(createAlert('Vetoes successfully started!', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const updateVetoesList = (id: string, vetoes: any): AppThunk => async (dispatch) => {
  const config = {headers: {Authorization: `Bearer ${token}`}};
  try {
    const response = await axios.patch(
      `${baseApiUrl}/nationsmatch/map-vetoes/${id}`,
      vetoes,
      config,
    );
    dispatch(createAlert('Vetoes successfully updated!', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const startTimer = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/nationsmatch/start-timer/${id}`);
    dispatch(createAlert('Vetoes successfully updated!', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};

export const pauseTimer = (id: string): AppThunk => async (dispatch) => {
  try {
    const response = await axios.post(`${baseApiUrl}/nationsmatch/pause-timer/${id}`);
    dispatch(createAlert('Vetoes successfully updated!', AlertTypes.success));
    if (!response) {
      throw new Error();
    }
  } catch (err: any) {
    dispatch(createAlert(err.message, AlertTypes.error));
  }
};
