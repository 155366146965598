import {AxiosResponse} from 'axios';
import {Credentials} from 'src/utils/auth-context/types/user';
import {HttpClient} from './HttpClient';

export class Authentication {
  constructor(private http: HttpClient) {}

  async login(email: string, password: string): Promise<AxiosResponse<Credentials>> {
    const body = {
      user_email: email,
      user_password: password,
    };
    return this.http.post('/api/basic/login', body);
  }

  async checkToken(): Promise<AxiosResponse<Credentials>> {
    return this.http.get('/api/basic/checktoken');
  }
}
