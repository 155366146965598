/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
import axios from 'axios';
import setAuthToken from 'src/utils/setAuthToken';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {setUser, deleteUser, getUser} from 'src/utils/localUser';
import {AlertTypes, createAlert} from './alert';
import {AppThunk} from './helpers';
import {useNavigate} from 'react-router-dom';

interface AuthenticationState {
  token: string | undefined; // Are we actually using state.token?
  isAuthenticated: boolean;
  user: User | null;
}

export interface User {
  token: string | undefined; // Are we actually using state.token?
  googleId: string;
  firstname: string;
  lastname: string;
  role: UserRole;
  email: string;
}

enum UserRole {
  ADMIN = 'admin',
  EDITOR = 'editor',
}

declare const localStorage: any;
const initialState: AuthenticationState = {
  token: localStorage.getItem('token'),
  isAuthenticated: !!localStorage.getItem('token'),
  user: getUser(),
};

function setAuthenticated(state: AuthenticationState) {
  state.isAuthenticated = true;
}
function setNotAuthenticated(state: AuthenticationState) {
  state.isAuthenticated = false;
}
function logOut(state: AuthenticationState) {
  state.token = undefined;
  state.isAuthenticated = false;
  state.user = null;
}

const authentication = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    previousSessionLoaded(state, {payload}: PayloadAction<User>) {
      state.isAuthenticated = true;
      state.user = payload;
      setAuthToken(payload.token);
      setUser(payload);
    },
    userLoaded(state, {payload}: PayloadAction<User>) {
      state.isAuthenticated = true;
      state.user = payload;
      state.token = payload.token;
      setAuthToken(payload.token);
      setUser(payload);
    },
    loginSuccess: setAuthenticated,
    registerSuccess: setAuthenticated,
    loginFailure: setNotAuthenticated,
    registerFailure: setNotAuthenticated,
    authenticationError: logOut,
    logoutSuccess: logOut,
  },
});

export const {
  userLoaded,
  loginSuccess,
  registerSuccess,
  loginFailure,
  registerFailure,
  authenticationError,
  logoutSuccess,
  previousSessionLoaded,
} = authentication.actions;

export default authentication.reducer;

const baseApiUrl = process.env.REACT_APP_BASE_URL;

export const loadUser = (): AppThunk => async (dispatch) => {
  try {
    const response = await axios.get(`${baseApiUrl}/api/basic/checktoken`);
    dispatch(userLoaded(response.data));
  } catch (error) {
    dispatch(authenticationError());
  }
};

export const login = (
  email: string,
  password: string,
): AppThunk => async (dispatch) => {
  const config = {headers: {'Content-Type': 'application/json'}};
  const body = JSON.stringify({
    user_email: email,
    user_password: password,
  });
  try {
    const response = await axios.post(
      `${baseApiUrl}/api/basic/login`,
      body,
      config,
    );
    if (!response || !response.data.auth_token || !response.data.user) {
      throw new Error();
    }
    dispatch(loginSuccess());
    dispatch(userLoaded(response.data));
    dispatch(
      createAlert(`Welcome back, ${response.data.user.user_name}`, AlertTypes.success),
    );
  } catch (error: any) {
    dispatch(loginFailure());
    if (error.response) {
      if (Array.isArray(error.response.data.message)) {
        error.response.data.message.forEach((e: any) => {
          dispatch(createAlert(e.constraints.isUniqueProperty, AlertTypes.error));
        });
      } else {
        dispatch(createAlert(error.response.data.message, AlertTypes.error));
      }
    } else {
      dispatch(createAlert('Something went wrong', AlertTypes.error));
    }
  }
};

export const logout = (): AppThunk => (dispatch) => {
  setAuthToken(undefined);
  deleteUser();
  dispatch(logoutSuccess());
  dispatch(createAlert('You have been successfully logged out!', AlertTypes.success));
};
