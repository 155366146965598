/* eslint-disable no-underscore-dangle */
import Icon from 'awesome-react-icons';
import {useEffect, useState} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {deleteTalent, loadTalentsList, Talent, talentDetailsUnloaded} from 'src/features/website/talents';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';
import {formDateDay} from 'src/utils/momentDateFormat';
import styles from './TalentsList.module.css';

const TalentsList = () => {
  const dispatch = useAppDispatch();
  const {talentsList} = useAppSelector((s) => s.talents);
  const navigate = useNavigate();
  const [openDeleteModal, setDeleteOpenModal] = useState(false);
  const [currentTalent, setCurrentTalent] = useState<Talent>();

  useEffect(() => {
    dispatch(loadTalentsList());
    dispatch(talentDetailsUnloaded(null));
  }, [dispatch]);

  const handleDeleteClick = () => {
    if (currentTalent?.id) {
      dispatch(deleteTalent(currentTalent?.id));
      setDeleteOpenModal(false);
    }
  };
  return (
    <div className={styles.membersListContainer}>
      <Modal show={openDeleteModal} onHide={() => setDeleteOpenModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Talent</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {`${currentTalent?.nickname}`} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteOpenModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleDeleteClick()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <div className={styles.title}>Talents List</div>
      <div className={styles.tableContainer}>
        <table className="table table-dark">
          <thead>
            <tr>
              <th scope="col">Description</th>
              <th scope="col">Firstname - Nickname - Lastname</th>
              <th scope="col">Photo</th>
              <th scope="col">Nationality</th>
              <th scope="col">Game</th>
              <th scope="col">Team Name</th>
              <th scope="col">Member Since</th>
              <th scope="col">Update</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {talentsList && talentsList.map((talent: Talent) => (
              <tr key={talent.id}>
                <td>{`${talent?.description?.substring(0, 50)}...`}</td>
                <td>{`${talent?.firstname} - ${talent?.nickname} - ${talent?.lastname}`}</td>

                <td>
                  <img
                    src={talent?.photo}
                    alt={`${talent?.title} profile`}
                    className={styles.photo}
                  />
                </td>
                <td>{talent?.nationality}</td>
                <td>{talent?.game}</td>
                <td>{talent?.teamName}</td>
                <td>{formDateDay(talent?.member_since)}</td>
                <td>
                  <div
                    className={styles.cursorPointer}
                    onClick={() => {
                      navigate(`/website/talents/update/${talent.id}`);
                    }}
                  >
                    <Icon name="activity" />
                  </div>
                </td>
                <td>
                  <button
                    className="deleteButton"
                    onClick={() => {
                      setDeleteOpenModal(true);
                      setCurrentTalent(talent);
                    }}
                  >
                    <Icon name="trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>

  );
};

export default TalentsList;
