/* eslint-disable class-methods-use-this */
import {useMemo} from 'react';
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {Authentication} from './authentication';

const baseApiUrl = process.env.REACT_APP_BASE_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';

export interface HttpProviders {
  authentication: Authentication;
}

export class HttpClient {
  static setToken(token: string) {
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
  }

  private cleanUrl(url: string) {
    if (url.startsWith('/')) { return url.substring(1); }
    return url;
  }

  public get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return axios.get(`${baseApiUrl}/${this.cleanUrl(url)}`, config);
  }

  public post<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return axios.post(`${baseApiUrl}/${this.cleanUrl(url)}`, data, config);
  }

  public deleteCall<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return axios.delete(`${baseApiUrl}/${this.cleanUrl(url)}`, config);
  }

  public put<T>(url: string, data?: any, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return axios.put(`${baseApiUrl}/${this.cleanUrl(url)}`, data, config);
  }
}

export function useHttp(): HttpProviders {
  return useMemo<HttpProviders>(() => {
    const http = new HttpClient();
    const authentication = new Authentication(http);
    return {
      authentication,
    };
  }, []);
}
