/* eslint-disable @typescript-eslint/ban-ts-comment */
import {useEffect, useState} from 'react';
import {ReactSortable} from 'react-sortablejs';
import {AlertTypes, createAlert} from 'src/features/global/alert';
import {loadPartnersList, Partner, updatePartnerRank} from 'src/features/website/partners';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

import styles from './PartnerOrder.module.css';

const PartnerOrder = () => {
  const dispatch = useAppDispatch();
  const {partnersList} = useAppSelector((s) => s.partners);
  const [rankList, setRankList] = useState<Partner[]>(partnersList);
  const displayedEvents = 20;

  useEffect(() => {
    setRankList(partnersList.slice().sort((a, b) => a.displayRank - b.displayRank));
  }, [partnersList]);

  useEffect(() => {
    dispatch(loadPartnersList());
  }, [dispatch]);

  const updateClientsOrder = (id: string, newDisplayOrder: number) => {
    const updatedEvent = {
      displayRank: newDisplayOrder,
    };
    dispatch(updatePartnerRank(id, updatedEvent));
  };

  const handleClientOrderSubmit = () => {
    for (let index = 0; index < rankList.length; index++) {
      const event = rankList[index];
      if (index + 1 !== event.displayRank && index < displayedEvents) {
        updateClientsOrder(event.id, index + 1);
      }
    }
    dispatch(createAlert('Partners Order successfully updated!', AlertTypes.success));
  };

  return (
    <div className="container">
      <div className={`${styles.title} mb-3`}>Order Partners</div>
      <div className={`${styles.description} mb-3`}>
        Order partners from 1 to {displayedEvents}, any partner {'>='} {displayedEvents} will not be displayed
      </div>
      {/*//@ts-ignore */}
      <ReactSortable tag="div" list={rankList} setList={setRankList} className="row">
        {!!rankList && rankList.map((partner, index) => (
          <div
            key={partner.displayRank}
            className={
              `${index < displayedEvents ? styles.elementDisplayed : styles.elementNotDisplayed}
              col-3
              ${styles.cardContainer}`}
          >
            <img
              src={partner?.imageUrl}
              alt={`${partner?.title} profile`}
              className={styles.photo}
            />
          </div>
        ))}
      </ReactSortable>
      <input type="submit" value="Update List Order" onClick={handleClientOrderSubmit} />

    </div>
  );
};

export default PartnerOrder;
