/* eslint-disable react/jsx-props-no-spreading */
import {yupResolver} from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
import Select from 'react-select';
import {loadNationsListNoIp, Player, updatePlayer} from 'src/features/tools/nationwars';
import {useAppDispatch} from 'src/hooks/hooks';
import {raceSelectOptions} from 'src/utils/nationwarsHelpers';
import * as yup from 'yup';
import styles from './UpdatePlayer.module.css';

interface IFormInputs {
  firstname: string;
  lastname: string;
  nickname: string;
  pickedByCaptain: boolean;
  race: { label: string; value: string };
}

interface AddPlayerProps {
  setUpdateModal: (value: boolean) => void;
  nationId: string;
  player: Player;
}
const SignupSchema = yup.object().shape({
  firstname: yup.string().min(2, 'Firstname must be at least 2 characters'),
  lastname: yup.string().min(2, 'Lastname must be at least 2 characters'),
  nickname: yup.string().min(2, 'Nickname must be at least 6 characters'),
  pickedByCaptain: yup.boolean(),
});

const UpdatePlayer = ({nationId, player, setUpdateModal}: AddPlayerProps) => {
  const dispatch = useAppDispatch();
  const {register, handleSubmit, control, formState: {errors, isValid}} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });

  const onSubmitPlayerUpdate = async (data: IFormInputs) => {
    const {id, votes, ...rest} = {...player};
    const updatedPlayer = {...rest};
    if (data.firstname) updatedPlayer.firstname = data.firstname;
    if (data.lastname) updatedPlayer.lastname = data.lastname;
    if (data.nickname) updatedPlayer.nickname = data.nickname;
    if (data.pickedByCaptain) updatedPlayer.pickedByCaptain = data.pickedByCaptain;
    if (data.race) updatedPlayer.race = data.race.value;
    dispatch(updatePlayer(nationId, player.id, updatedPlayer));
    setUpdateModal(false);
    dispatch(loadNationsListNoIp());
  };

  const defaultValueRace = raceSelectOptions.find((option) => option.value === player.race);
  return (
    <div>
      <div className={styles.title}>Update Player</div>
      <form onSubmit={handleSubmit(onSubmitPlayerUpdate)}>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>First Name</label>
            <input {...register('firstname')} defaultValue={player.firstname}/>
            {errors.firstname && <p>{errors.firstname.message}</p>}

          </div>
          <div className="w-50 pl-3">
            <label>Last Name</label>
            <input {...register('lastname')} defaultValue={player.lastname} />
            <p>{errors.lastname?.message}</p>
          </div>
        </div>
        <div className="d-flex">
          <div className="w-100 pr-3">
            <label>Nickname</label>
            <input {...register('nickname')} defaultValue={player.nickname}/>
            <p>{errors.nickname?.message}</p>
          </div>
        </div>
        <div className="d-flex">
          <div className="w-100 pr-3">
            <label>Picked By Captain</label>
             <input type="checkbox" placeholder="pickedByCaptain" {...register('pickedByCaptain')} defaultChecked={player.pickedByCaptain}/>
            <p>{errors.pickedByCaptain?.message}</p>
          </div>
        </div>
        <div className={styles.select}>
          <label>Race</label>
          <Controller
            name="race"
            control={control}
            defaultValue={defaultValueRace}
            render={({field}) => <Select
              {...field}
              options={raceSelectOptions}
            />}
          />
        </div>

        <input type="submit" value="Publish" disabled={!isValid} />
      </form>
    </div>
  );
};

export default UpdatePlayer;
