
import {useEffect, useState} from 'react';
import * as yup from 'yup';
import {useNavigate} from 'react-router-dom';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {Controller, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {createPartner} from 'src/features/website/partners';
import {loadEventsList} from 'src/features/website/events';
import ImagePicker from 'src/components/Global/ImagePicker';
import {createOptions} from 'src/utils/selectOptions';
import {AlertTypes, createAlert} from 'src/features/global/alert';
import BackButton from 'src/components/Global/BackButton';
import styles from './PartnerCreate.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

interface PartnerLogo {
  image: File | undefined;
}

interface IFormInputs {
  title: string;
  description: string;
  date: string;
  events: { label: string; value: string }[];
  clientType: string;
}

const SignupSchema = yup.object().shape({
  title: yup.string().min(4, 'Title must be at least 4 characters'),
  date: yup.date().required('Date must not be empty'),
  description: yup.string().min(4, 'Description must be at least 4 characters'),
  clientType: yup.string().min(4, 'Client Type must be at least 4 characters'),
});

interface PartnerCreateProps {
  external?: boolean;
  setCreatePartner?: (value: boolean) => void;
}

const PartnerCreate = ({external, setCreatePartner}: PartnerCreateProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {eventsList} = useAppSelector((s) => s.events);
  const {register, handleSubmit, control, formState: {errors, isValid}} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });
  const [partnerLogo, setPartnerLogo] = useState<PartnerLogo>({
    image: undefined,
  });

  useEffect(() => {
    dispatch(loadEventsList());
  }, [dispatch]);

  const onFileChange = (file: File | undefined) => {
    setPartnerLogo({image: file});
  };

  const onSubmitPartnerCreate = (data: IFormInputs) => {
    if (!partnerLogo.image) {
      dispatch(createAlert('A logo must be picked', AlertTypes.warning));
      return;
    }

    const createdPartner = new FormData();
    if (data.events) data.events.forEach((event) => createdPartner.append('events[]', event.value));
    if (data.description) createdPartner.append('description', data.description);
    if (data.clientType) createdPartner.append('clientType', data.clientType);
    if (partnerLogo.image) createdPartner.append('image', partnerLogo.image);
    if (data.title) createdPartner.append('title', data.title);
    if (data.date) createdPartner.append('date', data.date);
    dispatch(createPartner(createdPartner, setCreatePartner, !external && navigate));
  };

  const selectOptions = createOptions(eventsList);

  return (
    <div>
      <div className={styles.title}>Create Partner</div>
      {!external && <BackButton />}
      <form onSubmit={handleSubmit(onSubmitPartnerCreate)}>
        <div className="d-flex">
          <div className="w-50 pr-3">
            <label>Title</label>
            <input {...register('title')} />
            {errors.title && <p>{errors.title.message}</p>}
          </div>
          <div className="w-50 pl-3">
            <label>Date</label>
            <Controller
              control={control}
              name="date"
              render={({field}) => (
                <DatePicker
                  placeholderText="Select date"
                  onChange={(date: any) => field.onChange(date)}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  selected={field.value}
                />
              )}
            />
            <p>{errors.date?.message}</p>
          </div>
        </div>

        <label>Description</label>
        <input {...register('description')} />
        <p>{errors.description?.message}</p>

        <label>Events List</label>
        <Controller
          name="events"
          control={control}
          render={({field}) => <Select
            {...field}
            isMulti
            options={selectOptions}
          />}
        />

        <label>Client Type</label>
        <input {...register('clientType')} />
        <p>{errors.clientType?.message}</p>

        <label>Pick Logo</label>
        <div className={styles.imagePickerContainer}>
          <ImagePicker
            labelText="Pick a logo"
            fileHandler={onFileChange}
            errorText=""
            previewHeight={200}
            previewWidth={200}
            heightRatio={130}
            widthRatio={215}
          />
        </div>
        <input type="submit" value="Publish" disabled={!isValid} />
      </form>
    </div>
  );
};

export default PartnerCreate;
