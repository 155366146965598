/* eslint-disable react/jsx-props-no-spreading */
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import * as yup from 'yup';
import {createNation, loadNationsListNoIp} from 'src/features/tools/nationwars';
import styles from './CreateNation.module.css';
import {useAppDispatch} from 'src/hooks/hooks';

interface IFormInputs {
  title: string;
  flag: string;
}

const SignupSchema = yup.object().shape({
  title: yup.string().min(2, 'Title must be at least 2 characters'),
  flag: yup.string().min(2, 'Flag must be 2 characters long').max(2, 'Flag must be 2 characters long').uppercase('Flag must be uppercase'),
});

const CreateNation = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {register, handleSubmit, formState: {errors, isDirty, isValid}} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });
  const onSubmitNationUpdate = async (data: IFormInputs) => {
    const newNation = {
      title: data.title,
      flag: data.flag,
    };
    dispatch(createNation(newNation, navigate));
    dispatch(loadNationsListNoIp());
  };
  return (
    <div className={styles.pageContainer}>

      <div className={styles.title}>Add Nation</div>
      <form onSubmit={handleSubmit(onSubmitNationUpdate)}>
        <div>
          <label>Country Name</label>
          <input
            {...register('title')}
          />
          {errors.title && <p>{errors.title.message}</p>}

        </div>
        <div >
          <div>
            <a href="https://www.iban.com/country-codes" target="_blank" rel="noreferrer" >Country Code List</a>
          </div>
          <label>For Russia, just treat it as any other country. The flag will be updated automatically</label>
          <br />
          <label>Country Code</label>
          <input {...register('flag')} />
          <p>{errors.flag?.message}</p>
        </div>
        <input type="submit" value="Create" disabled={!isDirty} />
      </form>
    </div>
  );
};

export default CreateNation;
