import './App.css';
import {Route, Routes, useNavigate} from 'react-router-dom';
import GoogleCallback from 'src/pages/GoogleCallback';
import {Button} from 'react-bootstrap';
import {logout} from 'src/features/global/authentication';
import RedirectLogin from 'src/components/RedirectLogin';
import CustomAlert from 'src/components/Global/Alert/Alert';
import HomePage from 'src/pages/HomePage';
import WebSiteSection from 'src/pages/WebsiteSection';
import ToolsSection from 'src/pages/ToolsSection';
import PrivateRoute from 'src/components/Routes/PrivateRoute';
import ProtectedRoute from 'src/components/Routes/ProtectedRoute';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';
import {useEffect} from 'react';

function App() {
  const {user, isAuthenticated} = useAppSelector((s) => s.authentication);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const logOutUser = () => {
    dispatch(logout());
    navigate('/');
  };
  const inRender = window.location.href.includes('render');

  // useEffect(() =>  {
  //   if (isAuthenticated && !inRender) navigate('/home');
  // }, [isAuthenticated]);


  return (
    <div className="App">
      {(isAuthenticated && !inRender) && (
        <div className="userContainer d-flex">
          <div className="userName">{`${user?.firstname} ${user?.lastname}`}</div>
          <Button
            onClick={() => logOutUser()}
          >
            Logout
          </Button>
        </div>
      )}
      <CustomAlert />
      <Routes>
        <Route path="/" element={<RedirectLogin />} />
        <Route path="/home" element={<HomePage />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/website/*" element={<WebSiteSection />} />
          <Route path="/tools/*" element={<ToolsSection />} />
        </Route>
        <Route path="/login/success/:token" element={<GoogleCallback />} />
      </Routes>

    </div>
  );
}
export default App;
