import {AlertStatus, AlertTypes, removeAlert} from 'src/features/global/alert';
import {RootState} from 'src/app/rootReducer';
import Toast from 'react-bootstrap/Toast';
import Alert from 'react-bootstrap/Alert';
import {ReactComponent as ErrorIcon} from 'src/assets/alerts/error.svg';
import {ReactComponent as SuccessIcon} from 'src/assets/alerts/success.svg';
import {ReactComponent as WarningIcon} from 'src/assets/alerts/warning.svg';
import usePortal from 'react-useportal';
import styles from './Alert.module.css';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

const CustomAlert = (): JSX.Element => {
  const {Portal} = usePortal();
  const dispatch = useAppDispatch();
  const alertState = useAppSelector((state: RootState) => state.alert);
  let alerts = null;
  if (alertState !== null) {
    alerts = Object.values(alertState) as AlertStatus[];
  }

  const handleClose = (id: string) => {
    dispatch(removeAlert(id));
  };

  const renderIcon = (type: 'success' | 'danger' | 'warning') => {
    switch (type) {
      case AlertTypes.error:
        return <ErrorIcon />;
      case AlertTypes.success:
        return <SuccessIcon />;
      case AlertTypes.warning:
        return <WarningIcon />;
      default:
        return '';
        break;
    }
  };

  return (
    <Portal>
      <div className={styles.alertContainer}>
        {alerts !== null
          && alerts.map((alert) => (
            <Alert variant={alert.alertType} key={alert.id} className={styles.alertElement}>
              <Toast onClose={() => handleClose(alert.id)} key={alert.id}>
                <Toast.Body>
                  <div className="d-flex justify-content-between align-items-center">
                    <span>{alert.message}</span>
                    {renderIcon(alert.alertType)}
                  </div>
                </Toast.Body>
              </Toast>
            </Alert>
          ))}
      </div>
    </Portal>
  );
};

export default CustomAlert;
