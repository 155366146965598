/* eslint-disable max-len */
import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {loadMatchsList, loadNationsListNoIp, NationMatch, PickSteps, StarcraftMaps, VetoSteps} from 'src/features/tools/nationwars';
import {VETOSTEPS} from 'src/utils/nationwarsHelpers';
import MatchContext from '../NationsWarsMatchContext';
import NWMap from '../NWMap';
import styles from './NationsMatchesMapsList.module.css';

interface NationsMatchesMapsListProps {
  solo: boolean;
}


const NationsMatchesMapsList = ({solo}:NationsMatchesMapsListProps) => {
  const {id, nation} = useParams();
  const {handleMapSelection, VS1Maps, VS2Maps, currentMatch} = useContext(MatchContext);
  const [soloTurn, setSoloTurn] = useState<boolean>(true);
  const {timer} = currentMatch!;
  const {nationTurn} = timer!;
  const currentStep = currentMatch!.currentStep;

  const handleSoloTurnDisplay = () => {
    if (currentStep === 8 || currentStep === 9 || currentStep === 10 || currentStep === 11) setSoloTurn(false);
    if (currentStep === 1 || currentStep === 2 || currentStep === 3 || currentStep === 4 || currentStep === 5 || currentStep === 6) setSoloTurn(true);
  };

  useEffect(() => {
    handleSoloTurnDisplay();
  }, [currentStep]);


  const selectOrVetoMap = (map: StarcraftMaps, isSolo: boolean) => {
    if ((isSolo && !soloTurn) || (!isSolo && soloTurn)) return;
    if (currentStep === 0) return;
    if (nationTurn !== nation) {
      console.log('not your turn');
      return;
    }
    handleMapSelection(map, solo);
  };

  return (
    <div className={styles.container}>
      <div className='d-flex'>
        {solo ? VS1Maps!.map((map, index) => (
          <div onClick={() => selectOrVetoMap(map, true) } key={map.name} style={ currentStep === 12 ? {opacity: 1, cursor: 'disabled'} : soloTurn ? {opacity : 1} : {opacity : 0.2, cursor: 'disabled'} }>
            <NWMap mapUrl={map.mapUrl} name={map.name} key={map.name}/>
          </div>
        )) : VS2Maps!.map((map, index) => (
          <div onClick={() => selectOrVetoMap(map, false)} key={map.name} style={ currentStep === 12 ? {opacity: 1, cursor: 'disabled'} : soloTurn ? {opacity : 0.2, cursor: 'disabled'} : {opacity : 1}} className={styles.marginTop}>
            <NWMap mapUrl={map.mapUrl} name={map.name}/>
          </div>
        ))}

      </div>
    </div>
  );
};
export default NationsMatchesMapsList;
