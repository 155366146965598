import {Route, Routes, useLocation} from 'react-router-dom';
import NavbarTools from 'src/components/Navbars/NavbarTools';
import NationUpdate from './NationWars/Nations/NationUpdate';
import NationsList from './NationWars/Nations/NationsList';
import DonneTempoPage from './Donations/DonneTempo/DonneTempoPage';
import DonneTempoRender from './Donations/DonneTempo/DonneTempoRender';
import TwitchAnalytics from './TwitchAnalytics';
import VotesPage from './Backseat/Votes/VotesPage';
import VotesRender from './Backseat/Votes/VotesRender';
import CreateNation from './NationWars/Nations/CreateNation';
import WordCloudList from './Backseat/WordCloud/WordCloudList';
import WordCloudGenerate from './Backseat/WordCloud/WordCloudGenerate';
import ChannelsList from './BasecampLFI/Channels/ChannelsList';
import PollOverlay from './BasecampLFI/Polls/PollOverlay';
import PollDashboard from './BasecampLFI/Polls/PollDashboard';
import DonationOverlay from './BasecampLFI/DonationOverlay';
import CreateChannel from './BasecampLFI/Channels/CreateChannel';
import AdminPage from './BasecampLFI/Admin/AdminPage';
import LightEffectsDashboard from './LightEffects/LightEffectsDashboard';
import NationsMatchesDashboard from './NationWars/NationsMatches/NationsMatchesDashboard';
import NationsMatchesCreate from './NationWars/NationsMatches/NationsMatchesCreate';
import NationsMatchesRender from './NationWars/NationsMatches/NationsMatchesRender';
import NationsMatchesMapsList from './NationWars/NationsMatches/NationsMatchesMapsList';
import NationsMatchesList from './NationWars/NationsMatches/NationsMatchesList';

const ToolsSection = () => {
  const location = useLocation();

  const inRender = location.pathname.includes('render');
  const inCloudGenerator = location.pathname.includes('generate');
  const inBasecampPoll = location.pathname.includes('poll');

  return (
    <div>

      {(!inRender && !inCloudGenerator && !inBasecampPoll) && <NavbarTools />}

      <Routes>
        {/* Donne Tempo */}
        <Route path="/donations/donnetempo" element={<DonneTempoPage />} />
        <Route path="/donations/donnetempo/render/:streamer" element={<DonneTempoRender />} />

        {/* Votes */}
        <Route path="/votes/QCM" element={<VotesPage/>} />
        <Route path="/votes/QCM/render" element={<VotesRender/>} />

        {/* Live Shopping */}
        {/* <Route path="/shopping/cart/list" element={<CartsList />} />
        <Route path="/shopping/cart/create" element={<CreateCart />} />
        <Route path="/shopping/cart/:id" element={<CartDetails />} /> */}
        {/* <Route path="/shopping/products" element={<InsertProduct cartId="test" setAddProduct={(() => null)}/>} /> */}
        {/* <Route path="/shopping/render" element={<LiveShoppingRender />} /> */}

        {/* Twitch Analytics */}
        <Route path="/analytics" element={<TwitchAnalytics />} />

        {/* Nation Wars */}
        <Route path="/nationwars/nations/list" element={<NationsList />} />
        <Route path="/nationwars/nations/create" element={<CreateNation />} />
        <Route path="/nationwars/nations/update/:id" element={<NationUpdate />} />

        {/* Nation Wars Vetoes */}

        <Route path="/nationwars/matches/list" element={<NationsMatchesList />} />
        <Route path="/nationwars/matches/dashboard/:id" element={<NationsMatchesDashboard />} />
        <Route path="/nationwars/matches/create" element={<NationsMatchesCreate />} />
        <Route path="/nationwars/matches/render/:id/:nation" element={<NationsMatchesRender />} />
        <Route path="/nationwars/matches/render/:id" element={<NationsMatchesRender />} />


        {/* Word Cloud */}
        <Route path="/wordcloud" element={<WordCloudList />} />
        <Route path="/wordcloud/generate/:id" element={<WordCloudGenerate />} />

        {/* Basecamp LFI */}
        <Route path="/basecamp/admin" element={<AdminPage />} />
        <Route path="/basecamp/list" element={<ChannelsList />} />
        <Route path="/basecamp/create" element={<CreateChannel />} />
        <Route path="/basecamp/donations/render" element={<DonationOverlay/>} />
        <Route path="/basecamp/polls/dashboard/:channelId/:channel" element={<PollDashboard/>} />
        <Route path="/basecamp/polls/render/:channelId/:channel" element={<PollOverlay/>} />

        {/* Light Effects */}
        <Route path="/lighteffects/dashboard" element={<LightEffectsDashboard/>} />

      </Routes>
    </div>
  );
};

export default ToolsSection;
