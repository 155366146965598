import * as React from 'react';
import {User} from './types/user';

export interface AuthContextType {
  authenticated: boolean;
  user: User | null;
  login: (user: User, token: string) => void;
  logout: () => void;
  token: string | null
}

export const createAuthContext = (): React.Context<AuthContextType> => (
  React.createContext<AuthContextType>({
    authenticated: false,
    user: null,
    login: () => {},
    logout: () => {},
    token: null,
  })
);
