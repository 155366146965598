/* eslint-disable no-console */
import {useCallback, useEffect, useState} from 'react';

import * as React from 'react';
import {HttpClient, useHttp} from '../http/hooks/HttpClient';
import {createAuthContext} from './AuthContext';
import {User} from './types/user';

interface AuthContextProps {
  children: React.ReactNode | React.ReactNode[];
}

declare const localStorage: Storage;

export const AuthContext = createAuthContext();

const AuthContextProvider = ({children}: AuthContextProps) => {
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const http = useHttp();

  const removeUser = useCallback(() => {
    localStorage.removeItem('user');
  }, []);

  const removeToken = useCallback(() => {
    localStorage.removeItem('auth_token');
  }, []);

  const saveToken = useCallback((t: string) => {
    localStorage.setItem('auth_token', t);
  }, []);

  const saveUser = useCallback((u: User) => {
    localStorage.setItem('user', JSON.stringify(u));
  }, []);

  const readUser = useCallback(() => {
    const content = localStorage.getItem('user');
    if (!content) {
      return null;
    }
    return JSON.parse(content) as User;
  }, []);

  const readToken = useCallback(() => localStorage.getItem('auth_token'), []);

  const login = useCallback(
    (u: User, t: string, isAuthenticated = true) => {
      setUser(u);
      saveUser(u);
      setAuthenticated(isAuthenticated);
      setToken(t);
      saveToken(t);
    },
    [saveToken, saveUser],
  );

  const logout = useCallback(() => {
    setAuthenticated(false);
    setUser(null);
    removeUser();
    setToken(null);
    removeToken();
  }, [removeToken, removeUser]);

  const checkToken = useCallback(
    async (t: string): Promise<void> => {
      if (!t) {
        return;
      }
      HttpClient.setToken(t);

      try {
        const credentials = (await http.authentication.checkToken()).data;
        if (!credentials.auth_token || !credentials.user) {
          throw new Error('invalid_credentials');
        }

        login(credentials.user, credentials.auth_token);
      } catch (error) {
        console.log(error);
        throw error;
      }
    },
    [http, login],
  );

  useEffect(() => {
    const t = readToken();
    const u = readUser();

    if (!u || !t) {
      return;
    }
    login(u, t, false);
    // checkToken(t);
  }, [checkToken, login, readToken, readUser]);

  return (
    <AuthContext.Provider value={{authenticated, login, logout, token, user}}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
