import {useEffect, useState} from 'react';
import Icon from 'awesome-react-icons';
import {Button, Modal} from 'react-bootstrap';
import {Channel, deleteChannel, fetchChannelsList} from 'src/features/tools/basecamp';
import styles from './ChannelsList.module.css';
import UpdateChannel from '../UpdateChannel';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';

const ChannelsList = () => {
  const dispatch = useAppDispatch();
  const {channels} = useAppSelector((s) => s.basecamp);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [currentChannel, setCurrentChannel] = useState<Channel>();

  useEffect(() => {
    dispatch(fetchChannelsList());
  }, [dispatch]);

  const handleDeleteClick = () => {
    if (currentChannel?._id) {
      dispatch(deleteChannel(currentChannel?._id));
      setOpenDeleteModal(false);
    }
  };

  const renderDeleteModal = () => (
    <Modal show={openDeleteModal} onHide={() => setOpenDeleteModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Channel</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete{' '}
        {`${currentChannel?.channel}`} ?
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => handleDeleteClick()}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const renderUpdateModal = () => (
    <Modal show={openUpdateModal} onHide={() => setOpenUpdateModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Update Player</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {currentChannel && <UpdateChannel channel={currentChannel} />}
      </Modal.Body>
    </Modal>
  );

  return (
    <div className={styles.pageContainer}>
      {renderDeleteModal()}
      {renderUpdateModal()}
      <div className={styles.title}>Streamers List</div>
      <div className={styles.tableContainer}>
        <table className="table table-dark">
          <thead>
            <tr>
              <th scope="col">Channel</th>
              <th scope="col">Donation Overlay</th>
              <th scope="col">Polls Dashboard</th>
              <th scope="col">Update</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {!!channels.length && channels.map((channel: Channel) => (
              <tr key={channel?._id}>
                <td>{channel?.channel}</td>
                <td>
                  <div className={styles.icon}>
                    <a
                      href="/tools/basecamp/donations/render"
                      target="_blank"
                      rel="noreferrer"
                      className={styles.link}>
                        <Icon name="briefcase" />
                    </a>
                  </div>
                </td>
                <td>
                  <div className={styles.icon}>
                    <a
                      href={`/tools/basecamp/polls/dashboard/${channel._id}/${channel.channel}`}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.link}>
                        <Icon name="eye" />
                    </a>
                  </div>
                </td>

                <td>
                  <button
                    className="deleteButton"
                    onClick={() => {
                      setOpenUpdateModal(true);
                      setCurrentChannel(channel);
                    }}
                  >
                    <Icon name="activity" />
                  </button>
                </td>
                <td>
                  <button
                    className="deleteButton"
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setCurrentChannel(channel);
                    }}
                  >
                    <Icon name="trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ChannelsList;
