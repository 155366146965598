/* eslint-disable @typescript-eslint/ban-ts-comment */
import {useEffect, useState} from 'react';
import {ReactSortable} from 'react-sortablejs';
import {AlertTypes, createAlert} from 'src/features/global/alert';
import {loadEventsList, Event, updateEventRank} from 'src/features/website/events';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';
import {formDateDay} from 'src/utils/momentDateFormat';
import styles from './RealisationOrder.module.css';

const RealisationOrder = () => {
  const dispatch = useAppDispatch();
  const {eventsList} = useAppSelector((s) => s.events);
  const [rankList, setRankList] = useState<Event[]>(eventsList);
  const displayedEvents = 20;

  useEffect(() => {
    dispatch(loadEventsList());
  }, [dispatch]);

  useEffect(() => {
    setRankList(eventsList.slice().sort((a, b) => a.displayRank - b.displayRank));
  }, [eventsList]);

  const updateEventsOrder = (id: string, newDisplayOrder: number) => {
    const updatedEvent = {
      displayRank: newDisplayOrder,
    };
    dispatch(updateEventRank(id, updatedEvent));
  };

  const handleEventOrderSubmit = () => {
    for (let index = 0; index < rankList.length; index++) {
      const event = rankList[index];
      if (index + 1 !== event.displayRank && index < displayedEvents) {
        updateEventsOrder(event.id, index + 1);
      }
    }
    dispatch(createAlert('Events Order successfully updated!', AlertTypes.success));
  };
  return (
    <div>
      <div className={`${styles.title} mb-3`}>Order Realisations</div>
      <div className={`${styles.description} mb-3`}>
        Order events from 1 to {displayedEvents}, any events {'>='} {displayedEvents} will not be displayed
      </div>
      <table className="table table-dark">
        <thead>
          <tr>
            <th scope="col">Display Order</th>
            <th scope="col">Title</th>
            <th scope="col">Start Date</th>
            <th scope="col">End Date</th>
            <th scope="col">Thumbnail</th>
          </tr>
        </thead>
        {/*//@ts-ignore */}
        <ReactSortable tag="tbody" list={rankList} setList={setRankList}>
          {!!rankList && rankList.map((event, index) => (
            <tr key={event.displayRank} className={index < displayedEvents ? styles.elementDisplayed : styles.elementNotDisplayed}>
              <td>{event?.displayRank}</td>
              <td>{event?.title}</td>
              <td>{formDateDay(event?.start_date)}</td>
              <td>{formDateDay(event?.end_date)}</td>
              <td>
                <img
                  src={event?.thumbnail}
                  alt={`${event?.title} profile`}
                  className={styles.photo}
                />
              </td>
            </tr>
          ))}
        </ReactSortable>
      </table>
      <input type="submit" value="Update List Order" onClick={handleEventOrderSubmit} />

    </div>
  );
};

export default RealisationOrder;
