/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-underscore-dangle */
import {useEffect, useState} from 'react';
import {ReactSortable} from 'react-sortablejs';
import {AlertTypes, createAlert} from 'src/features/global/alert';
import {Article, loadArticlesList, updateArticleRank} from 'src/features/website/articles';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';
import styles from './ArticleOrder.module.css';

const ArticleOrder = () => {
  const dispatch = useAppDispatch();
  const {articlesList} = useAppSelector((s) => s.articles);
  const [rankList, setRankList] = useState<Article[]>(articlesList);
  const displayedEvents = 3;

  useEffect(() => {
    setRankList(articlesList.slice().sort((a, b) => a.displayRank - b.displayRank));
  }, [articlesList]);

  useEffect(() => {
    dispatch(loadArticlesList());
  }, [dispatch]);

  const updateClientsOrder = (id: string, newDisplayOrder: number) => {
    const updatedArticle = {
      displayRank: newDisplayOrder,
    };
    dispatch(updateArticleRank(id, updatedArticle));
  };

  const handleClientOrderSubmit = () => {
    for (let index = 0; index < rankList.length; index++) {
      const article = rankList[index];
      if (index + 1 !== article.displayRank && index < displayedEvents) {
        updateClientsOrder(article._id, index + 1);
      }
    }
    dispatch(createAlert('Partners Order successfully updated!', AlertTypes.success));
  };

  return (
    <div className="container">
      <div className={`${styles.title} mb-3`}>Order Articles</div>
      <div className={`${styles.description} mb-3`}>
        Order articles. Articles in green will
      </div>
      {/* @ts-ignore */}
      <ReactSortable tag="div" list={rankList} setList={setRankList} className="row">
        {!!rankList && rankList.map((article, index) => (
          <div
            key={article.displayRank}
            className={
              `${index < displayedEvents ? styles.elementDisplayed : styles.elementNotDisplayed}
              col-4
              ${styles.cardContainer}`}
          >
            <img
              src={article?.image}
              alt={`${article?.title} profile`}
              className={styles.photo}
            />
          </div>
        ))}
      </ReactSortable>
      <input type="submit" value="Update List Order" onClick={handleClientOrderSubmit} />

    </div>
  );
};

export default ArticleOrder;
