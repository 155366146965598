import {useEffect, useState} from 'react';
import Icon from 'awesome-react-icons';
import {Button, Modal} from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';
import {deleteRessource, loadRessourcesList, Ressource} from 'src/features/website/events';
import {useAppDispatch, useAppSelector} from 'src/hooks/hooks';
import styles from './RessourcesList.module.css';

const RessourcesList = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {ressourcesList} = useAppSelector((s) => s.events);
  const [openDeleteModal, setDeleteOpenModal] = useState(false);
  const [currentRessource, setCurrentRessource] = useState<Ressource>();

  useEffect(() => {
    dispatch(loadRessourcesList());
  }, [dispatch]);

  const handleDeleteClick = () => {
    if (currentRessource?._id) {
      dispatch(deleteRessource(currentRessource?._id));
      setDeleteOpenModal(false);
    }
  };

  return (
    <div>
      <Modal show={openDeleteModal} onHide={() => setDeleteOpenModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Ressource</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {`${currentRessource?.description}`} ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeleteOpenModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => handleDeleteClick()}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      <div className={styles.title}>Ressources List</div>
      <div className={styles.tableContainer}>
        <table className="table table-dark">
          <thead>
            <tr>
              <th scope="col">Description</th>
              <th scope="col">Update</th>
              <th scope="col">Delete</th>
            </tr>
          </thead>
          <tbody>
            {!!ressourcesList && ressourcesList.map((ressource: Ressource) => (
              <tr key={ressource?._id}>
                <td>{
                  ressource.description.length > 50
                    ? `${ressource?.description?.substring(0, 50)}...`
                    : ressource?.description
                }</td>
                <td>
                  <div
                    className={styles.cursorPointer}
                    onClick={() => {
                      navigate(`/website/ressources/update/${ressource?._id}`);
                    }}
                  >
                    <Icon name="activity" />
                  </div>
                </td>
                <td>
                  <button
                    className="deleteButton"
                    onClick={() => {
                      setDeleteOpenModal(true);
                      setCurrentRessource(ressource);
                    }}
                  >
                    <Icon name="trash" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default RessourcesList;
