import {useContext} from 'react';
import MatchContext from '../NationsWarsMatchContext';
import styles from './NationsMatchesAnnounceSelectedMap.module.css';

interface NationsMatchesAnnounceSelectedMapProps {
  isVetoPhase: boolean;
}
const NationsMatchesAnnounceSelectedMap = ({isVetoPhase}: NationsMatchesAnnounceSelectedMapProps) => {
  const {currentMatch, selectedMap} = useContext(MatchContext);

  const getNationName = () => {
    const {currentStep, startingNation} = currentMatch!;
    const nations = currentMatch!.nations.map((n) => n.flag);
    if (currentStep % 2 === 0 && startingNation === nations[0]) return nations[0];
    else return nations[1];
  };
  return (
    <div className={styles.selectedMapContainer}>
      {selectedMap !== undefined && (<div>
        {selectedMap.name.toUpperCase()}
        {/* TEAM {getNationName()} {isVetoPhase ? 'VETOES ' : 'PICKS '} {selectedMap.name.toUpperCase()} */}
      </div>)}
    </div>
  );
};

export default NationsMatchesAnnounceSelectedMap;
