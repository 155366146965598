/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import {useForm} from 'react-hook-form';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import BackButton from 'src/components/Global/BackButton';
import {useNavigate} from 'react-router-dom';
import {createRessource} from 'src/features/website/events';
import styles from './RessourceCreate.module.css';
import {useAppDispatch} from 'src/hooks/hooks';

interface IFormInputs {
  description: string;
}

const SignupSchema = yup.object().shape({
  description: yup.string().min(2, 'Description must be at least 2 characters'),
});

interface RessourceCreateProps {
  external?: boolean;
  setCreateRessource?: (value: boolean) => void;
}
const RessourceCreate = ({external, setCreateRessource} : RessourceCreateProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {register, handleSubmit, formState: {errors, isValid}} = useForm<IFormInputs>({
    resolver: yupResolver(SignupSchema),
    mode: 'onChange',
  });
  const onSubmitRessourceCreate = (data: IFormInputs) => {
    const newRessource = new FormData();
    if (data.description) newRessource.append('description', data.description);
    const ressource = {
      description: data.description,
    };
    dispatch(createRessource(ressource, !external && navigate, !!setCreateRessource && setCreateRessource));
  };

  return (
    <div>
      <div className={styles.title}>Create Ressource</div>
      {!external && <BackButton />}
      <form onSubmit={handleSubmit(onSubmitRessourceCreate)}>
        <label>Description</label>
        <input {...register('description')} />
        <p>{errors.description?.message}</p>
        <input type="submit" value="Publish" disabled={!isValid} />
      </form>
    </div>
  );
};

export default RessourceCreate;
