/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable no-shadow */
import {ChangeEvent, useState, useRef, useCallback, useEffect} from 'react';
import Cropper from 'react-image-crop';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import UpdateIcon from '@material-ui/icons/Update';
import 'react-image-crop/dist/ReactCrop.css';
import {AlertTypes, createAlert} from 'src/features/global/alert';
import styles from './ImagePicker.module.css';
import {useAppDispatch} from 'src/hooks/hooks';

export interface ImagePickerProps {
  labelText: string;
  errorText: string;
  fileHandler: (f: File | undefined) => void;
  widthRatio: number;
  heightRatio: number;
  previewWidth?: number;
  previewHeight?: number;
}

interface CompletedCrop {
  aspect: number;
  unit: string;
  width: number;
  height: number;
  x: number;
  y: number;
}
const initialCrop = {aspect: 0, unit: '', width: 0, height: 0, x: 0, y: 0};

const pixelRatio = 4;

declare const document: Document;
const ImagePicker = ({
  labelText,
  fileHandler,
  errorText,
  widthRatio,
  heightRatio,
  previewWidth,
  previewHeight,
}: ImagePickerProps) => {
  const [dataUrl, setDataUrl] = useState('');
  const [crop, setCrop] = useState({aspect: widthRatio / heightRatio});
  const [completedCrop, setCompletedCrop] = useState<CompletedCrop | any>(
    initialCrop,
  );
  const fileMaxSize = 2000000;
  const [croppedImg, setCroppedImg] = useState('');
  const [chooseImage, setChooseImage] = useState(false);
  const [isCropCompleted, setIsCropCompleted] = useState(false);
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const dispatch = useAppDispatch();

  const readFile = (file: File) => new Promise<{ file: File; dataURL: string }>((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();

    // Read the image via FileReader API and save image result in state.
    reader.onload = (e) => {
      // Add the file name to the data URL
      if (!e?.target?.result) {
        reject(new Error('Something went wrong with the imagepicker'));
        return;
      }

      let dataURL = e.target.result as string;
      dataURL = dataURL.replace(';base64', `;name=${file.name};base64`);
      resolve({file, dataURL});
    };

    reader.readAsDataURL(file);
  });

  const displayFile = async (file: File) => {
    const upload = await readFile(file);
    setDataUrl(upload.dataURL);
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {files} = event.target;
    if (!files || !files.length) {
      fileHandler(undefined);
      return;
    }
    const file = files[0];
    displayFile(file);
    fileHandler(file);
    setIsCropCompleted(false);
    setChooseImage(true);
  };

  const onLoad = useCallback((img : any) => {
    imgRef.current = img;
  }, []);

  const dataURLtoBlob = (dataurl: any) => {
    const arr = dataurl.split(',');
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    const newBlob = new Blob([u8arr], {type: mime});
    const b: any = newBlob;
    b.lastModified = new Date();
    b.name = 'croppedImg';
    return b;
  };

  useEffect(() => {
    if (
      !completedCrop
      || !previewCanvasRef.current
      || !imgRef.current
      || isCropCompleted
    ) {
      return;
    }

    const image = (imgRef.current as unknown) as HTMLImageElement;
    const canvas = (previewCanvasRef.current as unknown) as HTMLCanvasElement;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas?.getContext('2d');

    if (!ctx) {
      return;
    }
    if (!completedCrop.width || !completedCrop.height) {
      return;
    }

    canvas.width = completedCrop.width * pixelRatio;
    canvas.height = completedCrop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingEnabled = false;

    ctx.drawImage(
      image,
      completedCrop.x * scaleX,
      completedCrop.y * scaleY,
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
      0,
      0,
      completedCrop.width,
      completedCrop.height,
    );
    setCroppedImg(canvas.toDataURL());
  }, [completedCrop, croppedImg, isCropCompleted]);

  const confirmImageCrop = () => {
    if (!croppedImg) {
      dispatch(createAlert('Please crop the image', AlertTypes.warning));
      return;
    }
    const finalImage = dataURLtoBlob(croppedImg);
    if (finalImage.size >= fileMaxSize) {
      dispatch(createAlert(`Image size: ${(finalImage.size / (1024 * 1024)).toFixed(2)}MB, max size ${fileMaxSize}MB`, AlertTypes.warning));
      return;
    }
    displayFile(finalImage);
    fileHandler(finalImage);
    setChooseImage(false);
    setIsCropCompleted(true);
  };

  return (
    <>
      {croppedImg && (
        <div>
          <div className={[errorText ? styles.error : null].join(' ')}>
            <div className={[styles.cropperContainer, styles.previewCropped].join(' ')}>
              <div className={styles.center_image}>
                <IconButton size="small" className={styles.plusIcon}>
                  <UpdateIcon />
                </IconButton>
              </div>
              <input
                className={styles.gameImageInput}
                type="file"
                id="image"
                name="image"
                onChange={onFileChange}
              />
              <img
                src={croppedImg}
                alt="banner"
                className={styles.imagePreview}
                style={{width: previewWidth, height: previewHeight}}
              />
            </div>
          </div>
        </div>
      )}
      {!croppedImg && (
        <>
          <div
            className={[
              styles.imageHolder,
              errorText ? styles.error : null,
            ].join(' ')}
            style={{width: previewWidth, height: previewHeight}}
          >
            <div className={styles.center_image}>
              <IconButton size="small" className={styles.plusIcon}>
                <AddIcon />
              </IconButton>
            </div>
            <input
              className={styles.gameImageInput}
              type="file"
              id="image"
              name="image"
              onChange={onFileChange}
            />
          </div>
        </>
      )}
      <Dialog maxWidth="xl" open={chooseImage}>
        <DialogTitle>Crop your banner!</DialogTitle>
        <DialogContent>
          <div className={styles.cropperContainer}>
            {dataUrl && (
              <Cropper
                src={dataUrl}
                onImageLoaded={onLoad}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                crop={crop}
                onChange={(c: any) => setCrop(c)}
                onComplete={(c: any) => setCompletedCrop(c)}
              />
            )}
            <div className={styles.croppedImageContainer}>
              <canvas
                ref={previewCanvasRef}
                style={{
                  width: completedCrop?.width ?? 0,
                  height: completedCrop?.height ?? 0,
                }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className={styles.cancelButton}
            onClick={() => {
              setChooseImage(false);
              setIsCropCompleted(true);
            }}
          >
            Cancel
          </Button>
          <Button className={styles.confirmButton} onClick={confirmImageCrop}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <p
        className={[
          styles.errorText,
          errorText ? styles.visible : styles.hidden,
        ].join(' ')}
      >
        {errorText}
      </p>
    </>
  );
};

export default ImagePicker;
